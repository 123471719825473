import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ProfiloPazienteActions from '../../features/profilo-paziente/store/profilo-paziente.actions';
import * as fromApp from '../../store/app.reducer';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent implements OnInit, OnChanges {
  @Input() pazienteId: string;
  @Input() isHm: boolean = false;
  @Input() isLoading: boolean = true;
  @Input() isFull: boolean = true;
  @Input() receivedStatusScore: string;
  @Input() selectedHealthScoreId: string | null;
  @Input() selectedPeriod: string | null;
  @Input() fromTelevisita: boolean = false;
  private profiloPazienteSub: Subscription;
  public allRichieste: any[] | null;
  public error: boolean = false;
  public dataSurvey: any[] = [];
  public dataOnBoarding: any[] | null;
  public healthScoreId: string | null;
  public idOnBoarding: string;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getOnBoarding();
    if (!this.fromTelevisita) {
      this.getLastHealthScore();
    } else {
      this.healthScoreId = this.selectedHealthScoreId;
      this.getSelectedHealthScore();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedHealthScoreId) {
      const currentHealthScoreId = changes.selectedHealthScoreId.currentValue;
      const isFirstChange = changes.selectedHealthScoreId.firstChange;

      if (!isFirstChange && currentHealthScoreId !== this.healthScoreId) {
        this.healthScoreId = currentHealthScoreId;

        if (currentHealthScoreId !== null) {
          this.getSelectedHealthScore();
        } else {
          this.getLastHealthScore();
        }
      }
    }
  }

  getLastHealthScore() {
    this.isLoading = true;
    this.http
      .get(`${environment.NEW_API}/patients/${this.pazienteId}/health-score`)
      .subscribe(
        (response: any) => {
          console.log('get id last health score', response);
          this.healthScoreId = response.id;
          this.http
            .get(
              `${environment.NEW_API}/patients/${this.pazienteId}/health-scores/${this.healthScoreId}/surveys`
            )
            .subscribe(
              (secondResponse: any) => {
                console.log('get health score', secondResponse);
                this.dataSurvey = secondResponse;
              },
              (secondErr) => {
                this.errorHandling(secondErr);
              }
            );
        },
        (err) => {
          this.errorHandling(err);
        }
      );
  }

  getSelectedHealthScore() {
    this.isLoading = true;
    this.http
      .get(
        `${environment.NEW_API}/patients/${this.pazienteId}/health-scores/${this.healthScoreId}/surveys`
      )
      .subscribe(
        (response: any) => {
          console.log('get selected health score', response);
          this.dataSurvey = response;
        },
        (err) => {
          this.errorHandling(err);
        }
      );
  }

  getOnBoarding() {
    this.isLoading = true;
    this.http
      .get(
        `${environment.NEW_API}/patients/${this.pazienteId}/surveys/onboarding`
      )
      .subscribe(
        (response: any) => {
          this.dataOnBoarding = response;
          console.log('onboarding', this.dataOnBoarding);
        },
        (err) => {
          this.errorHandling(err);
          this.dataOnBoarding = null;
        }
      );
  }

  private errorHandling(errMessage: any) {
    console.error(errMessage);
    this.error = true;
    this.isLoading = false;
  }

  ngOnDestroy() {
    if (this.profiloPazienteSub) {
      this.profiloPazienteSub.unsubscribe();
    }
  }
}
