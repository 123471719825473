import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { RichiestaDatum } from 'src/app/model/Richiesta';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appuntamenti-item',
  templateUrl: './appuntamenti-item.component.html',
  styleUrls: ['./appuntamenti-item.component.scss'],
})
export class AppuntamentiItemComponent implements OnInit {
  @Input() sectionTab: string | null;
  @Input() isHm: boolean | null = false;
  @Input() myId: number | undefined;
  @Input() richiesta: RichiestaDatum;
  @Input() mode: 'appuntamenti' | 'storico';
  @Input() listType: 'today' | 'future' | 'past' | 'all' | 'open' | 'closed' =
    'today';
  @Output() handleClick: EventEmitter<RichiestaDatum> = new EventEmitter();
  @Output() handleDelete: EventEmitter<string> = new EventEmitter();
  public avatarUrl: any;
  public dateDisplay: any;
  public richiestaId: string;
  public showReason = false;
  public expireRequestMessage: string | null;

  public videoconsultoIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
      <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/>
    </svg> 
  `;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.getAvatar();

    switch (this.listType) {
      case 'today':
        this.dateDisplay = new Date();
        break;

      case 'future':
        this.dateDisplay = 'Appuntamenti in programma';
        break;

      default:
        break;
    }
  }

  handleDetailClick(richiesta: RichiestaDatum) {
    this.handleClick.emit(richiesta);
  }

  handleDeleteRequest(richiestaId: string) {
    this.handleDelete.emit(richiestaId);
  }

  expireDate(dateString: string, requestType: string): boolean {
    let oggi = new Date().getTime();
    let dataVisita = new Date(dateString).getTime();
    let expireLimit;
    if (requestType === 'HM' || requestType === 'HM_DOC') {
      expireLimit = 600000; // limite 10 minuti = 600000 ms
      this.expireRequestMessage =
        "L'appuntamento può essere cancellato fino a 10 minuti dall'inizio";
    } else {
      expireLimit = 21600000; // limite 6 ore = 21600000 ms
      this.expireRequestMessage =
        "L'appuntamento può essere cancellato fino a 6 ore dall'inizio";
    }
    let differenza = dataVisita - oggi - expireLimit;
    if (differenza < 0) {
      return true;
    } else {
      return false;
    }
  }

  patientHasBundle(richiesta: any) {
    if (richiesta.orderInfo) {
      return true;
    } else {
      return false;
    }
  }

  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(
        `${environment.NEW_API}/patients/${this.richiesta.patient.id}/image`,
        { responseType: 'blob' }
      )
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  goToPazienteProfile(patientId: any): void {
    this.router.navigate(['/profilo-paziente', patientId]);
  }

  // MOTIVO DELLA VISITA
  toggleReason(): void {
    if (!this.richiesta.reason) {
      return;
    }
    this.showReason = !this.showReason;
  }
}
