import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import { PostTriage, Triage } from 'src/app/model/Triage';
import { environment } from 'src/environments/environment';
import * as fromApp from '../../../store/app.reducer';
import * as RichiesteActions from './richieste.actions';
import { deleteRichiestaSuccess } from './richieste.actions';

@Injectable()
export class RichiesteEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private router: Router
  ) {}

  fetchAllRichieste$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchAllRichieste),
      switchMap((action) => {
        return this.fetchRichieste(action);
      }),
      map((richieste) => {
        console.log('richieste from effects', richieste);
        return RichiesteActions.fetchAllRichiesteSuccess({
          richieste: richieste,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchAllRichiesteFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchTodayRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchTodayRequests),
      switchMap((action) => {
        return this.fetchRichieste(action);
      }),
      map((richieste) => {
        return RichiesteActions.fetchTodayRequestsSuccess({
          richieste: richieste,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchTodayRequestsFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchFutureRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchFutureRequests),
      switchMap((action) => {
        return this.fetchRichieste(action);
      }),
      map((richieste) => {
        return RichiesteActions.fetchFutureRequestsSuccess({
          richieste: richieste,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchFutureRequestsFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchPastOpenRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchPastOpenRequests),
      switchMap((action) => {
        return this.fetchRichieste(action);
      }),
      map((richieste) => {
        return RichiesteActions.fetchPastOpenRequestsSuccess({
          richieste: richieste,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchPastOpenRequestsFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchClosedRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchClosedRequests),
      switchMap((action) => {
        return this.fetchRichieste(action);
      }),
      map((richieste) => {
        return RichiesteActions.fetchClosedRequestsSuccess({
          richieste: richieste,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchClosedRequestsFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchRichiestaDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchRichiesteDetails),
      switchMap((action) => {
        console.log('fetching richiesta details');
        return this.http.get<any>(
          environment.NEW_API + '/requests/' + action.richiestaId
        );
      }),
      pipe(
        map((richiesta) => {
          console.log('richiesta details from effects', richiesta);
          if (richiesta) {
            return RichiesteActions.fetchRichiestaDetailsSuccess({
              richiesta: richiesta,
            });
          } else {
            return RichiesteActions.fetchRichiestaDetailsFailure({
              error: 'error: Fetch details failed',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching:', error);
          return of(
            RichiesteActions.fetchRichiestaDetailsFailure({
              error:
                'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
            })
          );
        })
      )
    )
  );

  deleteRichiesta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.deleteRichiesta),
      switchMap((action) =>
        this.http
          .delete(environment.NEW_API + '/requests/' + action.id)
          .pipe(map(() => deleteRichiestaSuccess({ id: action.id })))
      )
    )
  );

  fetchTriage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchTriage),
      switchMap((action) =>
        this.http
          .get<Triage>(
            environment.NEW_API + '/requests/' + action.richiestaId + '/triage'
          )
          .pipe(
            map((triage) => {
              console.log('triage from effects', triage);
              if (triage) {
                return RichiesteActions.fetchTriageSuccess({
                  triage: triage.data,
                });
              } else {
                return RichiesteActions.fetchTriageFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.fetchTriageFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  postTriage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.postTriage),
      switchMap((action) =>
        this.http
          .patch<PostTriage>(
            environment.NEW_API + '/requests/' + action.richiestaId + '/triage',
            action.triage
          )
          .pipe(
            map((triage) => {
              console.log('triage from effects', JSON.stringify(triage));
              if (triage) {
                return RichiesteActions.postTriageSuccess({
                  triage: triage,
                });
              } else {
                return RichiesteActions.postTriageFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.postTriageFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  postNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.postNote),
      switchMap((action) => {
        console.log('posting notes');
        return this.http.post<any>(
          environment.NEW_API + '/requests/' + action.requestId + '/note',
          action.note,
          {
            params: {
              requestId: action.requestId,
              // closeRequest: action.closeRequest,
            },
          }
        );
      }),
      map((notes) => {
        console.log('post notes from effects', notes);
        if (notes) {
          return RichiesteActions.postNoteSuccess({ note: notes });
        } else {
          return RichiesteActions.postNoteFailed({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.postNoteFailed({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchNotesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RichiesteActions.postNoteSuccess),
        map(() => {
          this.router.navigate(['/appuntamenti']);
        })
      ),
    { dispatch: false }
  );

  postTriageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.postTriageSuccess),
      map(() => {
        this.router.navigate(['/appuntamenti']);
        return RichiesteActions.fetchAllRichieste({});
      })
    )
  );

  fetchRichiestaDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchRichiestaDocuments),
      switchMap((action) => {
        let url = `${environment.NEW_API}/patients/${action.patientId}/documents?f.sharedWithRequestId=${action.richiestaId}&f.type_in=${action.typeDoc}&pageItems=5`;

        if (action.sort) {
          url += `&sort=${action.sort}`;
        }
        if (action.page) {
          url += `&page=${action.page}`;
        }

        return this.http.get<any>(url).pipe(
          map((documents) => {
            console.log(
              'richiesta documents from effects',
              JSON.stringify(documents)
            );
            if (documents) {
              return RichiesteActions.fetchRichiestaDocumentsSuccess({
                documents: documents.items,
                pagination: documents.pagination,
              });
            } else {
              return RichiesteActions.fetchRichiestaDocumentsFailed({
                error: 'error: ',
              });
            }
          }),
          catchError((error) => {
            console.error('Error while fetching:', error);
            return of(
              RichiesteActions.fetchRichiestaDocumentsFailed({
                error:
                  'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
              })
            );
          })
        );
      })
    )
  );

  fetchRichiestaMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchRichiestaMeeting),
      switchMap((action) =>
        this.http
          .get(
            environment.NEW_API +
              '/requests/' +
              action.richiestaId +
              '/meeting',
            {
              responseType: 'text',
            }
          )
          .pipe(
            map((meeting) => {
              console.log('richiesta meeting from effects', meeting);
              if (meeting) {
                return RichiesteActions.fetchRichiestaMeetingSuccess({
                  meeting: meeting,
                });
              } else {
                return RichiesteActions.fetchRichiestaMeetingFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.fetchRichiestaMeetingFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  fetchCalendarRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchCalendarRequests),
      switchMap((action) => {
        let url = environment.NEW_API + '/requests/calendar';
        if (action.status) {
          url += '?requestStatus=' + action.status;
        }
        if (action.fromDate) {
          url += '&fromDate=' + action.fromDate;
        }
        if (action.toDate) {
          url += '&toDate=' + action.toDate;
        }
        if (action.page && action.pageSize) {
          url += '&page=' + action.page + '&pageSize=' + action.pageSize;
        }
        console.log('fetching calendar');
        const urlDebug =
          environment.NEW_API +
          '/requests/calendar?requestStatus=OPEN&fromDate=2022-06-01&toDate=2022-06-30';
        return this.http.get<Richiesta>(url);
      }),
      map((richieste) => {
        console.log('calendar from effects', richieste);
        if (richieste) {
          return RichiesteActions.fetchCalendarRequestsSuccess({
            requests: richieste.items,
          });
        } else {
          return RichiesteActions.fetchCalendarRequestsFailed({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while fetching calendar:', error);
        return of(
          RichiesteActions.fetchAllRichiesteFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  closeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.closeRequest),
      switchMap((action) => {
        return this.http.patch<any>(
          environment.NEW_API + '/requests/' + action.requestId + '/close',
          { requestId: action.requestId }
        );
      }),
      map((richiesta) => {
        console.log('close request from effects', richiesta);
        return RichiesteActions.closeRequestSuccess();
      }),
      catchError((error) => {
        console.error('Error while closing request:', error);
        return of(
          RichiesteActions.closeRequestFailed({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  closeRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RichiesteActions.closeRequestSuccess),
        map(() => {
          this.router.navigate(['/storico']);
        })
      ),
    { dispatch: false }
  );

  movergyDisplay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.movergyDisplay),
      switchMap((action) => {
        return this.http.get<any>(
          environment.NEW_API +
            '/my-wellness/patient/' +
            action.patientId +
            '/movergy'
        );
      }),
      map((movergy) => {
        console.log('movergy display from effects', movergy.data);
        return RichiesteActions.movergyDisplaySuccess({
          movergyDisplay: movergy.data,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching movergy:', error);
        return of(
          RichiesteActions.movergyDisplayFailed({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  mywellnessInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.myWellnessInvite),
      switchMap((action) => {
        return this.http.patch<any>(
          environment.NEW_API +
            '/my-wellness/patient/' +
            action.patientId +
            '/invite',
          {}
        );
      }),
      map((res) => {
        console.log('mywellness invite from effects', res.data);
        return RichiesteActions.myWellnessInviteSuccess({
          res: res.data,
        });
      }),
      catchError((error) => {
        console.error('Error while inviting:', error);
        return of(
          RichiesteActions.myWellnessInviteFailed({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchRichieste(action: any) {
    let url = environment.NEW_API + '/requests';
    // if (action.searchRequest) {
    //   const searchValue = {
    //     filter: {
    //       filterBy: {
    //         operation: 'AND',
    //         filters: [
    //           {
    //             field: 'status',
    //             operation: 'EQ',
    //             value: action.statusValue,
    //           },
    //           {
    //             field: 'slot.from',
    //             operation: 'EQ',
    //             value: action.fromDate,
    //           },
    //           {
    //             field: 'slot.to',
    //             operation: 'EQ',
    //             value: action.toDate,
    //           },
    //         ],
    //       },
    //     },
    //     pagination: {
    //       page: action.page,
    //       size: 20,
    //     },
    //   };
    //   const base64 = btoa(JSON.stringify(searchValue));
    //   url += '?searchRequest=' + base64;
    // }
    let queryRequest = [];
    if (action.statusValue) {
      queryRequest.push('f.status_in=' + action.statusValue);
    }
    if (action.fromDate) {
      queryRequest.push('f.start_date_time_gte=' + action.fromDate);
    }
    if (action.toDate) {
      queryRequest.push('f.start_date_time_lte=' + action.toDate);
    }
    if (action.sort) {
      queryRequest.push('sort=' + action.sort);
    }
    if (action.page) {
      queryRequest.push('page=' + action.page);
    }

    if (queryRequest.length > 0) {
      url += '?' + queryRequest.join('&');
      console.log(url);
    }
    return this.http.get<Richiesta>(url);
  }
}
