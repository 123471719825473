import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pipe } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Area } from 'src/app/model/Area';
import { Availabilities, AvailableSlot } from 'src/app/model/Calendar';
import { DoctorSummary } from 'src/app/model/DoctorSummary';
import { Policy, PolicyData } from 'src/app/model/Policy';
import { PrenotazioneResData } from 'src/app/model/PrenotazioneResData';
import { environment } from 'src/environments/environment';
import * as PrenotazioneActions from '../actions/prenotazione.action';

@Injectable()
export class PrenotazioneEffects {
  fetchAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.fetchAreas),
      switchMap(() => {
        console.log('fetching areas');
        return this.http.get<Area>(environment.NEW_API + '/medical-areas');
      }),
      pipe(
        map((areas) => {
          console.log('areas from effects', JSON.stringify(areas));
          return PrenotazioneActions.setAreas({ areas: areas.data });
        })
      )
    )
  );

  fetchDocsSumms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.fetchDocsSumms),
      switchMap(() => {
        console.log('fetching doctors');
        return this.http.get<DoctorSummary>(
          environment.NEW_API + '/doctors/summaries'
        );
      }),
      pipe(
        map((docsSumms) => {
          console.log('doctors summaries from effects', docsSumms);
          return PrenotazioneActions.setDocsSumms({ docSumms: docsSumms });
        })
      )
    )
  );

  filterDocs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.filterDocs),
      switchMap((action) => {
        console.log('effects filtering doctors');
        return this.http.get<DoctorSummary>(
          environment.NEW_API +
            '/doctors/summaries?textSearch=' +
            action.textSearch
        );
      }),
      pipe(
        map((docsSumms) => {
          console.log('doctors summaries from effects', docsSumms);
          return PrenotazioneActions.setDocsSumms({ docSumms: docsSumms });
        })
      )
    )
  );

  fetchAvailableAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.fetchAvailableAppointments),
      switchMap((action) => {
        console.log('fetching available appointments');
        return this.http.get<Availabilities>(
          environment.NEW_API +
            '/doctors/' +
            action.doctorId +
            '/availabilities'
        );
      }),
      pipe(
        map((availabilities) => {
          console.log('available appointments from effects', availabilities);
          return PrenotazioneActions.setAllAvailabilities({
            allAvailabilities: availabilities.data,
          });
        })
      )
    )
  );

  fetchAvailableSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.fetchAllSlots),
      switchMap((action) => {
        console.log('fetching available slots');
        return this.http.get<AvailableSlot>(
          environment.NEW_API +
            '/doctors/' +
            action.doctorId +
            '/availabilities/' +
            action.dateId +
            '/slots'
        );
      }),
      pipe(
        map((slots) => {
          console.log('available slots from effects', slots);
          return PrenotazioneActions.setAllSlots({
            slotsAvailable: slots.data,
          });
        })
      )
    )
  );

  fetchPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.fetchPolicies),
      switchMap(() => {
        return this.http.get<PolicyData[]>(
          environment.NEW_API + '/policies/unread'
        );
      }),
      map((policies) => {
        if (policies) {
          return PrenotazioneActions.fetchPoliciesSuccess({
            policies: policies,
          });
        } else {
          return PrenotazioneActions.fetchPoliciesFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  postPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.postPolicy),
      switchMap((action) => {
        console.log('posting policy');
        return this.http.post<any>(
          environment.NEW_API + '/policies/multiple',
          action.policy
        );
      }),
      map((policies) => {
        console.log('post policies from effects', JSON.stringify(policies));
        if (policies) {
          return PrenotazioneActions.postPolicySuccess();
        } else {
          return PrenotazioneActions.postPolicyFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  postPrenotazione$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.postPrenotazione),
      switchMap((action) => {
        console.log('starting prenotazione');
        return this.http.post<PrenotazioneResData>(
          environment.NEW_API + '/booking-sessions',
          {
            doctorId: action.selectedDoctor.id,
            medicalSpecializationId: action.medicalSpecializationId,
          }
        );
      }),
      map((data) => {
        console.log('start prenotazione success', data);
        return PrenotazioneActions.prenotazioneResData({
          data: data,
        });
      })
    )
  );

  patchPrenotazione$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.patchPrenotazione),
      switchMap((action) => {
        console.log('patching prenotazione');
        return this.http.patch<PrenotazioneResData>(
          environment.NEW_API + '/booking-sessions/' + action.sessionId,
          {
            doctorSlotId: action.doctorSlotId,
          }
        );
      }),
      map((data) => {
        console.log('prenotazione patched', data);
        return PrenotazioneActions.patchPrenotazioneResData({
          data: data,
        });
      })
    )
  );

  confirmPrenotazione$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrenotazioneActions.confirmPrenotazione),
      switchMap((action) => {
        console.log('confirming prenotazione');
        return this.http.get<any>(
          environment.NEW_API +
            '/booking-sessions/' +
            action.sessionId +
            '/paymentIntent'
        );
      }),
      map((data) => {
        console.log('prenotazione confirmed', data);
        return PrenotazioneActions.prenotazioneSuccess();
      })
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}
