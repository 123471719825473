<div class="cont-doc">
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <section class="documenti" *ngIf="!loading">
    <ng-container *ngIf="documentsFromStore.length == 0">
      <h1 class="d-flex justify-content-center m-5 do3-fw600">
        Nessun referto condiviso con il paziente
      </h1>
    </ng-container>
    <ng-container *ngIf="documentsFromStore.length > 0">
      <div class="row do3-fw800 do3-blue-text header">
        <div class="col-1"></div>
        <div class="col-10">
          <div class="row">
            <div class="col-3">Nome</div>
            <div class="col-3">Dimensione</div>
            <div class="col-3">Proprietario</div>
            <div class="col-3">Data caricamento</div>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
      <div class="document-box" *ngFor="let doc of documentsFromStore">
        <div class="row align-items-center">
          <div class="col-1">
            <div class="box" [ngClass]="parseDocType(doc.contentType)">
              <div class="box-content">{{ parseDocType(doc.contentType) }}</div>
            </div>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-3">{{ doc.name }}</div>
              <div class="col-3">{{ parseBytes(doc.bytes) }}</div>
              <div class="col-3">{{ doc.author.name }}</div>
              <div class="col-3">
                {{ doc.dateTime | date : "d MMMM y - HH:mm" }}
              </div>
            </div>
          </div>
          <div class="col-1 d-flex align-items-center">
            <div class="pointer" (click)="downloadDocument(doc)">
              <mat-icon class="do3-lightblue-text"> save_alt </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <button
      *ngIf="richiestaId && richiestaId.length > 0"
      (click)="onClick()"
      class="btn mt-4 mb-5"
    >
      Carica referto
    </button>
    <div></div>
    <input
      type="file"
      #fileUpload
      id="fileUpload"
      name="fileUpload"
      multiple="multiple"
      accept="image/*, application/pdf"
      style="display: none"
    />
  </section>
</div>
