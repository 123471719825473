<ng-container *ngIf="singleAnamnesi">
  <div class="single-anamnesi do3-fw600">
    <div class="row do3-gray-text" *ngIf="singleAnamnesi">
      <div class="col-5 pl-4">
        {{ singleAnamnesi.specialization.name }}
      </div>
      <div class="col-5">
        {{ singleAnamnesi.specialization.description }}
      </div>
      <div class="col-2">
        <div class="d-flex align-items-center justify-content-around">
          <!-- Input -->
          <div class="activeAnamnesi">
            <div class="toggle-button-cover">
              <div class="button-cover">
                <div class="button r" id="button-1">
                  <input type="checkbox" [ngModel]="isActived" class="checkbox" name="anamnesi" id="anamnesi"
                    [checked]="singleAnamnesi.active" (change)="activeAnamnesi($event,singleAnamnesi.specialization.id)" />
                  <div class="knobs"></div>
                  <div class="layer"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- fine input -->

          <mat-icon class=""  (click)="getAnamnesi(singleAnamnesi.specialization.id)">
            {{ !openPanel ? "expand_more" : "expand_less" }}
          </mat-icon>
        </div>
      </div>
    </div>

    <ng-container *ngIf="openPanel">
      <div class="row answers">
        <app-loading-spinner class="d-flex justify-content-center" *ngIf="isLoading"></app-loading-spinner>
        <div *ngIf="!isLoading" class="col custom-col">
          <h2 class="do3-fw800 do3-blue-text">Questa il questionario che sarà somministrato se l'opzione verrà attivata: </h2>
          <div  *ngFor="let quest of anamnesi.questions; let i = index">
            <span class="do3-fw800 do3-blue-text">{{ i + 1 }} - {{ quest.question }}</span>
            <ul *ngFor="let answer of quest.answers">
              <li class="m-2 do3-gray-text">{{ answer.description }}</li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>