<div>
  <h1 class="mb-2">Movergy</h1>
  <div class="box-movergy">
    <img *ngIf="movergyValue <= 50" src="../../../../assets/images/movergy/0.png" alt="movergy-counter">
    <img *ngIf="movergyValue > 50 && movergyValue <= 150" src="../../../../assets/images/movergy/100.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 150 && movergyValue <= 250" src="../../../../assets/images/movergy/200.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 250 && movergyValue <= 350" src="../../../../assets/images/movergy/300.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 350 && movergyValue <= 450" src="../../../../assets/images/movergy/400.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 450 && movergyValue <= 550" src="../../../../assets/images/movergy/500.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 550 && movergyValue <= 650" src="../../../../assets/images/movergy/600.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 650 && movergyValue <= 750" src="../../../../assets/images/movergy/700.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 750 && movergyValue <= 850" src="../../../../assets/images/movergy/800.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 850 && movergyValue <= 950" src="../../../../assets/images/movergy/900.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 950 && movergyValue <= 1050" src="../../../../assets/images/movergy/1000.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1050 && movergyValue <= 1150" src="../../../../assets/images/movergy/1100.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1150 && movergyValue <= 1250" src="../../../../assets/images/movergy/1200.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1250 && movergyValue <= 1350" src="../../../../assets/images/movergy/1300.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1350 && movergyValue <= 1450" src="../../../../assets/images/movergy/1400.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1450 && movergyValue <= 1550" src="../../../../assets/images/movergy/1500.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1550 && movergyValue <= 1650" src="../../../../assets/images/movergy/1600.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1650 && movergyValue <= 1750" src="../../../../assets/images/movergy/1700.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1750 && movergyValue <= 1850" src="../../../../assets/images/movergy/1800.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1850 && movergyValue <= 1950" src="../../../../assets/images/movergy/1900.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1950" src="../../../../assets/images/movergy/2000.png" alt="movergy-counter">

    <div class="movergy-text">
      MOVERGY
      <div class="value">
        {{movergyValue}}
      </div>
      <button data-bs-toggle="modal" data-bs-target="#movergyModal">
        <mat-icon class="info">info</mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="movergyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pb-0">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0 ml-4">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="195" height="57" viewBox="0 0 195 57">
            <g id="logo" transform="translate(1 1)">
              <g id="Raggruppa_3842" data-name="Raggruppa 3842" transform="translate(-1 -1)">
                <rect id="canvas_background" width="195" height="57" fill="none" />
              </g>
              <g id="Raggruppa_3843" data-name="Raggruppa 3843" transform="translate(0.357 0.357)">
                <g id="svg_2" transform="translate(0 0)">
                  <path id="svg_1"
                    d="M7.916,28.788h3.166V39.974l7.125,7.4H33.881V44.745H63.8l9.024,9.541h80.113l4.907-9.541h32.932V24.511A5.547,5.547,0,0,0,193,20.563a5.687,5.687,0,0,0-1.266-3.784l-8.075-8.554a5.237,5.237,0,0,0-3.8-1.809,5.053,5.053,0,0,0-4.591,3.125H152.942L143.759,0H0V20.563l7.916,8.225Z"
                    transform="translate(0 0)" />
                  <path id="svg_2-2" data-name="svg_2"
                    d="M143.034.727l9.182,9.541h23.59a4.394,4.394,0,0,1,3.959-3.125,3.625,3.625,0,0,1,3.007,1.481l8.234,8.554a4.33,4.33,0,0,1,.95,3.125,4.1,4.1,0,0,1-2.218,3.455V43.5H157.124l-4.907,9.541H73.054L63.871,43.5H32.681V46.13H18.432l-6.65-6.909V27.377h-3.8L.7,19.81V.727H143.034Z"
                    transform="translate(0.25 0.26)" fill="#f4e116" />
                  <path id="svg_3" d="M17.7,33.983h4.116V10.294H119.5V6.182H4.083v4.113H17.7Z"
                    transform="translate(1.458 2.208)" fill="#f4e116" />
                  <path id="svg_4" d="M17.7,33.983h4.116V10.294H119.5V6.182H4.083v4.113H17.7Z"
                    transform="translate(1.458 2.208)" />
                  <path id="svg_5"
                    d="M23.44,22.918h7.125V19.134H23.44V17.325H34.681V13.212h-15.2V28.84H34.84V24.727H23.44V22.918Z"
                    transform="translate(6.958 4.719)" fill="#f4e116" />
                  <path id="svg_6"
                    d="M23.44,22.918h7.125V19.134H23.44V17.325H34.681V13.212h-15.2V28.84H34.84V24.727H23.44V22.918Z"
                    transform="translate(6.958 4.719)" />
                  <path id="svg_7"
                    d="M63.28,28.84V13.212H59.322v5.922H51.089V13.212H47.131V28.84h3.958V22.918h8.233V28.84Z"
                    transform="translate(16.832 4.719)" fill="#f4e116" />
                  <path id="svg_8"
                    d="M63.28,28.84V13.212H59.322v5.922H51.089V13.212H47.131V28.84h3.958V22.918h8.233V28.84Z"
                    transform="translate(16.832 4.719)" />
                  <path id="svg_9"
                    d="M73.638,28.84h5.383V13.212H75.063V24.234L66.671,13.212H61.13V28.84h3.958V17.818l8.55,11.022Z"
                    transform="translate(21.832 4.719)" fill="#f4e116" />
                  <path id="svg_10"
                    d="M73.638,28.84h5.383V13.212H75.063V24.234L66.671,13.212H61.13V28.84h3.958V17.818l8.55,11.022Z"
                    transform="translate(21.832 4.719)" />
                  <path id="svg_11" d="M79.446,21.714v0a1.1,1.1,0,0,0,1.108,1.152h0A1.1,1.1,0,0,1,79.446,21.714Z"
                    transform="translate(28.373 6.58)" fill="#f4e116" />
                  <path id="svg_12" d="M79.446,21.714v0a1.1,1.1,0,0,0,1.108,1.152h0A1.1,1.1,0,0,1,79.446,21.714Z"
                    transform="translate(28.373 6.58)" />
                  <path id="svg_13"
                    d="M88.82,13.212a4.85,4.85,0,0,1,3.325,1.481,5.182,5.182,0,0,0-3.325-1.481H80.587a4.888,4.888,0,0,0-3.008,1.151,4.888,4.888,0,0,1,3.008-1.151Z"
                    transform="translate(27.707 4.719)" fill="#f4e116" />
                  <path id="svg_14"
                    d="M88.82,13.212a4.85,4.85,0,0,1,3.325,1.481,5.182,5.182,0,0,0-3.325-1.481H80.587a4.888,4.888,0,0,0-3.008,1.151,4.888,4.888,0,0,1,3.008-1.151Z"
                    transform="translate(27.707 4.719)" />
                  <path id="svg_15"
                    d="M87.436,16.242H80.47a1.185,1.185,0,0,0-.791.329,1.185,1.185,0,0,1,.791-.329h6.966a1.1,1.1,0,0,1,1.109,1.152v4.935a.618.618,0,0,1-.159.493c0-.164.159-.329.159-.493V17.394A1.1,1.1,0,0,0,87.436,16.242Z"
                    transform="translate(28.457 5.801)" fill="#f4e116" />
                  <path id="svg_16"
                    d="M87.436,16.242H80.47a1.185,1.185,0,0,0-.791.329,1.185,1.185,0,0,1,.791-.329h6.966a1.1,1.1,0,0,1,1.109,1.152v4.935a.618.618,0,0,1-.159.493c0-.164.159-.329.159-.493V17.394A1.1,1.1,0,0,0,87.436,16.242Z"
                    transform="translate(28.457 5.801)" />
                  <path id="svg_17"
                    d="M88.945,25.16H80.712a4.721,4.721,0,0,1-3.483-1.645,4.468,4.468,0,0,0,3.483,1.645h8.233A4.85,4.85,0,0,0,92.27,23.68,4.477,4.477,0,0,1,88.945,25.16Z"
                    transform="translate(27.582 8.398)" fill="#f4e116" />
                  <path id="svg_18"
                    d="M88.945,25.16H80.712a4.721,4.721,0,0,1-3.483-1.645,4.468,4.468,0,0,0,3.483,1.645h8.233A4.85,4.85,0,0,0,92.27,23.68,4.477,4.477,0,0,1,88.945,25.16Z"
                    transform="translate(27.582 8.398)" />
                  <path id="svg_19"
                    d="M45.573,22.918v.493a1.1,1.1,0,0,1-1.108,1.152H37.5a1.1,1.1,0,0,1-1.108-1.152V18.476A1.1,1.1,0,0,1,37.5,17.325h6.966a1.1,1.1,0,0,1,1.108,1.151v.494h3.958V17.818A4.513,4.513,0,0,0,45.1,13.212H37.023a4.65,4.65,0,0,0-4.591,4.606v6.251a4.513,4.513,0,0,0,4.433,4.606H45.1a4.515,4.515,0,0,0,4.433-4.606V22.753l-3.958.164Z"
                    transform="translate(11.583 4.719)" fill="#f4e116" />
                  <path id="svg_20"
                    d="M45.573,22.918v.493a1.1,1.1,0,0,1-1.108,1.152H37.5a1.1,1.1,0,0,1-1.108-1.152V18.476A1.1,1.1,0,0,1,37.5,17.325h6.966a1.1,1.1,0,0,1,1.108,1.151v.494h3.958V17.818A4.513,4.513,0,0,0,45.1,13.212H37.023a4.65,4.65,0,0,0-4.591,4.606v6.251a4.513,4.513,0,0,0,4.433,4.606H45.1a4.515,4.515,0,0,0,4.433-4.606V22.753l-3.958.164Z"
                    transform="translate(11.583 4.719)" />
                  <path id="svg_21"
                    d="M96.214,30.268h13.774V18.753h-7.916V23.03h3.957v3.125h-9.34a1.43,1.43,0,0,1-1.268-1.481v-9.87a1.325,1.325,0,0,1,1.268-1.481h12.665V9.212H96.372c-2.691,0-4.907,2.632-4.907,5.758v9.541C91.305,27.636,93.364,30.268,96.214,30.268Z"
                    transform="translate(32.663 3.29)" fill="#f4e116" />
                  <path id="svg_22"
                    d="M96.214,30.268h13.774V18.753h-7.916V23.03h3.957v3.125h-9.34a1.43,1.43,0,0,1-1.268-1.481v-9.87a1.325,1.325,0,0,1,1.268-1.481h12.665V9.212H96.372c-2.691,0-4.907,2.632-4.907,5.758v9.541C91.305,27.636,93.364,30.268,96.214,30.268Z"
                    transform="translate(32.663 3.29)" />
                  <path id="svg_23"
                    d="M123.534,28.84V19.3l4.593,9.541h3.482l4.434-9.541V28.84H140V13.212h-5.541l-4.591,9.706-4.591-9.706h-5.7V28.84h3.957Z"
                    transform="translate(42.706 4.719)" fill="#f4e116" />
                  <path id="svg_24"
                    d="M123.534,28.84V19.3l4.593,9.541h3.482l4.434-9.541V28.84H140V13.212h-5.541l-4.591,9.706-4.591-9.706h-5.7V28.84h3.957Z"
                    transform="translate(42.706 4.719)" />
                  <path id="svg_25"
                    d="M56.114,34.268v4.113H127.2l12.825-25.169h-4.434l-3.8,7.074-3.641-7.074H123.56l6.016,11.351-5.066,9.706h-68.4Z"
                    transform="translate(20.041 4.719)" fill="#f4e116" />
                  <path id="svg_26"
                    d="M56.114,34.268v4.113H127.2l12.825-25.169h-4.434l-3.8,7.074-3.641-7.074H123.56l6.016,11.351-5.066,9.706h-68.4Z"
                    transform="translate(20.041 4.719)" />
                  <path id="svg_27"
                    d="M133.359,12.883a3.24,3.24,0,0,0,3.166-3.29,3.169,3.169,0,1,0-6.332,0A3.24,3.24,0,0,0,133.359,12.883Zm0-6.087a2.8,2.8,0,1,1-2.691,2.8,2.721,2.721,0,0,1,2.691-2.8Z"
                    transform="translate(46.497 2.251)" fill="#f4e116" />
                  <path id="svg_28"
                    d="M133.359,12.883a3.24,3.24,0,0,0,3.166-3.29,3.169,3.169,0,1,0-6.332,0A3.24,3.24,0,0,0,133.359,12.883Zm0-6.087a2.8,2.8,0,1,1-2.691,2.8,2.721,2.721,0,0,1,2.691-2.8Z"
                    transform="translate(46.497 2.251)" />
                  <path id="svg_29"
                    d="M132.142,9.7h.95c.318,0,.475.164.475.493v.658h.634v-.658a.763.763,0,0,0-.475-.822.9.9,0,0,0,.634-.987c0-.822-.318-.987-.95-.987h-1.584v3.29h.634V9.7Zm0-1.645h.793c.475,0,.475.164.475.493,0,.494-.159.658-.634.658h-.791V8.052h.157Z"
                    transform="translate(47.081 2.641)" fill="#f4e116" />
                  <path id="svg_30"
                    d="M132.142,9.7h.95c.318,0,.475.164.475.493v.658h.634v-.658a.763.763,0,0,0-.475-.822.9.9,0,0,0,.634-.987c0-.822-.318-.987-.95-.987h-1.584v3.29h.634V9.7Zm0-1.645h.793c.475,0,.475.164.475.493,0,.494-.159.658-.634.658h-.791V8.052h.157Z"
                    transform="translate(47.081 2.641)" />
                  <path id="svg_31"
                    d="M132.142,9.7h.95c.318,0,.475.164.475.493v.658h.634v-.658a.763.763,0,0,0-.475-.822.9.9,0,0,0,.634-.987c0-.822-.318-.987-.95-.987h-1.584v3.29h.634V9.7Zm0-1.645h.793c.475,0,.475.164.475.493,0,.494-.159.658-.634.658h-.791V8.052h.157Z"
                    transform="translate(47.081 2.641)" />
                  <path id="svg_32"
                    d="M89.461,17.55V23.8a4.584,4.584,0,0,1-1.266,3.126A4.584,4.584,0,0,0,89.461,23.8V17.55a4.585,4.585,0,0,0-1.266-3.125A4.033,4.033,0,0,1,89.461,17.55Z"
                    transform="translate(31.498 5.152)" fill="#f4e116" />
                  <path id="svg_33"
                    d="M89.461,17.55V23.8a4.584,4.584,0,0,1-1.266,3.126A4.584,4.584,0,0,0,89.461,23.8V17.55a4.585,4.585,0,0,0-1.266-3.125A4.033,4.033,0,0,1,89.461,17.55Z"
                    transform="translate(31.498 5.152)" />
                  <path id="svg_34" d="M86.353,21.87h0a1.59,1.59,0,0,0,1.109-.658A1.59,1.59,0,0,1,86.353,21.87Z"
                    transform="translate(29.54 7.576)" fill="#f4e116" />
                  <path id="svg_35" d="M86.353,21.87h0a1.59,1.59,0,0,0,1.109-.658A1.59,1.59,0,0,1,86.353,21.87Z"
                    transform="translate(29.54 7.576)" />
                  <path id="svg_36"
                    d="M76.529,17.515a4.7,4.7,0,0,1,1.583-3.455,4.2,4.2,0,0,0-1.583,3.455v6.251a4.835,4.835,0,0,0,1.108,2.961,4.835,4.835,0,0,1-1.108-2.961Z"
                    transform="translate(27.332 5.022)" fill="#f4e116" />
                  <path id="svg_37"
                    d="M76.529,17.515a4.7,4.7,0,0,1,1.583-3.455,4.2,4.2,0,0,0-1.583,3.455v6.251a4.835,4.835,0,0,0,1.108,2.961,4.835,4.835,0,0,1-1.108-2.961Z"
                    transform="translate(27.332 5.022)" />
                  <path id="svg_38"
                    d="M79.762,16.485a1.282,1.282,0,0,0-.317.822v0C79.446,16.978,79.6,16.814,79.762,16.485Z"
                    transform="translate(28.373 5.887)" fill="#f4e116" />
                  <path id="svg_39"
                    d="M79.762,16.485a1.282,1.282,0,0,0-.317.822v0C79.446,16.978,79.6,16.814,79.762,16.485Z"
                    transform="translate(28.373 5.887)" />
                  <path id="svg_40"
                    d="M93.712,17.939a4.579,4.579,0,0,0-1.268-3.125,4.236,4.236,0,0,0-3.325-1.481H80.887a4.887,4.887,0,0,0-3.008,1.152A4.7,4.7,0,0,0,76.3,17.939v6.251A4.835,4.835,0,0,0,77.4,27.152,4.468,4.468,0,0,0,80.887,28.8H89.12a4.847,4.847,0,0,0,3.325-1.481,4.579,4.579,0,0,0,1.268-3.125V17.939Zm-3.959,5.593a.619.619,0,0,1-.159.494,1.053,1.053,0,0,1-1.107.658H81.521a1.1,1.1,0,0,1-1.109-1.152v-5.1a1.282,1.282,0,0,1,.317-.823,1.186,1.186,0,0,1,.792-.329h6.966a1.1,1.1,0,0,1,1.107,1.152l.159,5.1Z"
                    transform="translate(27.249 4.762)" fill="#f4e116" />
                  <path id="svg_41"
                    d="M93.712,17.939a4.579,4.579,0,0,0-1.268-3.125,4.236,4.236,0,0,0-3.325-1.481H80.887a4.887,4.887,0,0,0-3.008,1.152A4.7,4.7,0,0,0,76.3,17.939v6.251A4.835,4.835,0,0,0,77.4,27.152,4.468,4.468,0,0,0,80.887,28.8H89.12a4.847,4.847,0,0,0,3.325-1.481,4.579,4.579,0,0,0,1.268-3.125V17.939Zm-3.959,5.593a.619.619,0,0,1-.159.494,1.053,1.053,0,0,1-1.107.658H81.521a1.1,1.1,0,0,1-1.109-1.152v-5.1a1.282,1.282,0,0,1,.317-.823,1.186,1.186,0,0,1,.792-.329h6.966a1.1,1.1,0,0,1,1.107,1.152l.159,5.1Z"
                    transform="translate(27.249 4.762)" />
                  <path id="svg_42"
                    d="M93.712,17.939a4.579,4.579,0,0,0-1.268-3.125,4.236,4.236,0,0,0-3.325-1.481H80.887a4.887,4.887,0,0,0-3.008,1.152A4.7,4.7,0,0,0,76.3,17.939v6.251A4.835,4.835,0,0,0,77.4,27.152,4.468,4.468,0,0,0,80.887,28.8H89.12a4.847,4.847,0,0,0,3.325-1.481,4.579,4.579,0,0,0,1.268-3.125V17.939Zm-3.959,5.593a.619.619,0,0,1-.159.494,1.053,1.053,0,0,1-1.107.658H81.521a1.1,1.1,0,0,1-1.109-1.152v-5.1a1.282,1.282,0,0,1,.317-.823,1.186,1.186,0,0,1,.792-.329h6.966a1.1,1.1,0,0,1,1.107,1.152l.159,5.1Z"
                    transform="translate(27.249 4.762)" />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="d-flex align-items-center">
          <div class="col-5">
            <h1 class="do3-fw800 do3-blue-text my-3">Scopri i MOVEs e il MOVERGY</h1>
            <p class="do3-gray-text info-text">
              Il movimento è alla base di uno stile
              di vita sano. Ecco perché abbiamo creato
              MOVEs e MOVERGY.
              <br>
              <br>
              I MOVEs sono l’unità di misura per valutare
              oggettivamente Il tuo stile di vita, in base
              al tipo di esercizio che fai, a prescindere
              da genere , età o livello di fitness.
              <br>
              Per essere in buona salute, dovresti fare
              1000 MOVEs al giorno.
              <br>
              <br>
              Il MOVERGY, invece, è il tuo trend di attività
              su 2 settimane. Con MOVEs e MOVERGY
              tieni alta la motivazione, ti confronti
              con i tuoi amici e accendi una sana
              competizione
            </p>
          </div>
          <div class="col-7">
            <div class="videoWrapper">
              <iframe title="vimeo-player" src="https://player.vimeo.com/video/565974576?h=946540e49d" width="680px"
                height="320px" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>