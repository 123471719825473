import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { AssistenzaComponent } from './features/assistenza/components/assistenza.component';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { StoreModule } from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth/store/auth.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { MeEffects } from './core/header/store/me.effects';
import { CalendarCommonModule, CalendarModule } from 'angular-calendar';
import { SharedModule } from './shared/shared.module';
import { ConsensoModule } from './features/consenso/consenso.module';
import { MedicProfileModule } from './features/medic-profile/medic-profile.module';
import { PrenotazioneEffects } from './features/prenotazione/store/effects/prenotazione.effects';
import { ProfiloModule } from './features/profilo/profilo.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth, setPersistence } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { PERSISTENCE } from '@angular/fire/compat/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { RichiesteEffects } from './features/richieste/store/richieste.effects';
import { ProfiloEffects } from './features/profilo/store/profilo.effects';
import { MedicProfileEffects } from './features/medic-profile/store/medic-profile.effects';
import { LogoutModule } from './features/logout/logout.module';
import { CookiePolicyComponent } from './features/cookie-policy/cookie-policy.component';
import { TerminiCondizioniComponent } from './features/termini-condizioni/termini-condizioni.component';
import { PrivacyPolicyComponent } from './features/privacy-policy/privacy-policy.component';
import { ConsensoEffects } from './features/consenso/store/consenso.effects';
import { ProfiloPazienteEffects } from './features/profilo-paziente/store/profilo-paziente.effects';
import { HmEffects } from './features/hm/store/hm.effects';
import { TranslateModule } from '@ngx-translate/core';
import { ChatPageModule } from './features/chat-page/chat-page.module';
@NgModule({
  declarations: [
    AppComponent,
    AssistenzaComponent,
    CookiePolicyComponent,
    TerminiCondizioniComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    CoreModule,
    MatExpansionModule,
    MatListModule,
    AuthModule,
    StoreModule.forRoot(fromApp.appReducers),
    EffectsModule.forRoot([
      AuthEffects,
      MeEffects,
      PrenotazioneEffects,
      RichiesteEffects,
      ProfiloEffects,
      MedicProfileEffects,
      ConsensoEffects,
      ProfiloPazienteEffects,
      HmEffects,
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    HttpClientModule,
    CalendarCommonModule,
    CalendarModule,
    SharedModule,
    ConsensoModule,
    MedicProfileModule,
    ProfiloModule,
    LogoutModule,
    ChatPageModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    AngularFireStorageModule,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: PERSISTENCE, useValue: 'local' },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
