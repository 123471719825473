<!-- VALUTARE SE VISIBILE ALTROVE 
<ng-container *ngIf="allRichieste && !isLoading">
  <div class="anamnesi" *ngIf="allRichieste.length > 0">
    <div class="row do3-fw800 do3-blue-text ms-1 mb-2">
      <div class="col-3" *ngIf="isFull">Tipologia</div>
    </div>
    <ng-container *ngFor="let richiesta of allRichieste; let i = index">
      <app-single-anamnesi
        *ngIf="richiesta.hasTriage"
        [richiesta]="richiesta"
        [isTriage]="false"
        [isFull]="isFull"
      ></app-single-anamnesi>
    </ng-container> 
  </div>
  <ng-container *ngIf="allRichieste.length == 0">
    <h1 class="d-flex justify-content-center m-5 do3-fw600">
      Il paziente non ha ancora compilato i questionari MyHealth
    </h1>
  </ng-container> 
</ng-container>
-->
<div *ngIf="dataOnBoarding">
  <ng-container>
    <app-single-anamnesi
      [richiesta]="dataOnBoarding"
      [isTriage]="false"
      [isFull]="isFull"
      [healthScoreId]="null"
      [pazienteId]="pazienteId"
      [isOnBoarding]="true"
    ></app-single-anamnesi>
  </ng-container>
</div>

<ng-container
  *ngIf="receivedStatusScore === 'NEW' && dataSurvey && !selectedHealthScoreId"
>
  <h1 class="d-flex justify-content-center m-5 do3-fw600">
    Il paziente non ha ancora compilato i questionari MyHealth questo mese.
  </h1>
</ng-container>

<div
  *ngIf="!selectedHealthScoreId && receivedStatusScore !== 'NEW' && dataSurvey"
>
  <h1 class="d-flex justify-content-center m-5 do3-fw600">
    Questionari completati di questo mese:
  </h1>
  <ng-container *ngFor="let survey of dataSurvey; let i = index">
    <app-single-anamnesi
      [richiesta]="survey"
      [isTriage]="false"
      [isFull]="isFull"
      [healthScoreId]="healthScoreId"
      [pazienteId]="pazienteId"
      [isOnBoarding]="false"
    ></app-single-anamnesi>
  </ng-container>
</div>

<div *ngIf="selectedHealthScoreId && dataSurvey">
  <h1
    *ngIf="!fromTelevisita"
    class="d-flex justify-content-center m-5 do3-fw600"
  >
    Questionari completati a {{ selectedPeriod }}:
  </h1>
  <ng-container *ngFor="let survey of dataSurvey; let i = index">
    <app-single-anamnesi
      [richiesta]="survey"
      [isTriage]="false"
      [isFull]="isFull"
      [healthScoreId]="selectedHealthScoreId"
      [pazienteId]="pazienteId"
      [isOnBoarding]="false"
    ></app-single-anamnesi>
  </ng-container>
</div>
