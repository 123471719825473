<div class="custom-navbar">
  <div class="container px-5">
    <div class="row">
      <div class="col-12">
        <mat-toolbar>
          <div class="menu-button">
            <button mat-icon-button (click)="onToggleSidenav()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <div>
            <a routerLink="/">
              <img
                class="logo-navbar"
                src="./assets/images/logo-colori.png"
                alt="logo"
              />
            </a>
          </div>
          <div class="justify-content-end w-100 navbar-menu">
            <ul *ngIf="!auth" class="navigation-items">
              <li>
                <a href="https://drfeel.com/" target="_blank">Home</a>
              </li>

              <li>
                <a routerLink="/login" routerLinkActive="activeLink">Accedi </a>
              </li>
            </ul>
            <ul *ngIf="auth" class="navigation-items">
              <li *ngIf="!isSeller">
                <a routerLink="/appuntamenti" routerLinkActive="activeLink"
                  >Appuntamenti</a
                >
              </li>
              <li *ngIf="!isSeller">
                <a routerLink="/storico" routerLinkActive="activeLink"
                  >Storico</a
                >
              </li>
              <!-- LISTA PAZIENTI APERTA A TUTTI (specialisti vedono solo pazienti con cui hanno richieste) -->
              <li>
                <a routerLink="/patients" routerLinkActive="activeLink"
                  >Lista pazienti</a
                >
              </li>
              <!-- LISTA PAZIENTI SOLO PER HM, HM_DOC, SELLER -->
              <!-- <li *ngIf="isHm || isHm_doc || isSeller">
                <a routerLink="/patients" routerLinkActive="activeLink"
                  >Lista pazienti</a
                >
              </li> -->
              <li>
                <a
                  routerLink="/chat"
                  target="_blank"
                  routerLinkActive="activeLink"
                  >Chat</a
                >
              </li>
              <li>
                <a routerLink="/assistenza" routerLinkActive="activeLink"
                  >Centro Assistenza</a
                >
              </li>
            </ul>
          </div>
        </mat-toolbar>
      </div>
    </div>
  </div>
</div>
