<app-loading-spinner
  class="row d-flex justify-content-center"
  *ngIf="isLoading"
></app-loading-spinner>
<section
  class="storico"
  *ngIf="!isLoading && allRichieste && allRichieste.length > 0"
>
  <div class="row do3-fw800 do3-blue-text header">
    <!--<div class="col-1"></div>-->
    <div class="col-10">
      <div class="row">
        <div class="col-2">ID</div>
        <div class="col-2">Nome</div>
        <div class="col-2">Data visita</div>
        <div class="col-2">Tipologia</div>
        <div class="col-2">A pagamento</div>
        <div class="col-2">Stato</div>
      </div>
    </div>
    <div class="col-2"></div>
  </div>

  <div *ngFor="let richiesta of allRichieste">
    <div class="storico-box">
      <div class="row align-items-center">
        <!--<div class="col-1">
          <div class="box">
            <div class="box-content" *ngIf="!isHm">
              <div class="avatar-border">
                <ngx-avatars
                  alt="avatar"
                  [textSizeRatio]="3"
                  bgColor="#FF3D77 "
                  fgColor="white"
                  class="circle my-avatar"
                  [name]="richiesta.doctor.name"
                  size="50"
                  [round]="true"
                  [src]="avatarUrl"
                >
                </ngx-avatars>
              </div>
            </div>
          </div>
        </div>-->
        <div class="col-10 align-items-center">
          <div class="row align-items-center">
            <div class="col-2">{{ richiesta.crmNo }}</div>
            <div class="col-2">{{ richiesta.medicalProvider.name }}</div>
            <div class="col-2">
              {{ richiesta.startDateTime | date : "longDate" }}
            </div>
            <div class="col-2">
              {{ richiesta.medicalSpecialization.description }}
              <span
                class="do3-fw600 do3-lightblue-text"
                *ngIf="patientHasBundle(richiesta)"
                ><br />Percorso {{ richiesta.orderInfo?.name }}</span
              >
            </div>
            <div class="col-2">
              {{ richiesta.paymentInfo.amount > 0 ? "Sì" : "No" }}
            </div>
            <div [ngSwitch]="richiesta.status" class="col-2">
              <span
                *ngSwitchCase="'OPEN'"
                class="badge text-white text-uppercase lh-sm bg-success"
                >Attivo</span
              >
              <span
                *ngSwitchCase="'CLOSED'"
                class="badge text-white text-uppercase lh-sm bg-warning"
                >Archiviato</span
              >
              <span
                *ngSwitchCase="'DELETED'"
                class="badge text-white text-uppercase lh-sm bg-danger"
                >Eliminato</span
              >
            </div>
          </div>
        </div>
        <div
          class="col-2 d-flex gap-1 align-items-center justify-content-between"
        >
          <!-- <button type="button" class="btn" *ngIf="richiesta.status !== 'DELETED'">
            <a [href]="'detail/' + richiesta.id">Dettaglio</a>
          </button> -->

          <a
            [href]="'detail/' + richiesta.id"
            class="icon-button do3-fw600 tooltip-custom lightBlue"
            *ngIf="richiesta.status !== 'DELETED'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="6"
              viewBox="0 0 192 512"
            >
              <path
                d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z"
                fill="#fff"
              />
            </svg>
            <span class="tooltiptext fs-6 text-capitalize">Apri Dettaglio</span>
          </a>

          <button
            type="button"
            class="do3-secondary-btn lightBlue reason-button text-uppercase fs-3 do3-fw600 tooltip-custom"
            (click)="toggleReason(richiesta)"
            [ngClass]="{ 'disabled-button': !richiesta.reason }"
            *ngIf="richiesta.status !== 'DELETED'"
          >
            +
            <span class="tooltiptext fs-6 text-capitalize">Motivo Visita</span>
          </button>

          <button
            data-bs-toggle="modal"
            data-bs-target="#modalDelete"
            *ngIf="isHm && richiesta.status === 'OPEN'"
            data-id="{{ richiesta.id }}"
            (click)="setRequestId(richiesta.id)"
            class="delete-btn"
            [disabled]="
              richiesta.paymentInfo.amount != 0 ||
              expireDate(
                richiesta.startDateTime,
                richiesta.medicalSpecialization.code
              )
            "
            [attr.title]="
              richiesta.paymentInfo.amount != 0
                ? 'Gli appuntamenti a pagamento non possono essere cancellati'
                : expireDate(
                    richiesta.startDateTime,
                    richiesta.medicalSpecialization.code
                  )
                ? expireRequestMessage
                : null
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              fill="red"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"
              ></path>
              <path
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"
              ></path>
            </svg>
          </button>
        </div>
        <div id="{{ richiesta.id }}" class="col-12 reason d-none">
          <span><b>Motivo della visita: </b>{{ richiesta.reason }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Pagination -->
  <ng-container *ngIf="getTotalPages() > 0">
    <div class="row justify-content-center">
      <div class="col-auto">
        <div class="pagination">
          <a
            class="page-link"
            (click)="handlePageClick(0)"
            [ngClass]="{
              disabled: richiesteMeta.first
            }"
          >
            <span class="material-icons-outlined">first_page</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(page - 1)"
            [ngClass]="{
              disabled: richiesteMeta.first
            }"
          >
            <span class="material-icons-outlined">chevron_left</span>
          </a>
          <span class="page-indicator"
            >{{ page + 1 }} / {{ getTotalPages() }}</span
          >
          <a
            class="page-link"
            (click)="handlePageClick(page + 1)"
            [ngClass]="{
              disabled: richiesteMeta.last
            }"
          >
            <span class="material-icons-outlined">chevron_right</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(getTotalPages() - 1)"
            [ngClass]="{
              disabled: richiesteMeta.last
            }"
          >
            <span class="material-icons-outlined">last_page</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</section>

<section *ngIf="(!isLoading && !allRichieste) || allRichieste?.length == 0">
  <h1 class="d-flex justify-content-center m-5 do3-fw600">
    Al momento non ci sono appuntamenti.
  </h1>
</section>

<!-- Modal -->
<div
  class="modal fade"
  id="modalDelete"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#FF3D77"
          width="32"
          height="32"
          class="bi bi-trash mb-3"
          viewBox="0 0 16 16"
        >
          <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"
          ></path>
          <path
            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"
          ></path>
        </svg>
        <p>Confermi di voler eliminare l'appuntamento?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn light-blue text-white text-uppercase"
          data-bs-dismiss="modal"
        >
          Annulla
        </button>
        <button
          type="button"
          class="btn blue text-uppercase text-white"
          (click)="deleteRequest()"
          data-bs-dismiss="modal"
        >
          Conferma
        </button>
      </div>
    </div>
  </div>
</div>
