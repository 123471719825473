import { HttpClient } from '@angular/common/http';
import { NonNullAssert } from '@angular/compiler';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PatientDatum, PatientDetail } from 'src/app/model/Patient';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patients-item',
  templateUrl: './patients-item.component.html',
  styleUrls: ['./patients-item.component.scss'],
})
export class PatientsItemComponent implements OnInit {
  @Input() patient: PatientDetail;
  @Input() isHm: boolean | null;
  @Input() isSeller: boolean | null = false;
  @Output() handleClick: EventEmitter<PatientDetail> = new EventEmitter();
  public avatarUrl: any;
  public success: boolean = false;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.getAvatar();
  }

  handleDetailClick(patient: PatientDetail) {
    this.handleClick.emit(patient);
  }

  getAvatar() {
    // Debug
    // return;
    return this.http
      .get(`${environment.NEW_API}/patients/${this.patient.id}/image`, {
        responseType: 'blob',
      })
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  goToPazienteProfile(patientId: any): void {
    this.router.navigate(['/profilo-paziente', patientId]);
  }

  activateChat(patientId: any): void {
    console.log('activateChat', patientId);

    this.http
      .post<any>(`${environment.NEW_API}/seller/${patientId}`, patientId)
      .subscribe(
        (data) => {
          console.log('activateChat', data);
          this.success = true;
        },
        (error) => {
          console.error('activateChat', error);
        }
      );
  }
}
