<!-- Title banner -->
<div class="container">
  <div class="row banner">
    <div class="col-12">
      <span
        *ngIf="
          listType == 'today' || listType == 'future' || listType == 'closed'
        "
        class="material-icons-outlined"
        >calendar_month</span
      >
      <span *ngIf="listType == 'open'" class="material-icons-outlined"
        >calendar_month</span
      >
      <span *ngIf="listType == 'today'" class="text"
        >Appuntamenti di {{ dateDisplay | date : "EEEE d MMMM y" }}
      </span>
      <span *ngIf="listType !== 'today'" class="text">{{ dateDisplay }} </span>
      <span *ngIf="listType == 'open'" class="text"
        >Pazienti da refertare
      </span>
      <span *ngIf="listType == 'closed'" class="text"
        >Appuntamenti archiviati
      </span>
    </div>
  </div>
  <div *ngIf="!richieste || richieste.items.length === 0">
    <div class="row justify-content-center mt-5">
      <h1 class="text-center no-requests">
        Al momento non hai alcuna prenotazione.
      </h1>
    </div>
  </div>
  <div
    *ngIf="richieste.items.length > 0"
    class="appuntamenti-table align-middle"
  >
    <div class="row th">
      <div class="col-1"></div>
      <div class="col-2">Nome</div>
      <div class="col-2" *ngIf="listType === 'today'">Ora</div>
      <div
        class="col-2"
        *ngIf="
          listType === 'future' || listType === 'closed' || listType === 'open'
        "
      >
        Data visita/ora
      </div>
      <div class="col-2">Tipo di visita</div>
      <div class="col-2">A pagamento</div>
      <!-- 
        <div class="col-1"><span>Consenso</span></div>
        <div class="col-1"><span>Questionario</span></div> 
      -->
      <div class="col-1"><span>Documenti</span></div>
      <div class="col"></div>
    </div>

    <div *ngIf="richieste && listType !== 'closed'">
      <ng-container *ngFor="let richiesta of richiesteData">
        <!-- <app-appuntamenti-item [richiesta]="richiesta" [mode]="mode" [listType]="listType"
          *ngIf="!(richiesta.needTriage && !richiesta.hasTriage) && ((listType !== 'open') || (listType === 'open' && richiesta.hasInformedConsent))"
          (handleClick)="handleDetailClick($event)"></app-appuntamenti-item> -->

        <!-- ATTESA CONSENSO BE DOCTOR
          <app-appuntamenti-item
          [sectionTab]="sectionTab"
          [isHm]="isHm"
          [richiesta]="richiesta"
          [mode]="mode"
          [listType]="listType"
          *ngIf="
            listType !== 'open' ||
            (listType === 'open' && richiesta.hasInformedConsent)
          "
          (handleClick)="handleDetailClick($event)"
          (handleDelete)="handleDeleteRequest($event)"
          ></app-appuntamenti-item> 
        -->

        <app-appuntamenti-item
          [sectionTab]="sectionTab"
          [isHm]="isHm"
          [myId]="myId"
          [richiesta]="richiesta"
          [mode]="mode"
          [listType]="listType"
          (handleClick)="handleDetailClick($event)"
          (handleDelete)="handleDeleteRequest($event)"
        ></app-appuntamenti-item>
      </ng-container>
    </div>
    <div *ngIf="richieste && listType === 'closed'">
      <ng-container *ngFor="let richiesta of richiesteClosed">
        <!-- <app-appuntamenti-item [richiesta]="richiesta" [mode]="mode" [listType]="listType"
          *ngIf="!(richiesta.needTriage && !richiesta.hasTriage) && ((listType !== 'open') || (listType === 'open' && richiesta.hasInformedConsent))"
          (handleClick)="handleDetailClick($event)"></app-appuntamenti-item> -->

        <app-appuntamenti-item
          [sectionTab]="sectionTab"
          [isHm]="isHm"
          [myId]="myId"
          [richiesta]="richiesta"
          [mode]="mode"
          [listType]="listType"
          (handleClick)="handleDetailClick($event)"
          (handleDelete)="handleDeleteRequest($event)"
        ></app-appuntamenti-item>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="pagination.totalElements > 0">
    <!-- Pagination -->
    <div class="row justify-content-center">
      <div class="col-auto">
        <div class="pagination">
          <a
            class="page-link"
            (click)="handlePageClick(0)"
            [ngClass]="{
              disabled: page === 0
            }"
          >
            <span class="material-icons-outlined">first_page</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(page - 1)"
            [ngClass]="{
              disabled: page === 0
            }"
          >
            <span class="material-icons-outlined">chevron_left</span>
          </a>
          <span class="page-indicator">{{ page + 1 }} / {{ totalPages }}</span>
          <a
            class="page-link"
            (click)="handlePageClick(page + 1)"
            [ngClass]="{
              disabled: pagination.last
            }"
          >
            <span class="material-icons-outlined">chevron_right</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(totalPages - 1)"
            [ngClass]="{
              disabled: pagination.last
            }"
          >
            <span class="material-icons-outlined">last_page</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
