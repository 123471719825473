<div class="row table-row">
  <div class="col-1">
    <div class="img-profile-container medium big-border mx-0">
      <img
        class="img-profile pointer"
        [src]="avatarUrl"
        alt="{{ richiesta.patient.name }}"
        (click)="goToPazienteProfile(richiesta.patient.id)"
      />
    </div>
  </div>
  <div class="col-2">
    {{
      richiesta.patient.name.length > 20
        ? (richiesta.patient.name | slice : 0 : 20) + ".."
        : richiesta.patient.name
    }}
  </div>
  <div class="col-2" *ngIf="listType == 'today'">
    {{ richiesta.startDateTime | date : "shortTime" }}
  </div>
  <div
    class="col-2"
    *ngIf="
      listType === 'future' || listType === 'closed' || listType === 'open'
    "
  >
    {{ richiesta.startDateTime | date : "longDate" }} <br />
    Ore:
    {{ richiesta.startDateTime | date : "shortTime" }}
  </div>
  <div class="col-2">
    {{ richiesta.medicalSpecialization.description }}
    <span
      class="do3-fw600 do3-lightblue-text"
      *ngIf="patientHasBundle(richiesta)"
      ><br />Percorso {{ richiesta.orderInfo?.name }}</span
    >
  </div>
  <div class="col-2">
    {{ richiesta.paymentInfo.amount > 0 ? "Sì" : "No" }}
  </div>
  <!-- IN ATTESA DI CONSENSO BE DOCTOR, QUESTIONARI NO
    <div
      class="col-1"
      [ngClass]="richiesta.hasInformedConsent === true ? 'green' : 'red'"
    >
      <span>
        {{ richiesta.hasInformedConsent === true ? "Firmato" : "Da firmare" }}
      </span>
    </div>
    <div class="col-1">
      <span class="no-wrap">
        <ng-container *ngIf="richiesta.needTriage && richiesta.hasTriage">
          <span class="green"> Compilata </span>
        </ng-container>
        <ng-container *ngIf="richiesta.needTriage && !richiesta.hasTriage">
          <span class="red"> Da compilare </span>
        </ng-container>
        <ng-container *ngIf="!richiesta.needTriage"> Non richiesta </ng-container>
      </span>
    </div> 
  -->

  <!-- DOCUMENTI COLLEGATI ALLA RICHIESTA -->
  <div *ngIf="richiesta.documentCount == 0" class="col-1">Non presente</div>
  <div class="no-wrap" *ngIf="richiesta.documentCount == 1" class="col-2">
    {{ richiesta.documentCount }} presente
  </div>
  <div class="no-wrap" *ngIf="richiesta.documentCount > 1" class="col-2">
    {{ richiesta.documentCount }} presenti
  </div>

  <div class="col last">
    <div
      *ngIf="mode === 'appuntamenti' && myId === richiesta.medicalProvider.id"
      type="button"
      class="icon-button lightBlue me-2 tooltip-custom"
      (click)="handleDetailClick(richiesta)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="18"
        viewBox="0 0 576 512"
      >
        <path
          d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"
          fill="#fff"
        />
      </svg>
      <span class="tooltiptext fs-9 text-capitalize">Apri videoconsulto</span>
    </div>
    <div
      *ngIf="mode !== 'appuntamenti'"
      type="button"
      class="icon-button lightBlue me-2 tooltip-custom"
      (click)="handleDetailClick(richiesta)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="6"
        viewBox="0 0 192 512"
      >
        <path
          d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z"
          fill="#fff"
        />
      </svg>
      <span class="tooltiptext text-capitalize">Vedi dettagli</span>
    </div>
    <!-- MOTIVO DELLA VISITA -->
    <div
      type="button"
      class="icon-button lightBlue do3-fw600 me-2 fs-3 tooltip-custom"
      (click)="toggleReason()"
      [ngClass]="{ 'disabled-button': !richiesta.reason }"
    >
      +
      <span class="tooltiptext text-capitalize">Motivo Visita</span>
    </div>

    <button
      data-bs-toggle="modal"
      data-bs-target="#modalDelete"
      *ngIf="
        isHm && richiesta.status === 'OPEN' && sectionTab !== 'Da refertare'
      "
      data-id="{{ richiesta.id }}"
      (click)="handleDeleteRequest(richiesta.id)"
      class="delete-btn"
      [disabled]="
        richiesta.paymentInfo.amount != 0 ||
        expireDate(
          richiesta.startDateTime,
          richiesta.medicalSpecialization.code
        )
      "
      [attr.title]="
        richiesta.paymentInfo.amount != 0
          ? 'Gli appuntamenti a pagamento non possono essere cancellati'
          : expireDate(
              richiesta.startDateTime,
              richiesta.medicalSpecialization.code
            )
          ? expireRequestMessage
          : null
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        fill="red"
        class="bi bi-trash"
        viewBox="0 0 16 16"
      >
        <path
          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"
        ></path>
        <path
          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"
        ></path>
      </svg>
    </button>
  </div>

  <!-- MOSTRA MOTIVO DELLA VISITA -->
  <div *ngIf="showReason" class="col-12 note">
    <p><b>Motivo della visita: </b>{{ richiesta.reason }}</p>
  </div>
</div>
