import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kpi-input',
  templateUrl: './kpi-input.component.html',
  styleUrls: ['./kpi-input.component.scss'],
})
export class KpiInputComponent implements OnInit {
  @Input() patientId: string = '';
  @Input() isHm: boolean | null = false;
  @Output() statusScoreChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Input() selectedHealthScoreId: string | null;
  @Input() selectedPeriod: string | null;
  myHealthScore: string;
  health: string;
  mind: string;
  movement: string;
  nutrition: string;
  idScore: string;
  statusScore: string;
  processing: boolean;
  public loading: boolean = true;
  public error: boolean = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getHealthScore();
  }

  ngOnChanges(): void {
    this.getHealthScore();
  }

  getHealthScore() {
    this.loading = true;
    if (this.selectedHealthScoreId) {
      this.http
        .get(`${environment.NEW_API}/patients/${this.patientId}/health-scores`)
        .subscribe((response: any) => {
          if (response.items) {
            let filteredResponse = response.items.filter(
              (score: any) => score.id === this.selectedHealthScoreId
            );
            console.log(filteredResponse);
            this.myHealthScore = filteredResponse[0].score;
            this.health = filteredResponse[0].zoneScore.HEALTH;
            this.mind = filteredResponse[0].zoneScore.MIND;
            this.movement = filteredResponse[0].zoneScore.MOVEMENT;
            this.nutrition = filteredResponse[0].zoneScore.NUTRITION;
          }
        });
      this.loading = false;
    } else {
      this.http
        .get(`${environment.NEW_API}/patients/${this.patientId}/health-score`)
        .subscribe(
          (response: any) => {
            if (response) {
              this.idScore = response.id || '';
              this.statusScore = response.status || '';
              this.myHealthScore = response.score || '';
              this.health = response.zoneScore.HEALTH || '';
              this.mind = response.zoneScore.MIND || '';
              this.movement = response.zoneScore.MOVEMENT || '';
              this.nutrition = response.zoneScore.NUTRITION || '';
              this.statusScoreChange.emit(this.statusScore);
            } else {
              this.idScore = '';
              this.statusScore = '';
              this.myHealthScore = '';
              this.health = '';
              this.mind = '';
              this.movement = '';
              this.nutrition = '';
            }

            if (
              Number(this.health) !== 0 &&
              Number(this.mind) !== 0 &&
              Number(this.movement) !== 0 &&
              Number(this.nutrition) !== 0 &&
              Number(this.myHealthScore) !== 0
            ) {
              this.processing = true;
            } else {
              this.processing = false;
            }

            console.log('kpi input response', response);
            this.loading = false;
          },
          (err) => {
            this.errorHandling(err);
          }
        );
    }
  }

  private errorHandling(errMessage: any) {
    console.error(errMessage);
    this.error = true;
    this.loading = false;
  }

  onSubmit(idScore: string) {
    if (!this.isHm) {
      return;
    }
    const data = {
      id: idScore,
      score: this.myHealthScore,
      zoneScore: {
        HEALTH: this.health,
        MIND: this.mind,
        NUTRITION: this.nutrition,
        MOVEMENT: this.movement,
      },
    };

    this.http
      .post(
        `${environment.NEW_API}/patients/${this.patientId}/health-score`,
        data
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.getHealthScore();
        },
        (err) => {
          this.errorHandling(err);
        }
      );
  }

  onCompleted(idScore: string) {
    this.http
      .patch(
        `${environment.NEW_API}/patients/${this.patientId}/health-score/${idScore}/complete`,
        null
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.getHealthScore();
        },
        (err) => {
          this.errorHandling(err);
        }
      );
  }
}
