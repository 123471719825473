<ng-container *ngIf="richiesta">
  <div class="single-anamnesi do3-fw600">
    <!-- <div class="row do3-gray-text">
      <div class="col kpi-survey" *ngIf="!isTriage && isFull">
        {{ handleType(richiesta.specialization.name) }}
      </div>
      <div class="col-9 kpi-survey" *ngIf="!isTriage && !isFull">
        <p>Questionario {{ handleType(richiesta.specialization.name) }}</p>
      </div>
      <div class="col-3" *ngIf="isTriage">
        {{ richiesta.specialization.name }}
      </div>
      <div class="col-1">
        <div class="d-flex align-items-center justify-content-between">
          <div *ngIf="isTriage">
            {{ richiesta.slot.from | date : "longDate" }}
          </div>
          <mat-icon (click)="getAnamnesi(richiesta.id)">expand_more</mat-icon>
        </div>
      </div>
    </div> -->

    <div class="row do3-gray-text">
      <div class="col d-flex justify-content-between align-items-center">
        <div class="flex-grow-1 kpi-survey" *ngIf="!isTriage && isFull && isOnBoarding">
          {{ richiesta.surveyType }}
        </div>
        <div class="flex-grow-1 kpi-survey" *ngIf="!isTriage && isFull && !isOnBoarding">
          <img [src]="richiesta.imageUrl" [name]="richiesta.title" />
          {{ richiesta.healthZone }}
        </div>
        <div class="flex-grow-1 kpi-survey" *ngIf="!isTriage && !isFull && isOnBoarding">
          <span>Questionario {{ richiesta.surveyType }}</span>
        </div>
        <div class="flex-grow-1 kpi-survey" *ngIf="!isTriage && !isFull && !isOnBoarding">
          <span>Questionario {{ richiesta.healthZone }}</span>
        </div>
        <div class="flex-grow-1" *ngIf="isTriage">
          {{ richiesta.specialization.name }}
        </div>
        <div class="">
          <div class="d-flex align-items-center justify-content-between">
            <div *ngIf="isTriage">
              {{ richiesta.slot.from | date : "longDate" }}
            </div>
            <mat-icon (click)="getAnamnesi(richiesta.id)">expand_more</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="openPanel">
      <div class="row answers">
        <app-loading-spinner
          class="d-flex justify-content-center"
          *ngIf="isLoading"
        ></app-loading-spinner>
        <div *ngIf="!isLoading" class="col">
          <p *ngFor="let quest of anamnesi.questions; let i = index">
            <span class="do3-fw800 do3-blue-text">
              {{ i + 1 }} - {{ quest.question }}
            </span>
            <br />
            <span *ngIf="quest.selectedAnswer">
              {{ quest.selectedAnswer.description }}
            </span>
            <span *ngIf="quest.userAnswer.value">
              {{ quest.userAnswer.value }}
            </span>
            <span *ngIf="quest.type === 'MULTIPLE'">
              <span *ngFor="let answer of quest.userAnswer.answers">
                - {{ answer.selected.description }} <br/>
              </span>
            </span>
            <span *ngIf="quest.subQuestions">
              <span *ngFor="let subQuestion of quest.subQuestions; let j = index">
                <span class="do3-fw800 do3-blue-text ps-4">{{ j + 1 }} - {{subQuestion.question}}</span>
                <br />
                <span class="ps-4">{{subQuestion.selectedAnswer.description}}</span>
                <br />
              </span>
            </span>
            <!-- <span *ngIf="!quest.selectedAnswer" class="bg-danger text-white">
              Si è verificato un errore
            </span> -->
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
