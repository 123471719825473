import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistenza',
  templateUrl: './assistenza.component.html',
  styleUrls: ['./assistenza.component.scss'],
})
export class AssistenzaComponent implements OnInit {
  headerTitle: string;
  headerDescription: string;
  assistantName: string = 'Elvira';
  assistantNumber: string = '340 094 95 43';
  // supervisorName: string = 'Giuseppe';
  // supervisorNumber: string = '392 744 76 36';
  supportoITemail: string = 'supportoit@drfeel.com';

  accordions: any = [
    {
      funzioni_generali: [
        {
          title: 'Come posso modificare i dati del mio profilo?',
          description: `Per facilitarti nella gestione del tuo account le informazioni presenti nel tuo profilo vengono inserite dal nostro team. Se hai esigenza di modificare qualcosa contatta la nostra Assistant ${this.assistantName} al Numero Verde Whatsapp ${this.assistantNumber}`,
        },
        {
          title: 'Posso modificare il mio CV?',
          description: `Certo! Il tuo CV può essere modificato in qualunque momento. Se desideri apportare modifiche a questa sezione contatta la nostra Assistant ${this.assistantName} contattandola al Numero Verde (Whatsapp) ${this.assistantNumber}.`,
        },
        {
          title:
            'Dove posso controllare o modificare le mie impostazioni di privacy?',
          description:
            'Per controllare o modificare le impostazioni vai alla sezione “Privacy” presente nel tuo profilo. In quest’area potrai modificare in qualunque momento le tue preferenze per il trattamento dei dati personali.',
        },
        {
          title: 'Come posso modificare i dati di fatturazione?',
          description: `Puoi rivolgerti alla nostra Assistant ${
            this.assistantName
          } al Numero Verde Whatsapp <a href="${this.formatPhoneNumber(
            this.assistantNumber
          )}" target="_blank" class="activeLink">${this.assistantNumber}</a>.`,
        },
      ],
      appuntamenti: [
        {
          title: 'Dove posso visualizzare i miei appuntamenti?',
          description:
            'Per visualizzare i tuoi appuntamenti entra in piattaforma con le credenziali che ti abbiamo fornito. Una volta finalizzato l’accesso, visualizzerai immediatamente gli appuntamenti del giorno e quelli futuri. Per gestire in serenità la tua agenda ricorda di controllare quotidianamente la tua casella e-mail: ogni mattina ti invieremo un reminder con la lista degli appuntamenti del giorno ed eventuali variazioni al calendario.',
        },
        {
          title: 'Come posso modificare un appuntamento?',
          description: `Per modificare o cancellare un appuntamento contatta la nostra Assistant ${
            this.assistantName
          } al Numero Verde Whatsapp <a href="${this.formatPhoneNumber(
            this.assistantNumber
          )}" target="_blank" class="activeLink">${
            this.assistantNumber
          }</a>. Sarà lei ad occuparsi personalmente di modificare le tue disponibilità e aggiornarle in piattaforma.`,
        },
        {
          title:
            'Quanto tempo prima posso disdire o riprogrammare un appuntamento?',
          description:
            'Il limite massimo è 3 ore prima dell’appuntamento. Nel caso tu non possa rispettarlo, contatta immediatamente il nostro Health Manager. Ricorda! Non puoi disdire o modificare un appuntamento per più di 4 volte al mese.',
        },
        {
          title: 'Cosa succede se un utente disdice un appuntamento?',
          description:
            'Ti arriverà una e-mail e una segnalazione da parte dell’Health Manager nella quale ti verrà chiesto di fissare un nuovo appuntamento nel primo slot disponibile.',
        },
        {
          title:
            'Cosa devo fare se per sbaglio non mi presento a un videoconsulto?',
          description:
            'Contatta tempestivamente l’Health Manager per comunicare il problema, si occuperà personalmente di contattare il paziente. Per qualunque comunicazione improvvisa, inoltre, puoi sempre utilizzare la chat raggiungibile dal menù superiore. Ricorda! In base all’attuale Policy non potrai disdire un videoconsulto senza preavviso per più di 2 volte in un mese, ed è severamente vietato non presentarsi a un videoconsulto con lo stesso utente per 2 volte consecutive.',
        },
        {
          title:
            'Se un paziente fa ritardo, entro quanto tempo posso interrompere il videoconsulto?',
          description:
            'Se il paziente non si connette entro 10 minuti dall’orario stabilito, puoi ritenere concluso il videoconsulto.',
        },
        {
          title: 'Come posso impostare le mie disponibilità?',
          description: `Per aggiungere le tue disponibilità in piattaforma compila il foglio Excel che trovi a questo <a href="../../../../assets/docs/Calendar_drfeel_elv1.csv" target="_blank" download="calendar.csv" class="activeLink">link</a> e invialo alla nostra Assistant ${
            this.assistantName
          } al Numero Verde Whatsapp <a href="${this.formatPhoneNumber(
            this.assistantNumber
          )}" target="_blank" class="activeLink">${
            this.assistantNumber
          }</a>. si occuperà personalmente di gestire e aggiornare il tuo calendario.`,
        },
      ],
      chat: [
        {
          title: 'Come posso mettermi in contatto con un paziente?',
          description:
            'Se vuoi parlare con un paziente, accedi alla chat direttamente dal menù superiore. Potrai comunicare contemporaneamente con la persona interessata e con l’Health Manager a lei assegnata.',
        },
        {
          title: 'Quanto tempo ho a disposizione per rispondere a un paziente?',
          description:
            'Per garantire la migliore esperienza di salute possibile, controlla la chat e assicurati che i tuoi pazienti non siano in attesa di informazioni urgenti. Ti consigliamo di guardare la chat ogni 120 minuti.',
        },
        {
          title: 'Posso caricare un documento in chat?',
          description:
            'Sì, puoi caricare direttamente un documento in chat cliccando sul tasto “+” posizionato a sinistra del campo di testo. Attenzione! Quando inoltri un documento in chat ricorda sempre di inviarlo anche all’Health Manager che si occuperà di caricarli nella sezione “profilo” del paziente. Per offrire la migliore esperienza di salute possibile puoi caricare tutta la documentazione sanitaria anche nella richiesta di videoconsulto, raggiungibile dall’area “Pazienti da refertare” alla voce vedi “Vedi dettagli”. Clicca su “Documenti”, seleziona la cartella di riferimento tra “Referti”, “Prescrizioni”, “Esami” oppure “Immagini”, clicca su “Carica” e invia.',
        },
        {
          title:
            'Non riesco a recuperare un vecchio messaggio, cosa posso fare?',
          description:
            'Se non riesci a trovare una vecchia conversazione scorri tutta la pagina “chat” e poi clicca sul tasto “Load More” a sinistra dello schermo.',
        },
      ],
      myhealth_score: [
        {
          title: 'Cos’è il MyHealth Score?',
          description:
            'Il MyHealth score è un indicatore che permette di valutare la qualità dello stato di salute e del benessere fisico e mentale di una persona.',
        },
        {
          title: 'Come si calcola il MyHealth score?',
          description:
            'Il MyHealth score viene calcolato attraverso la somministrazione di questionari sulle abitudini alimentari, sportive e sullo stato di benessere mentale e fisico della persona.',
        },
        {
          title: 'Dove posso visualizzare i risultati del MyHealth score?',
          description:
            'Per visualizzare il MyHealth score del tuo paziente visita il suo profilo. Puoi accedere ai profili dei tuoi pazienti dalla sezione “Lista pazienti” alla voce “Dettagli”.',
        },
        {
          title:
            'Dove posso trovare le risposte ai questionari del MyHealth score?',
          description:
            'Puoi accedere alle risposte dei questionari nella sezione MyHealth score presente nel profilo del tuo paziente. Puoi accedere ai profili del paziente dalla sezione “Lista pazienti” alla voce “Dettagli”.',
        },
      ],
      videoconsulto: [
        {
          title: 'Come faccio ad accedere al Videoconsulto?',
          description:
            'Per collegarti al Videoconsulto vai alla sezione “Appuntamenti”, clicca sul tasto “Collegati” e segui la procedura guidata.',
        },
        {
          title: 'Il paziente non mi vede. Cosa posso fare?',
          description:
            'Verifica che la tua webcam sia accesa e che tu abbia fornito al servizio le autorizzazioni necessarie. Per verificare clicca sull’icona del Video in basso a sinistra. Ricorda che per qualunque comunicazione improvvisa con il paziente, puoi sempre utilizzare la chat raggiungibile dal menù superiore.',
        },
        {
          title: 'Il paziente non mi sente. Cosa posso fare?',
          description:
            'Verifica che il tuo microfono sia abilitato e che tu abbia fornito al servizio le autorizzazioni necessarie. Per verificare clicca sull’icona del Microfono in basso a destra. Ricorda che per qualunque comunicazione improvvisa con il paziente, puoi sempre utilizzare la chat raggiungibile dal menù superiore.',
        },
        {
          title: 'Ho un problema tecnico, cosa posso fare?',
          description: `Puoi rivolgerti al nostro Team di Supporto IT all'email  <a href="mailto:${this.supportoITemail}" class="activeLink">${this.supportoITemail}</a>.`,
        },
        {
          title:
            'Cosa posso fare se il videoconsulto si interrompe all’improvviso?',
          description:
            'Contatta tempestivamente l’Health Manager che si occuperà personalmente di contattare il paziente per riprogrammare l’appuntamento. Per qualunque comunicazione improvvisa con il paziente, inoltre, puoi sempre utilizzare la chat raggiungibile dal menù superiore. Ricorda! Qualunque problema tu possa avere puoi sempre contare sull’aiuto della nostra assistenza tecnica.',
        },
        {
          title:
            'Cosa posso fare se il paziente si disconnette all’improvviso?',
          description:
            'Contatta l’Health Manager, si occuperà di valutare con l’assistenza tecnica se riattivare il videoconsulto o fissare un nuovo appuntamento nel primo slot disponibile. Ricorda che per qualunque comunicazione improvvisa con il paziente, puoi sempre utilizzare la chat raggiungibile dal menù superiore.',
        },
      ],
      documenti: [
        {
          title:
            'Dove posso caricare i referti, le prescrizioni, una foto o qualunque altro documento clinico?',
          description:
            'Puoi inviare i documenti in chat oppure caricare i documenti clinici nella sezione “Paziente da refertare” cliccando sul tasto “Vedi dettagli” e selezionando la voce “Documenti” Prima di caricare, spunta la cartella di riferimento tra “Referti”, “Prescrizioni”, “Esami” oppure “Immagini”, clicca su “Carica” e invia',
        },
        {
          title: 'Posso attivare o disattivare un questionario anamnestico?',
          description:
            'Certo! Per attivare o disattivare un questionario anamnestico vai alla sezione “Questionari” presente nel tuo profilo e clicca sul tasto “sì - no”.',
        },
        {
          title:
            'Quanto tempo ho a disposizione per inviare un referto di prima valutazione?',
          description:
            'Il tempo massimo per l’invio del referto di prima valutazione da parte del Medico Internista è 8 ore.',
        },
        {
          title:
            'Quanto tempo ho a disposizione per inviare un piano di salute e benessere personalizzato?',
          description:
            'Il tempo massimo per l’invio di un piano di Salute e Benessere personalizzato da parte del Medico Specialista è 10 ore.',
        },
        {
          title: 'Come posso firmare un documento?',
          description:
            'La policy di Dr. Feel prevede che ogni documento sanitario venga firmato attraverso la firma digitale. Se non sei in possesso di una firma digitale puoi usare in via del tutto una firma in versione PNG.',
        },
        {
          title: 'Dove devo inviare i documenti al paziente?',
          description:
            'Per garantire al tuo paziente la migliore esperienza possibile invia i documenti sia in chat che nella sezione “Documenti”.',
        },
      ],
    },
  ];

  constructor() {}

  viewMode: any;
  ngOnInit(): void {
    this.viewMode = 'funzioni_generali';
    this.headerTitle = 'Funzioni Generali';
    this.headerDescription =
      'Vuoi avere qualche informazione in più su come gestire il tuo account? Cerca la risposta tra le domande frequenti.';
  }

  formatPhoneNumber(phoneNumber: string): string {
    phoneNumber = phoneNumber.replace(/\s/g, '');

    if (!phoneNumber.startsWith('https://wa.me/39')) {
      phoneNumber = 'https://wa.me/39' + phoneNumber;
    }

    return phoneNumber;
  }
}
