import { Action, createReducer, on } from '@ngrx/store';
import { DoctorTriageData } from 'src/app/model/DoctorTriage';
import { Policy, PolicyData } from 'src/app/model/Policy';
import * as ProfileActions from './profilo.actions';

export interface State {
  documents: any[];
  loading: boolean;
  error: boolean;
  errorMessage: any;
  policies: PolicyData | null;
  loadingPolicies: boolean;
  doctorTriage: DoctorTriageData | null;
}

const initialState: State = {
  documents: [],
  loading: false,
  error: false,
  errorMessage: null,
  policies: null,
  loadingPolicies: false,
  doctorTriage: null,
};

const _profileReducer = createReducer(
  initialState,

  on(ProfileActions.fetchDocuments, (state) => ({
    ...state,
    loading: true,
  })),

  on(ProfileActions.fetchDocumentsSuccess, (state, action) => ({
    ...state,
    documents: action.documents,
    loading: false,
  })),

  on(ProfileActions.fetchDocumentsFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    loading: false,
  })),

  on(ProfileActions.fetchPoliciesSuccess, (state, action) => ({
    ...state,
    policies: action.policies,
    loadingPolicies: true,
  })),

  on(ProfileActions.fetchTriagesSuccess, (state, action) => ({
    ...state,
    doctorTriage: action.triages.data,
  })),

  on(ProfileActions.resetProfile, (state) => ({
    ...state,
    documents: [],
    loading: false,
    error: false,
    errorMessage: null,
    policies: null,
  }))
);

export function profileReducer(state: State | undefined, action: Action) {
  return _profileReducer(state, action);
}
