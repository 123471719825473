<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<ng-container *ngIf="!loading">
  <div class="chat-root">
    <div id="chat-body">
      <div class="list-col">
        <!-- <input
          class="search-bar"
          type="text"
          (change)="filterChannels($event)"
          placeholder="Search members..."
        /> -->
        <div class="input-group" *ngIf="!id">
          <input
            class="search-bar"
            type="text"
            #searchInput
            placeholder="Search members..."
            (change)="filterChannels($event)"
          />
          <label class="checkbox-unread">
            <input type="checkbox" (change)="toggleUnreadFilter($event)" />
            Mostra solo messaggi non letti
          </label>
          <!-- <button
            class="search-button"
            (click)="filterChannels(searchInput.value)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#080707"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </button> -->
        </div>
        <stream-channel-list *ngIf="!id" class="with-filters">
        </stream-channel-list>
        <stream-channel-list *ngIf="id" class="no-filters">
        </stream-channel-list>
      </div>
      <stream-channel>
        <stream-channel-header></stream-channel-header>
        <stream-message-list></stream-message-list>
        <stream-notification-list></stream-notification-list>
        <stream-message-input></stream-message-input>
        <stream-thread name="thread">
          <stream-message-list mode="thread"></stream-message-list>
          <stream-message-input mode="thread"></stream-message-input>
        </stream-thread>
      </stream-channel>
    </div>
  </div>
</ng-container>

<ng-template #customMessageTemplate let-message="message">
  <app-message [message]="message"></app-message>
</ng-template>
