<footer>
  <div class="container main-container">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img
          class="logo-footer"
          src="./assets/images/logo-bianco.png"
          alt="Dr.Feel"
        />
        <p class="ml-4">© 2023 drfeel.com All rights reserved</p>
      </div>

      <div>
        <ul class="legal d-flex align-items-center">
          <li class="item-copy">
            <a
              target="_black"
              class="mr-1"
              href="https://www.iubenda.com/privacy-policy/54416982/cookie-policy"
              >Cookie Policy</a
            >
          </li>
          <li class="item-copy">
            <a target="_black" class="mr-1" href="/assets/docs/DrFeel_TC.pdf"
              >Termini e Condizioni</a
            >
          </li>
          <li class="item-copy">
            <a
              target="_black"
              class=""
              href="/assets/docs/Privacy_Policy_DrFeel.pdf"
              >Privacy Policy</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
