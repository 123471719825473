<div class="vh-100">
  <mat-drawer-container>
    <mat-drawer #sidenav role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-drawer>
    <mat-drawer-content>
      <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
      <div style="min-height: 100vh">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
