import { Action, createReducer, on } from '@ngrx/store';
import * as ProfiloPazienteActions from './profilo-paziente.actions';
import { ProfiloPazienteDatum } from 'src/app/model/ProfiloPaziente';
import { PatientMeasurement } from 'src/app/model/PatientMeasurement';
import { PatientMembership } from 'src/app/model/PatientMembership';
import { PatientHealthTeam } from 'src/app/model/PatientHealthTeam';
import { PatientMembershipProfile } from 'src/app/model/PatientMembershipProfile';

export interface State {
  profiloPaziente: ProfiloPazienteDatum | null;
  pazienteMeasurements: PatientMeasurement | null;
  // patientActiveMembership: PatientMembership | null;
  // patientHealthTeam: PatientHealthTeam | null;
  patientMembershipProfile: PatientMembershipProfile | null;
  loading: boolean;
  error: boolean;
  errorMessage: any;
  documents: any[] | null;
  loadingDocs: boolean;
  richieste: any[] | null;
  meta: any | null;
}

const initialState: State = {
  profiloPaziente: null,
  pazienteMeasurements: null,
  // patientActiveMembership: null,
  // patientHealthTeam: null,
  patientMembershipProfile: null,
  loading: false,
  error: false,
  errorMessage: null,
  documents: [],
  loadingDocs: false,
  richieste: null,
  meta: null,
};

const _profiloPazienteReduces = createReducer(
  initialState,

  on(ProfiloPazienteActions.fetchProfiloPaziente, (state) => ({
    ...state,
    profiloPaziente: null,
    loading: true,
  })),

  on(ProfiloPazienteActions.fetchProfiloPazienteSuccess, (state, action) => ({
    ...state,
    profiloPaziente: action.profiloPaziente,
    loading: false,
  })),

  on(ProfiloPazienteActions.fetchProfiloPazienteFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(ProfiloPazienteActions.fetchProfiloMeasurementsPaziente, (state) => ({
    ...state,
    pazienteMeasurements: null,
    loading: true,
  })),

  on(
    ProfiloPazienteActions.fetchProfiloMeasurementsPazienteSuccess,
    (state, action) => ({
      ...state,
      pazienteMeasurements: action.measurements,
      loading: false,
    })
  ),

  on(
    ProfiloPazienteActions.fetchProfiloMeasurementsPazienteFailure,
    (state, action) => ({
      ...state,
      loading: false,
      error: true,
      errorMessage: action.error,
    })
  ),

  on(ProfiloPazienteActions.fetchDocumentsPaziente, (state) => ({
    ...state,
    loadingDocs: true,
  })),

  on(ProfiloPazienteActions.fetchDocumentsPazienteSuccess, (state, action) => ({
    ...state,
    documents: action.documents,
    meta: action.meta,
    loadingDocs: false,
  })),

  on(ProfiloPazienteActions.fetchDocumentsPazienteFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    loadingDocs: false,
  })),

  on(ProfiloPazienteActions.resetProfiloPaziente, (state) => ({
    ...state,
    profiloPaziente: null,
    loading: false,
    error: false,
    loadingDocs: false,
    documents: null,
  })),

  on(ProfiloPazienteActions.fetchRichiestePazienteSuccess, (state, action) => ({
    ...state,
    richieste: action.richieste,
    meta: action.meta,
  })),

  on(ProfiloPazienteActions.fetchRichiestePaziente, (state) => ({
    ...state,
    richieste: null,
    meta: null,
  }))

  // on(ProfiloPazienteActions.fetchPatientActiveMembership, (state) => ({
  //   ...state,
  //   patientActiveMembership: null,
  //   loading: true,
  // })),

  // on(
  //   ProfiloPazienteActions.fetchPatientActiveMembershipSuccess,
  //   (state, action) => ({
  //     ...state,
  //     patientActiveMembership: action.membership,
  //     loading: false,
  //   })
  // ),

  // on(
  //   ProfiloPazienteActions.fetchPatientActiveMembershipFailure,
  //   (state, action) => ({
  //     ...state,
  //     loading: false,
  //     error: true,
  //     errorMessage: action.error,
  //   })
  // ),

  // on(ProfiloPazienteActions.fetchPatientHealthTeam, (state) => ({
  //   ...state,
  //   patientHealthTeam: null,
  //   loading: true,
  // })),

  // on(ProfiloPazienteActions.fetchPatientHealthTeamSuccess, (state, action) => ({
  //   ...state,
  //   patientHealthTeam: action.healthTeam,
  //   loading: false,
  // })),

  // on(ProfiloPazienteActions.fetchPatientHealthTeamFailure, (state, action) => ({
  //   ...state,
  //   loading: false,
  //   error: true,
  //   errorMessage: action.error,
  // })),

  // on(ProfiloPazienteActions.fetchPatientMembershipProfile, (state) => ({
  //   ...state,
  //   patientMembershipProfile: null,
  //   loading: true,
  // })),

  // on(
  //   ProfiloPazienteActions.fetchPatientMembershipProfileSuccess,
  //   (state, action) => ({
  //     ...state,
  //     patientMembershipProfile: action.membershipProfile,
  //     loading: false,
  //   })
  // ),

  // on(
  //   ProfiloPazienteActions.fetchPatientMembershipProfileFailure,
  //   (state, action) => ({
  //     ...state,
  //     loading: false,
  //     error: true,
  //     errorMessage: action.error,
  //   })
  // )
);

export function profiloPazienteReducer(
  state: State | undefined,
  action: Action
) {
  return _profiloPazienteReduces(state, action);
}
