<div class="container main-container do3-gray-text consenso">
  <div class="row">
    <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>
  </div>

  <div class="row">
    <app-topper-info-doctor></app-topper-info-doctor>
  </div>

  <app-dati-profilo></app-dati-profilo>

  <app-consenso-informato></app-consenso-informato>
  <!-- toggle: -->
  <div class="row border-top">
    <div class="col-md-12">
      <div class="w-45">
        <div class="row mb-4 mt-4">
          <div class="col-8 p-privacy">
            Ho letto e compreso le condizioni del consenso informato.
          </div>
          <div class="toggle-button-cover col-4">
            <div class="button-cover">
              <div class="button r" id="button-1">
                <input
                  [(ngModel)]="showOTP"
                  type="checkbox"
                  value=""
                  ngModel
                  class="checkbox"
                  name="defaultCheck1"
                  id="defaultCheck1"
                  (ngOnclick)="toggleConsenso($event)"
                  (ngModelChange)="toggleConsenso($event)"
                  required
                />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="showOTP">
        <div class="col-3 d-flex justify-content-center">
          <p class="do3-blue-text">
            <span class="fw-bold">Ci siamo quasi!</span> <br />
            Hai ricevuto una mail con un <span class="fw-bold">codice</span> di
            autenticazione,
            <span class="fw-bold">inseriscilo nel campo</span> per ultimare la
            procedura.
          </p>
        </div>
        <div class="col-3 d-flex justify-content-center">
          <!-- Inserisci otp -->
          <div class="col-9 d-flex align-items-center">
            <div class="form-group">
              <!-- Error message -->
              <p class="alert alert-danger" *ngIf="error">
                <span class="fw-bold">{{ errorTitle }}</span> <br />
                {{ errorMessage }}
              </p>
              <!-- <label for="exampleInputEmail1">Inserisci il codice ottento via email</label> -->
              <input
                class="form-control"
                [(ngModel)]="otpCode"
                type="text"
                id="otp"
                aria-describedby="otp"
                placeholder="Inserisci il codice"
              />
              <button
                class="ms-auto do3-primary-btn blue mt-3 do3-fw600"
                (click)="acceptInformedConsent()"
              >
                Completa e invia
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
