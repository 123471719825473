<section>
  <div class="info">
    <div>
      <h2 *ngIf="!isHM" class="info-name mb-0" [class]="size">{{ userName }}</h2>
      <h2 *ngIf="isHM" class="info-name mb-0" [class]="size">Health Manager</h2>
      <p *ngIf="isHM">{{ fullName }}</p>

      <!-- <small class="do3-gray-text ml-1">Completamento profilo {{me?.completionPercentage}}%</small> -->
    </div>
    <app-user-avatar
      [fullName]="fullName"
      class="info-avatar"
      [me]="me"
      [size]="size"
    >
    </app-user-avatar>
  </div>
</section>
