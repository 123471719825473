<div class="row privacy">
  <div class="col-12">
    <h1 class="do3-fw600 do3-blue-text">
      Ecco le tue preferenze per il trattamento dei dati personali. <br /><b
        >Scarica il documento</b
      >
      per visualizzare tutte le informazioni
    </h1>
  </div>

  <div class="col-12 do3-gray-text">
    <div class="row align-items-center privacy-container">
      <div class="privacy-item col-9">
        <p>
          Ho letto e compreso le
          <a
            href="{{
              this.env.NEW_API + '/policies/content-by-type/TermsOfServices'
            }}"
            class="do3-fw800 activeLink"
            target="_blank"
            >Informazioni pre-contrattuali.</a
          >
        </p>
      </div>

      <!-- <div class="col-3">
        <a href="#">
          <mat-icon class="material-symbols-outlined">file_download</mat-icon>
        </a>
      </div> -->
    </div>

    <div class="row align-items-center privacy-container">
      <div class="privacy-item col-9">
        <p>
          Ho letto e compreso l'informativa
          <a
            href="{{ this.env.NEW_API + '/policies/content-by-type/Privacy' }}"
            class="do3-fw800 activeLink"
            target="_blank"
            >Privacy Policy</a
          >
          e
          <a
            href="https://www.iubenda.com/privacy-policy/54416982/cookie-policy"
            class="do3-fw800 activeLink"
            target="_blank"
            >Cookie Policy</a
          >
          ed ho inteso che i miei dati saranno inviati al centro servizi per
          l’erogazione delle prestazioni sanitaria.
        </p>
      </div>

      <!-- <div class="col-3">
        <a href="#">
          <mat-icon class="material-symbols-outlined">file_download</mat-icon>
        </a>
      </div> -->
    </div>

    <div class="row align-items-center privacy-container" *ngIf="policies">
      <div class="privacy-item col-7">
        <p>
          Ho letto e compreso le
          <span class="do3-fw800">caratteristiche principali</span>, i
          <span class="do3-fw800">termini del programma di Affiliazione</span> e
          i <span class="do3-fw800">servizi</span> di drfeel.com.
        </p>
      </div>
      <p class="col-3 data-text">
        Data accettazione:
        {{ policies[1].lastUpdate | date : "shortDate" }}
      </p>
      <div class="col-1">
        <div class="toggle-button-cover">
          <div class="button-cover">
            <div class="button r" id="button-1">
              <input
                [ngModel]="policies[1].accepted"
                type="checkbox"
                class="checkbox"
                name="privacy3"
                id="privacy3"
                [required]="policies[1].mandatory"
                [disabled]="policies[1].mandatory"
              />
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1">
        <a [href]="getPolicyLink(policies[1].policyId)" target="_blank">
          <mat-icon>save_alt</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
