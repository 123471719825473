import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromApp from '../../../store/app.reducer';
import * as RichiesteActions from '../../richieste/store/richieste.actions';
import { RichiestaDatum, Pagination } from 'src/app/model/Richiesta';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cronologia',
  templateUrl: './cronologia.component.html',
  styleUrls: ['./cronologia.component.scss'],
})
export class CronologiaComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  public richieste: RichiestaDatum[];
  public pagination: Pagination;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(RichiesteActions.fetchAllRichieste({}));
    this.storeSub = this.store.select('richieste').subscribe((richieste) => {
      if (richieste.allRichieste) {
        this.richieste = richieste.allRichieste.items;
        this.pagination = richieste.allRichieste.pagination;
      }
    });
  }

  onRichiestaClick(richiesta: RichiestaDatum) {
    this.router.navigate(['/medic', richiesta.medicalProvider.id]);
  }

  getDoctorAvatar(richiesta: RichiestaDatum) {
    return `${environment.NEW_API}/doctors/${richiesta.medicalProvider.id}/image`;
  }

  ngOnDestroy(): void {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
