import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as ProfiloPazienteActions from './profilo-paziente.actions';
import { of, pipe } from 'rxjs';
import { ProfiloPaziente } from 'src/app/model/ProfiloPaziente';
import { PatientMembership } from 'src/app/model/PatientMembership';
import { PatientHealthTeam } from 'src/app/model/PatientHealthTeam';
import { ProfiloDocument } from 'src/app/model/ProfiloDocument';
import { Richiesta } from 'src/app/model/Richiesta';
import { PatientMeasurement } from 'src/app/model/PatientMeasurement';
import { PatientMembershipProfile } from 'src/app/model/PatientMembershipProfile';

@Injectable()
export class ProfiloPazienteEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  fetchProfiloPaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchProfiloPaziente),
      switchMap((action) => {
        console.log('fetching profilo paziente');
        return this.http.get<ProfiloPaziente>(
          environment.NEW_API + '/patients/' + action.pazienteId
        );
      }),
      pipe(
        map((patient) => {
          console.log('profilo paziente from effects', patient);
          if (patient) {
            return ProfiloPazienteActions.fetchProfiloPazienteSuccess({
              profiloPaziente: patient.patient,
            });
          } else {
            return ProfiloPazienteActions.fetchProfiloPazienteFailure({
              error: 'error: ',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching profilo paziente', error);
          return of(
            ProfiloPazienteActions.fetchProfiloPazienteFailure({
              error: 'errore',
            })
          );
        })
      )
    )
  );

  fetchMeasurementsPaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchProfiloMeasurementsPaziente),
      switchMap((action) => {
        const url = `${environment.NEW_API}/patients/${action.pazienteId}/measurements`;
        return this.http.get<PatientMeasurement>(url);
      }),
      pipe(
        map((patient) => {
          console.log('misurazioni paziente from effects', patient);
          if (patient) {
            return ProfiloPazienteActions.fetchProfiloMeasurementsPazienteSuccess(
              {
                measurements: patient,
              }
            );
          } else {
            return ProfiloPazienteActions.fetchProfiloMeasurementsPazienteFailure(
              {
                error: 'error: ',
              }
            );
          }
        }),
        catchError((error) => {
          console.error('Error while fetching measurements paziente', error);
          return of(
            ProfiloPazienteActions.fetchProfiloMeasurementsPazienteFailure({
              error: 'errore',
            })
          );
        })
      )
    )
  );

  fetchRichiestePaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchRichiestePaziente),
      switchMap((action) => {
        return this.getRichieste(action);
      }),
      pipe(
        map((anamnesi) => {
          console.log('anamnesi profilo from effect', anamnesi);

          return ProfiloPazienteActions.fetchRichiestePazienteSuccess({
            richieste: anamnesi.items,
            meta: anamnesi.pagination,
          });
        })
      )
    )
  );

  getRichieste(action: any) {
    // let url = environment.NEW_API + '/requests';
    // let searchAnam;
    // if (action.withTriage) {
    //   searchAnam = {
    //     filter: {
    //       filterBy: {
    //         operation: 'AND',
    //         filters: [
    //           {
    //             field: 'patient.id',
    //             operation: 'EQ',
    //             value: action.idPatient,
    //           },
    //           {
    //             field: 'needTriage',
    //             operation: 'EQ',
    //             value: true,
    //           },
    //           {
    //             field: 'hasTriage',
    //             operation: 'EQ',
    //             value: true,
    //           },
    //         ],
    //       },
    //     },
    //     pagination: {
    //       page: 0,
    //       size: 0,
    //     },
    //   };
    // } else if (action.surveyRequest) {
    //   searchAnam = {
    //     filter: {
    //       filterBy: {
    //         operation: 'AND',
    //         filters: [
    //           {
    //             field: 'patient.id',
    //             operation: 'EQ',
    //             value: action.idPatient,
    //           },
    //           {
    //             field: 'surveyRequest',
    //             operation: 'EQ',
    //             value: true,
    //           },
    //         ],
    //       },
    //     },
    //     pagination: {
    //       page: 0,
    //       size: 0,
    //     },
    //   };
    // } else {
    //   searchAnam = {
    //     filter: {
    //       filterBy: {
    //         operation: 'AND',
    //         filters: [
    //           {
    //             field: 'patient.id',
    //             operation: 'EQ',
    //             value: action.idPatient,
    //           },
    //         ],
    //       },
    //     },
    //     pagination: {
    //       page: 0,
    //       size: 0,
    //     },
    //   };
    // }
    // const base64 = btoa(JSON.stringify(searchAnam));
    let url =
      environment.NEW_API +
      '/requests?f.patient_id_eq=' +
      action.idPatient +
      '&sort=-start_date_time';
    if (action.pageItems) {
      url += '&pageItems=' + action.pageItems;
    }
    if (action.page) {
      url += '&page=' + action.page;
    }
    console.log('fetching all richieste profilo paziente', url);
    return this.http.get<Richiesta>(url);
  }

  fetchDocumentsPaziente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloPazienteActions.fetchDocumentsPaziente),
      switchMap((action) => {
        // const url =
        //   action.isHm || action.isHm_doc
        //     ? `${environment.NEW_API}/hm/patients/${action.pazienteId}/documents`
        //     : `${environment.NEW_API}/patients/${action.pazienteId}/documents`;

        let url = `${environment.NEW_API}/patients/${action.pazienteId}/documents?f.type_in=${action.documentType}&sort=-date_time&pageItems=${action.pageItems}`;
        if (action.page) {
          url += '&page=' + action.page;
        }
        console.log('fetching profilo');
        console.log(url);
        return this.http.get<ProfiloDocument>(url);
      }),
      map((docs) => {
        console.log('all profilo documents from effects', docs);
        if (docs) {
          return ProfiloPazienteActions.fetchDocumentsPazienteSuccess({
            documents: docs.items,
            meta: docs.pagination,
          });
        } else {
          return ProfiloPazienteActions.fetchDocumentsPazienteFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  // fetchPatientActiveMembership$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfiloPazienteActions.fetchPatientActiveMembership),
  //     switchMap((action) => {
  //       console.log('fetching patient active membership');
  //       return this.http.get<PatientMembership>(
  //         environment.NEW_API +
  //           '/patients/' +
  //           action.pazienteId +
  //           '/membership/active'
  //       );
  //     }),
  //     pipe(
  //       map((membership) => {
  //         console.log('patient active membership from effects', membership);
  //         if (membership) {
  //           return ProfiloPazienteActions.fetchPatientActiveMembershipSuccess({
  //             membership: membership,
  //           });
  //         } else {
  //           return ProfiloPazienteActions.fetchPatientActiveMembershipFailure({
  //             error: 'error: ',
  //           });
  //         }
  //       }),
  //       catchError((error) => {
  //         console.error(
  //           'Error while fetching patient active membership',
  //           error
  //         );
  //         return of(
  //           ProfiloPazienteActions.fetchPatientActiveMembershipFailure({
  //             error: 'errore',
  //           })
  //         );
  //       })
  //     )
  //   )
  // );

  // fetchPatientHealthTeam$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfiloPazienteActions.fetchPatientHealthTeam),
  //     switchMap((action) => {
  //       console.log('fetching patient health team');
  //       return this.http.get<PatientHealthTeam>(
  //         environment.NEW_API +
  //           '/patients/' +
  //           action.pazienteId +
  //           '/membership/health-team'
  //       );
  //     }),
  //     pipe(
  //       map((healthteam) => {
  //         console.log('patient health team from effects', healthteam);
  //         if (healthteam) {
  //           return ProfiloPazienteActions.fetchPatientHealthTeamSuccess({
  //             healthTeam: healthteam,
  //           });
  //         } else {
  //           return ProfiloPazienteActions.fetchPatientHealthTeamFailure({
  //             error: 'error: ',
  //           });
  //         }
  //       }),
  //       catchError((error) => {
  //         console.error('Error while fetching patient health team', error);
  //         return of(
  //           ProfiloPazienteActions.fetchPatientHealthTeamFailure({
  //             error: 'errore',
  //           })
  //         );
  //       })
  //     )
  //   )
  // );

  // fetchPatientMembershipProfile$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfiloPazienteActions.fetchPatientMembershipProfile),
  //     switchMap((action) => {
  //       console.log('fetching patient membership profile');
  //       return this.http.get<PatientMembershipProfile>(
  //         environment.NEW_API +
  //           '/patients/' +
  //           action.pazienteId +
  //           '/membership/profile'
  //       );
  //     }),
  //     pipe(
  //       map((profile) => {
  //         console.log('patient membership profile from effects', profile);
  //         if (profile) {
  //           return ProfiloPazienteActions.fetchPatientMembershipProfileSuccess({
  //             membershipProfile: profile,
  //           });
  //         } else {
  //           return ProfiloPazienteActions.fetchPatientMembershipProfileFailure({
  //             error: 'error: ',
  //           });
  //         }
  //       }),
  //       catchError((error) => {
  //         console.error(
  //           'Error while fetching patient membership profile',
  //           error
  //         );
  //         return of(
  //           ProfiloPazienteActions.fetchPatientMembershipProfileFailure({
  //             error: 'errore',
  //           })
  //         );
  //       })
  //     )
  //   )
  // );
}
