import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../../auth/store/auth.actions';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() public sidenavToggle = new EventEmitter();
  public env = environment;

  private authSubscription!: Subscription;
  auth!: any | null;
  private profiloSubscription: Subscription;
  public isHm: boolean | null = false;
  public isHm_doc: boolean | null = false;
  public isSeller: boolean | null = false;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  ngOnInit() {
    this.authSubscription = this.store
      .select('auth')
      .pipe(map((authState) => authState))
      .subscribe((authState) => {
        this.auth = authState.userData;
      });
    this.profiloSubscription = this.store
      .select('me')
      .pipe(map((profiloState) => profiloState))
      .subscribe((profiloState) => {
        this.isHm = profiloState.isHm;
        this.isHm_doc = profiloState.isHm_doc;
        this.isSeller = profiloState.isSeller;
      });
  }

  ngOnDestroy() {
    this.authSubscription && this.authSubscription.unsubscribe();
    this.profiloSubscription && this.profiloSubscription.unsubscribe();
  }

  onLogout() {
    this.router.navigate(['/loading']);
    this.store.dispatch(AuthActions.logout());
  }
}
