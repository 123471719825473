import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { exhaustMap, map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as fromApp from '../store/app.reducer';
import { UserData } from '../model/UserData';
import { AuthService } from './store/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<fromApp.AppState>,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userData: UserData | null = this.authService.getToken();
    if (userData) {
      const cloned = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + userData.accessToken
        ),
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
