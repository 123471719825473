import { Action, createReducer, on } from '@ngrx/store';
import { MeData } from 'src/app/model/Me';
import * as MeActions from './me.actions';
import * as AuthActions from '../../../auth/store/auth.actions';
import { UserInfo } from 'src/app/model/UserInfo';

export interface State {
  me: MeData | null;
  error: boolean;
  errorMessage: any;
  loading: boolean;
  isHm: boolean | null;
  isHm_doc: boolean | null;
  isSeller: boolean | null;
  userInfo: UserInfo | null;
}

const initialState: State = {
  me: null,
  error: false,
  errorMessage: null,
  loading: false,
  isHm: null,
  isHm_doc: null,
  isSeller: null,
  userInfo: null,
};

const _meReducer = createReducer(
  initialState,

  on(MeActions.fetchMe, (state) => ({
    ...state,
    loading: true,
  })),

  on(MeActions.setMe, (state, action) => ({
    ...state,
    me: action.me,
    loading: false,
    // isHm: action.me.specializations.some((spec) => spec.name == 'HM')
    //   ? true
    //   : false,
    // isHm_doc: action.me.specializations.some((spec) => spec.name == 'HM_DOC')
    //   ? true
    //   : false,
  })),

  on(MeActions.updateMe, (state, action) => ({
    ...state,
    loading: true,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    me: null,
    loading: false,
  })),

  on(MeActions.fetchUserInfo, (state) => ({
    ...state,
    loading: true,
  })),

  on(MeActions.fetchUserInfoSuccess, (state, action) => ({
    ...state,
    userInfo: action.userInfo,
    loading: false,
    isHm: action.userInfo.profiles.includes('HM') ? true : false,
    isHm_doc: action.userInfo.profiles.includes('HM_DOC') ? true : false,
    isSeller: action.userInfo.profiles.includes('SELLER') ? true : false,
  })),

  on(MeActions.fetchUserInfoFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    loading: false,
  }))
);

export function meReducer(state: State | undefined, action: Action) {
  return _meReducer(state, action);
}
