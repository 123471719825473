import { createAction, props } from '@ngrx/store';
import { Patient } from 'src/app/model/Patient';

export const fetchPatients = createAction(
  '[Patients] Fetch Patients',
  props<{
    firstName?: string;
    lastName?: string;
    referenceCode?: string;
    primary?: boolean; // true se il medico fa parte dell'health team del paziente
    active?: boolean; // true se il paziente ha una member attiva
    sort?: string;
    page?: any;
  }>()
);

export const fetchPatientsSuccess = createAction(
  '[Patients] Fetch Patients Success',
  props<{ patients: Patient }>()
);

export const fetchPatientsFailure = createAction(
  '[Patients] Fetch Patients Failure',
  props<{ error: any }>()
);

export const fetchHmRequestDetail = createAction(
  '[Patients] Fetch Hm Request Detail',
  props<{ richiestaId: string }>()
);

export const fetchHmRequestDetailSuccess = createAction(
  '[Patients] Fetch Hm Request Detail Success',
  props<{ hmRequestDetail: any }>()
);

export const fetchHmRequestDetailFailure = createAction(
  '[Patients] Fetch Hm Request Detail Failure',
  props<{ error: any }>()
);

// export const fetchHmRequestDocuments = createAction(
//   '[Patients] Fetch Hm Request Documents',
//   props<{ patientId: string; richiestaId: string; typeDoc: string }>()
// );
