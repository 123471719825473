<div class="d-flex justify-content-end align-items-center topper-profile-user">
  <div *ngIf="me" class="avatar-border">
    <ngx-avatars
      [matMenuTriggerFor]="menu"
      alt="avatar"
      [textSizeRatio]="3"
      bgColor="#FF3D77 "
      fgColor="white"
      class="circle my-avatar"
      [name]="this.fullName"
      size="77"
      [round]="true"
      [src]="avatarUrl"
    ></ngx-avatars>
  </div>

  <mat-menu #menu="matMenu" class="custom-men">
    <button mat-menu-item routerLink="/profilo">
      <div class="blue-left-border"></div>
      <mat-icon>person_outline</mat-icon>
      Profilo
    </button>
    <div mat-menu-item (click)="onLogout()">
      <div class="blue-left-border"></div>
      <mat-icon>login</mat-icon>
      Logout
    </div>
  </mat-menu>
</div>
