import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss'],
})
export class ChangeAvatarComponent implements OnInit {
  @Input() richiestaId: string = '';
  public fileToUpload: File | null = null;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files = [];
  private postUrl: string = environment.NEW_API + '/profile/image';
  @Output() avatarChanged = new EventEmitter<boolean>();
  @Output() avatarChangeError = new EventEmitter<boolean>();
  private payload: any = {
    headers: {
      contentType: 'multipart/form-data',
    },
  };

  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {}

  uploadImageProfile(file: any) {
    const formData = new FormData();
    formData.append('image', file.data, file.data.name);
    return this.httpClient
      .post(this.postUrl, formData, this.payload)
      .subscribe((data) => {
        console.log('success', data);
        this.avatarChanged.emit(true);
      },
      (error) => {
        console.error('error', error);
        this.avatarChangeError.emit(true);
      });
  }

  onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const fileSingle = fileUpload.files[0];
      const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
      this.uploadImageProfile(fileParams);
    };
    fileUpload.click();
  }
}
