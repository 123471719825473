<stream-message
  *ngIf="message && !message.contentType"
  [message]="message"
></stream-message>

<ng-container *ngIf="message && message.contentType">
  <div class="d-flex align-items-center">
    <!-- Balloon with name initial -->
    <div class="name-initial">
      <span class="">
        {{ nameInitial }}
      </span>
    </div>

    <div class="file-balloon" (click)="downloadDocument(message)">
      <span class="file-icon">
        {{
          message.contentType == "application/pdf"
            ? "📎"
            : message.contentType == "image/jpeg"
            ? "📷"
            : ""
        }}
      </span>
      <span class="file-name">{{ message.filename }}</span>
    </div>
  </div>
  <div
    class="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata ng-star-inserted timestamp"
  >
    <span
      data-testid="sender"
      class="str-chat__message-simple-name str-chat__message-sender-name ng-star-inserted"
    >
      {{ message.user?.name }}
    </span>
    <span data-testid="date" class="str-chat__message-simple-timestamp">
      {{ message.created_at | date : "medium" }}
    </span>
  </div>
</ng-container>
