<div class="container mt60 assistenza">
  <div class="row">
    <div class="col-sm-4">
      <div class="position-fixed">
        <mat-list role="list">
          <mat-list-item
            role="listitem"
            (click)="
              viewMode = 'funzioni_generali';
              headerTitle = 'Funzioni Generali';
              headerDescription =
                'Vuoi avere qualche informazione in più su come gestire il tuo account? Cerca la risposta tra le domande frequenti.'
            "
            [ngClass]="{ activeLink: viewMode == 'funzioni_generali' }"
            >Funzioni Generali</mat-list-item
          >
          <mat-list-item
            role="listitem"
            (click)="
              viewMode = 'appuntamenti';
              headerTitle = 'Appuntamenti';
              headerDescription =
                'Hai qualche dubbio su come gestire la tua agenda? Cerca la risposta tra le domande frequenti.'
            "
            [ngClass]="{ activeLink: viewMode == 'appuntamenti' }"
            >Appuntamenti</mat-list-item
          >
          <mat-list-item
            role="listitem"
            (click)="
              viewMode = 'chat';
              headerTitle = 'Chat';
              headerDescription =
                'Hai qualche incertezza sul nostro sistema di messaggistica? Cerca la risposta tra le domande frequenti.'
            "
            [ngClass]="{ activeLink: viewMode == 'chat' }"
            >Chat</mat-list-item
          >
          <mat-list-item
            role="listitem"
            (click)="
              viewMode = 'myhealth_score';
              headerTitle = 'MyHealth Score';
              headerDescription =
                'Hai qualche problema con il MyHealth score? Cerca la risposta tra le domande più frequenti.'
            "
            [ngClass]="{ activeLink: viewMode == 'myhealth_score' }"
            >MyHealth Score</mat-list-item
          >
          <mat-list-item
            role="listitem"
            (click)="
              viewMode = 'videoconsulto';
              headerTitle = 'Videoconsulto';
              headerDescription =
                'Il Videoconsulto genera un po’ di confusione? Cerca la risposta tra le domande più frequenti.'
            "
            [ngClass]="{ activeLink: viewMode == 'videoconsulto' }"
            >Videoconsulto</mat-list-item
          >
          <mat-list-item
            role="listitem"
            (click)="
              viewMode = 'documenti';
              headerTitle = 'Documenti';
              headerDescription =
                'Hai qualche esitazione su come gestire la documentazione? Cerca la risposta tra le domande più frequenti.'
            "
            [ngClass]="{ activeLink: viewMode == 'documenti' }"
            >Documenti</mat-list-item
          >
        </mat-list>
      </div>
    </div>
    <div class="col-sm-8">
      <div class="col-12">
        <h2 class="mat-headline fw-bold">Centro Assistenza</h2>
        <p>
          La navigazione in piattaforma ti crea qualche incertezza? Niente
          paura! Consulta la lista delle domande più frequenti oppure contatta i
          nostri operatori all'email
          <a href="mailto:{{ this.supportoITemail }}" class="activeLink">{{
            this.supportoITemail
          }}</a
          >.
        </p>
      </div>
      <div class="colored p-3 rounded my-3" id="header-faq">
        <h3 class="fw-bold mb-1">{{ headerTitle }}</h3>
        <p>{{ headerDescription }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-8">
      <div [ngSwitch]="viewMode">
        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'funzioni_generali'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.funzioni_generali"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p [innerHTML]="funzioni.description">
                {{ funzioni.description }}
              </p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>

        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'appuntamenti'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.appuntamenti"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p [innerHTML]="funzioni.description">
                {{ funzioni.description }}
              </p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>

        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'chat'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.chat"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ funzioni.description }}</p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>

        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'myhealth_score'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.myhealth_score"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ funzioni.description }}</p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>

        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'videoconsulto'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.videoconsulto"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p [innerHTML]="funzioni.description">
                {{ funzioni.description }}
              </p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>

        <mat-accordion *ngFor="let accordion of accordions">
          <div *ngSwitchCase="'documenti'">
            <mat-expansion-panel
              class="my-3"
              *ngFor="let funzioni of accordion.documenti"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ funzioni.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ funzioni.description }}</p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </div>
      <div>
        <h2>
          Non hai trovato quello che cercavi? <br />
          Scarica il manuale oppure consulta le linee guida Dr. Feel
        </h2>
        <a href="../../../../assets/docs/ManualeMedico.pdf" target="_blank"
          ><button
            type="button"
            class="do3-primary-btn blue rounded-pill w-auto text-uppercase do3-fw800"
          >
            scarica guida
          </button></a
        >
      </div>
    </div>
  </div>
</div>
