import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import saveAs from 'file-saver';
import { environment } from 'src/environments/environment';
import { StreamMessage } from 'stream-chat-angular';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() message: StreamMessage | undefined;
  constructor(private http: HttpClient) {}
  public nameInitial: string = '';

  ngOnInit(): void {
    this.nameInitial = this.message?.user?.name
      ? this.message.user.name.charAt(0).toUpperCase()
      : '';
  }

  downloadDocument(message: any) {
    const downloadUrl: string = message.documentId
      ? `${environment.NEW_API}/documents/${message.documentId}/content`
      : message.text.replace('bff-patient', 'doctor');

    return this.http
      .get(downloadUrl, {
        responseType: 'blob',
        headers: { Accept: message.contentType },
      })
      .subscribe((blob) => {
        saveAs(blob, message.filename);
      });
  }
}
