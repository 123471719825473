import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-single-anamnesi',
  templateUrl: './single-anamnesi.component.html',
  styleUrls: ['./single-anamnesi.component.scss'],
})
export class SingleAnamnesiComponent implements OnInit {
  @Input() richiesta: any | null;
  @Input() isTriage: boolean = true;
  @Input() isFull: boolean = true;
  @Input() pazienteId: string;
  @Input() healthScoreId: string | null;
  @Input() isOnBoarding: boolean;
  public anamnesi: any;
  public isLoading: boolean = true;
  public openPanel: boolean = false;

  constructor(private http: HttpClient) {}
  ngOnInit(): void {
    console.log('entro qui');
  }

  // VALUTARE SE TRIAGE VERRA' ATTIVATO IN FUTURO
  // getAnamnesi(id: string) {
  //   this.openPanel = !this.openPanel;
  //   return this.http
  //     .get(`${environment.NEW_API}/requests/${id}/triage`)
  //     .subscribe((data: any) => {
  //       console.log(data.data);
  //       this.isLoading = false;
  //       return (this.anamnesi = data.data);
  //     });
  // }

  getAnamnesi(id: string) {
    this.openPanel = !this.openPanel;
    if (this.isOnBoarding) {
      this.isLoading = false;
      console.log('onboarding explode', this.richiesta);
      return (this.anamnesi = this.richiesta);
    } else {
      return this.http
        .get(
          `${environment.NEW_API}/patients/${this.pazienteId}/health-scores/${this.healthScoreId}/surveys/${id}`
        )
        .subscribe((data: any) => {
          console.log('singola survey', data);
          this.isLoading = false;
          return (this.anamnesi = data);
        });
    }
  }

  handleType(id: string | undefined) {
    switch (id) {
      case 'KPI_Q1':
        return 'health';
      case 'KPI_Q2':
        return 'nutrition';
      case 'KPI_Q3':
        return 'mind';
      case 'KPI_Q4':
        return 'movement';
      default:
        return '';
    }
  }
}
