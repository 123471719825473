import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as RichiesteActions from 'src/app/features/richieste/store/richieste.actions';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { fetchDocumentsPaziente } from 'src/app/features/profilo-paziente/store/profilo-paziente.actions';

@Component({
  selector: 'app-referti',
  templateUrl: './referti.component.html',
  styleUrls: ['./referti.component.scss'],
})
export class RefertiComponent implements OnInit {
  private storeSubscription: Subscription;
  private profileSubscription: Subscription;
  public documentsFromStore: ProfiloDocumentDatum[];
  public loading: boolean = false;
  @Input() richiestaId: string = '';
  @Input() patientId: string = '';
  @Input() isHm: boolean = false;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    if (
      !this.richiestaId ||
      (this.richiestaId === '' && this.patientId !== '')
    ) {
      this.store.dispatch(
        fetchDocumentsPaziente({ pazienteId: this.patientId, isHm: this.isHm })
      );
      this.profileSubscription = this.store
        .select('profiloPaziente')
        .subscribe((profilo) => {
          this.documentsFromStore = profilo.documents!.filter(
            (doc) => doc.documentType === 'DIAGNOSIS'
          );
          this.loading = profilo.loadingDocs;
          console.log('profilo documents from store', this.documentsFromStore);
        });
    } else {
      this.store.dispatch(
        RichiesteActions.fetchRichiestaDocuments({
          richiestaId: this.richiestaId,
        })
      );
      this.storeSubscription = this.store
        .select('richieste')
        .subscribe((richieste) => {
          this.documentsFromStore = richieste.selectedRichiestaDocuments.filter(
            (doc) => doc.documentType === 'DIAGNOSIS'
          );
          this.loading = richieste.documentsLoading;
          console.log(
            'richiesta documents from store',
            this.documentsFromStore
          );
        });
    }
  }

  downloadDocument(doc: ProfiloDocumentDatum) {
    console.log('download document', doc);
    // let url = '';
    // if (this.richiestaId) {
    //   url = `${environment.NEW_API}/requests/${this.richiestaId}/documents/${doc.id}/content`;
    // } else if (this.patientId && !this.isHm) {
    //   url = `${environment.NEW_API}/patients/${this.patientId}/documents/${doc.id}/content`;
    // } else if (this.patientId && this.isHm) {
    //   url = `${environment.NEW_API}/hm/patients/${this.patientId}/documents/${doc.id}/content`;
    // }

    let url = `${environment.NEW_API}/patients/${this.patientId}/documents/${doc.id}/content`;

    return this.http
      .get(url, { responseType: 'blob', headers: { Accept: doc.contentType } })
      .subscribe((blob) => {
        saveAs(blob, doc.name);
      });
  }

  parseDocType(docType: string) {
    switch (docType) {
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpg';
      case 'document/doc':
        return 'doc';
      case 'application/pdf':
        return 'pdf';
      case 'other':
        return 'etc';
      default:
        return 'etc';
    }
  }

  parseBytes(bytes: any) {
    const floatBytes = parseFloat(bytes);
    if (floatBytes > 1024) {
      return `${Math.round(floatBytes / 1024)} KB`;
    } else {
      return `${bytes} Bytes`;
    }
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('document', file.data, file.data.name);
    formData.append('requestId', this.richiestaId);
    formData.append('documentType', 'DIAGNOSIS');
    console.log('formData', formData);
    return this.http
      .post(
        environment.NEW_API + '/requests/' + this.richiestaId + '/documents',
        formData,
        {
          headers: {
            contentType: 'multipart/form-data',
          },
        }
      )
      .subscribe((data) => {
        console.log('success', data);
        this.store.dispatch(
          RichiesteActions.fetchRichiestaDocuments({
            richiestaId: this.richiestaId,
          })
        );
      });
  }
  onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const fileSingle = fileUpload.files[0];
      const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
      this.uploadFile(fileParams);
    };
    fileUpload.click();
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    this.profileSubscription && this.profileSubscription.unsubscribe();
  }
}
