import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-prenota',
  templateUrl: './button-prenota.component.html',
  styleUrls: ['./button-prenota.component.scss'],
})
export class ButtonPrenotaComponent {
  @Input() buttonText: string = 'Prenota una televisita';

  constructor() {}
}
