<section class="documenti">
  <div class="row do3-fw800 do3-blue-text header">
    <div class="col-1"></div>
    <div class="col-10">
      <div class="row">
        <div class="col-3">Nome </div>
        <div class="col-3">Dimensione</div>
        <div class="col-3">Proprietario</div>
        <div class="col-3">Data caricamento</div>
      </div>
    </div>
    <div class="col-1"></div>
  </div>

  <div *ngIf="!loading && documentsFromStore">
    <app-document-box *ngFor="let doc of documentsFromStore" [doc]="doc"></app-document-box>
  </div>

  <app-loading-spinner *ngIf="loading"></app-loading-spinner>

  <app-upload-doc [isRichiesta]="false">+</app-upload-doc>

</section>
