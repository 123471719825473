import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import * as ProfiloPazienteActions from '../../features/profilo-paziente/store/profilo-paziente.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storico',
  templateUrl: './storico.component.html',
  styleUrls: ['./storico.component.scss'],
})
export class StoricoComponent implements OnInit, OnDestroy {
  @Input() patientId: string;
  @Input() isHm: boolean = false;
  public avatarUrl: any;

  private storeSub: Subscription;
  public allRichieste: any[] | null;
  public richiesteMeta: any | null;
  public paginatedRichieste: any[] | undefined;
  public isLoading: boolean = true;
  public requestId: string;
  public itemsPerPage: number = 5;
  public showReasons = false;
  public page: number = 0;
  public expireRequestMessage: string | null;
  // public currentPage: number = this.page + 1;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // console.log(this.patientId, 'from storico')
    this.store.dispatch(
      ProfiloPazienteActions.fetchRichiestePaziente({
        idPatient: this.patientId,
        withTriage: false,
        isHm: this.isHm,
        pageItems: this.itemsPerPage,
        page: this.page,
      })
    );
    this.storeSub = this.store.select('profiloPaziente').subscribe((state) => {
      this.allRichieste = state.richieste;
      this.richiesteMeta = state.meta;
      if (this.allRichieste) {
        // this.paginatedRichieste = [...this.allRichieste];
        // .sort(
        //   (a, b) =>
        //     new Date(b.slot.from).getTime() - new Date(a.slot.from).getTime()
        // )
        // .slice(
        //   (this.currentPage - 1) * this.itemsPerPage,
        //   this.currentPage * this.itemsPerPage
        // );
        this.isLoading = !state.richieste;
      }
    });
    this.getAvatar();
  }

  setRequestId(requestId: string) {
    this.requestId = requestId;
  }

  expireDate(dateString: string, requestType: string): boolean {
    let oggi = new Date().getTime();
    let dataVisita = new Date(dateString).getTime();
    let expireLimit;
    if (requestType === 'HM' || requestType === 'HM_DOC') {
      expireLimit = 600000; // limite 10 minuti = 600000 ms
      this.expireRequestMessage =
        "L'appuntamento può essere cancellato fino a 10 minuti dall'inizio";
    } else {
      expireLimit = 21600000; // limite 6 ore = 21600000 ms
      this.expireRequestMessage =
        "L'appuntamento può essere cancellato fino a 6 ore dall'inizio";
    }
    let differenza = dataVisita - oggi - expireLimit;
    if (differenza < 0) {
      return true;
    } else {
      return false;
    }
  }

  patientHasBundle(richiesta: any) {
    if (richiesta.orderInfo) {
      return true;
    } else {
      return false;
    }
  }

  deleteRequest() {
    this.isLoading = true;
    this.http
      .delete<any>(`${environment.NEW_API}/requests/${this.requestId}`)
      .subscribe(
        () => {
          this.isLoading = false;
          if (
            this.allRichieste!.length === 1 &&
            this.richiesteMeta.last &&
            !this.richiesteMeta.first
          ) {
            this.page--;
          }
          this.store.dispatch(
            ProfiloPazienteActions.fetchRichiestePaziente({
              idPatient: this.patientId,
              withTriage: false,
              isHm: this.isHm,
              pageItems: this.itemsPerPage,
              page: this.page,
            })
          );
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
        }
      );
  }

  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(`${environment.NEW_API}/profile/image`, { responseType: 'blob' })
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  handlePageClick(pageNumber: number) {
    this.isLoading = true;
    this.page = pageNumber;
    this.store.dispatch(
      ProfiloPazienteActions.fetchRichiestePaziente({
        idPatient: this.patientId,
        withTriage: false,
        isHm: this.isHm,
        pageItems: this.itemsPerPage,
        page: this.page,
      })
    );
    this.storeSub = this.store.select('profiloPaziente').subscribe((state) => {
      this.allRichieste = state.richieste;
      this.richiesteMeta = state.meta;
      console.log('richiestemeta: ', this.richiesteMeta);
      if (this.allRichieste) {
        // this.paginatedRichieste = [...this.allRichieste];
        // .sort(
        //   (a, b) =>
        //     new Date(b.slot.from).getTime() - new Date(a.slot.from).getTime()
        // )
        // .slice(
        //   (this.currentPage - 1) * this.itemsPerPage,
        //   this.currentPage * this.itemsPerPage
        // );
        this.isLoading = !state.richieste;
      }
    });
  }

  getTotalPages(): number {
    if (this.richiesteMeta) {
      const totalElements = this.richiesteMeta.totalElements;
      return Math.ceil(totalElements / this.itemsPerPage);
    }
    return 0;
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

  toggleReason(richiesta: any): void {
    if (richiesta.reason) {
      document
        .querySelector('.reason[id="' + richiesta.id + '"]')
        ?.classList.toggle('d-none');
    }
  }
}
