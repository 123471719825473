import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import //   movergyDisplay,
//   myWellnessEnable,
'src/app/core/header/store/me.actions';
import * as fromApp from 'src/app/store/app.reducer';

@Component({
  selector: 'app-movergy-page',
  templateUrl: './movergy-page.component.html',
  styleUrls: ['./movergy-page.component.scss'],
})
export class MovergyPageComponent implements OnInit, OnDestroy {
  private storeSubscription: Subscription;
  public movergyDisplay: any;
  public movergyDisplayLoading: boolean = false;
  public movergyValue: number = 1964;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    // this.store.dispatch(movergyDisplay());
    this.storeSubscription = this.store.select('me').subscribe((state) => {
      //   this.movergyDisplay = state.movergyDisplay;
      //   this.movergyDisplayLoading = state.movergyLoading;
      //   this.movergyValue = state.movergyDisplay?.movergy.movergy;
      console.log('movergyDisplay', this.movergyDisplay);
      console.log('movergyDisplayLoading', this.movergyDisplayLoading);
    });
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  public onEnableMovergy(): void {
    // this.store.dispatch(myWellnessEnable());
  }
}
