<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<ng-container *ngIf="!loading">
  <ng-container *ngIf="!selectedHealthScoreId">
    <ng-container *ngIf="!isHm && statusScore === 'PROCESSING'">
      <div class="alert alert-warning" role="alert">
        HM sta elaborando health score...
      </div>
    </ng-container>
    <ng-container *ngIf="isHm || (!isHm && statusScore === 'COMPLETED')">
      <form
        class="input-container container"
        (ngSubmit)="onSubmit(idScore)"
        #kpiForm="ngForm"
      >
        <div class="my-2">
          <label for="myHealthScore">MyHealth Score complessivo</label>
          <input
            type="text"
            id="myHealthScore"
            name="myHealthScore"
            placeholder="Inserisci dato"
            [(ngModel)]="myHealthScore"
            required
            [disabled]="!isHm || (isHm && statusScore !== 'PROCESSING')"
          />
        </div>

        <div class="d-flex">
          <div class="me-3">
            <label for="health">Health</label>
            <input
              type="text"
              id="health"
              name="health"
              placeholder="Inserisci dato"
              [(ngModel)]="health"
              required
              [disabled]="!isHm || (isHm && statusScore !== 'PROCESSING')"
            />
          </div>
          <div class="me-3">
            <label for="mind">Mind</label>
            <input
              type="text"
              id="mind"
              name="mind"
              placeholder="Inserisci dato"
              [(ngModel)]="mind"
              required
              [disabled]="!isHm || (isHm && statusScore !== 'PROCESSING')"
            />
          </div>
          <div class="me-3">
            <label for="movement">Movement</label>
            <input
              type="text"
              id="movement"
              name="movement"
              placeholder="Inserisci dato"
              [(ngModel)]="movement"
              required
              [disabled]="!isHm || (isHm && statusScore !== 'PROCESSING')"
            />
          </div>
          <div class="me-3">
            <label for="nutrition">Nutrition</label>
            <input
              type="text"
              id="nutrition"
              name="nutrition"
              placeholder="Inserisci dato"
              [(ngModel)]="nutrition"
              required
              [disabled]="!isHm || (isHm && statusScore !== 'PROCESSING')"
            />
          </div>
        </div>
        <div class="d-flex flex-row gap-3">
          <button
            *ngIf="isHm && statusScore === 'PROCESSING'"
            type="submit"
            [disabled]="!kpiForm.valid"
          >
            Salva
          </button>
          <button
            *ngIf="isHm && statusScore === 'PROCESSING' && processing"
            type="button"
            [disabled]="!kpiForm.valid"
            (click)="onCompleted(idScore)"
          >
            Conferma
          </button>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="statusScore === 'NEW'">
      <div class="alert alert-warning" role="alert">
        Attesa compilazione questionari da utente.
      </div>
    </ng-container>
    <ng-container *ngIf="statusScore === 'COMPLETED'">
      <div class="alert alert-success" role="alert">
        Health score di questo mese è concluso, attendi il mese successivo.
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="selectedHealthScoreId">
    <form
      class="input-container container"
      (ngSubmit)="onSubmit(idScore)"
      #kpiForm="ngForm"
    >
      <div class="my-2">
        <label for="myHealthScore"
          >MyHealth Score complessivo<span *ngIf="selectedPeriod">
            di {{ selectedPeriod }}</span
          ></label
        >
        <input
          type="text"
          id="myHealthScore"
          name="myHealthScore"
          placeholder="Inserisci dato"
          [(ngModel)]="myHealthScore"
          required
          disabled
        />
      </div>

      <div class="d-flex">
        <div class="me-3">
          <label for="health">Health</label>
          <input
            type="text"
            id="health"
            name="health"
            placeholder="Inserisci dato"
            [(ngModel)]="health"
            required
            disabled
          />
        </div>
        <div class="me-3">
          <label for="mind">Mind</label>
          <input
            type="text"
            id="mind"
            name="mind"
            placeholder="Inserisci dato"
            [(ngModel)]="mind"
            required
            disabled
          />
        </div>
        <div class="me-3">
          <label for="movement">Movement</label>
          <input
            type="text"
            id="movement"
            name="movement"
            placeholder="Inserisci dato"
            [(ngModel)]="movement"
            required
            disabled
          />
        </div>
        <div class="me-3">
          <label for="nutrition">Nutrition</label>
          <input
            type="text"
            id="nutrition"
            name="nutrition"
            placeholder="Inserisci dato"
            [(ngModel)]="nutrition"
            required
            disabled
          />
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>
