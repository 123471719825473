<div class="anamnesi">
  <!-- <div class="question" *ngFor="let singleAnamnesi of anamnesi" [ngClass]="status ? 'disabledAnamnesi' : ''">
    <h3>{{singleAnamnesi.title}}</h3>
    <p class="mb-4">{{singleAnamnesi.question}}</p>

    <div class="answer mb-5">
      <button *ngFor="let answer of singleAnamnesi.answer" type="button" class="btn">{{answer.name}}</button>
    </div>

    <hr>
  </div> -->
  <app-anamnesi-list [specializzazioni]="triages"></app-anamnesi-list>
</div>
