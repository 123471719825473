import * as fromAuth from '../auth/store/auth.reducer';
import * as fromMe from '../core/header/store/me.reducer';
import * as fromDocsSumms from '../features/prenotazione/store/reducers/prenotazione.reducer';
import * as fromRichieste from '../features/richieste/store/richieste.reducer';
import * as fromProfile from '../features/profilo/store/profilo.reducer';
import * as fromMedicProfile from '../features/medic-profile/store/medic-profile.reducer';
import * as fromConsent from '../features/consenso/store/consenso.reducer';
import * as fromProfiloPaziente from '../features/profilo-paziente/store/profilo-paziente.reducer';
import * as fromHm from '../features/hm/store/hm.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  auth: fromAuth.AuthState;
  me: fromMe.State;
  //   doctors: fromDoctors.State;
  //   sintomi: fromSintomi.State;
  //   documenti: fromDocumenti.State;
  //   visite: fromVisite.State;
  //   messaggi: fromMessaggi.State;

  //   NEW API
  prenotazione: fromDocsSumms.State;
  richieste: fromRichieste.State;
  profile: fromProfile.State;
  medicProfile: fromMedicProfile.State;
  consent: fromConsent.State;
  profiloPaziente: fromProfiloPaziente.State;
  hm: fromHm.State;
}

export const appReducers: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  me: fromMe.meReducer,
  prenotazione: fromDocsSumms.docsSummsReducer,
  richieste: fromRichieste.richiesteReducer,
  profile: fromProfile.profileReducer,
  medicProfile: fromMedicProfile.medicProfileReducer,
  consent: fromConsent.consensoReducer,
  profiloPaziente: fromProfiloPaziente.profiloPazienteReducer,
  hm: fromHm.hmReducer,
};
