<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<!-- Filtro storico Health Scores -->
<ng-container>
  <h2 *ngIf="years.length === 0" class="no-health-scores">
    Il paziente non ha ancora Health Scores elaborati.
  </h2>
  <form *ngIf="years.length > 0">
    <h2>Cronologia Health Scores</h2>
    <div class="form-fields">
      <!-- <select
        id="year"
        name="year"
        [(ngModel)]="selectedYear"
        (ngModelChange)="getMonths()"
      >
        <option hidden disabled selected [ngValue]="null">
          Seleziona l'anno
        </option>
        <option *ngFor="let year of years" [value]="year">
          {{ year }}
        </option>
      </select>

      <select
        *ngIf="selectedYear"
        [disabled]="!this.selectedYear"
        id="month"
        name="month"
        [(ngModel)]="selectedMonth"
      >
        <option hidden disabled selected [ngValue]="null">
          Seleziona il mese
        </option>
        <option *ngFor="let month of months" [value]="month">
          {{ monthNames[month - 1] }}
        </option>
      </select> -->

      <!-- sostituito select con menu dropdown personalizzato per non avere limiti nella personalizzazione dello stile -->
      <div class="custom-dropdown">
        <div class="selected-option" (click)="toggleDropdown('year', $event)">
          <span *ngIf="!selectedYear">Seleziona l'anno</span>
          <span *ngIf="selectedYear">{{ selectedYear }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
          >
            <path
              d="M12.625 1.25L7 6.875L1.375 1.25"
              stroke="#5E718D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <ul *ngIf="isDropdownOpen.year">
          <li *ngFor="let year of years" (click)="selectYear(year, $event)">
            {{ year }}
          </li>
        </ul>
      </div>

      <div class="custom-dropdown" *ngIf="selectedYear">
        <div class="selected-option" (click)="toggleDropdown('month', $event)">
          <span *ngIf="!selectedMonth">Seleziona il mese</span>
          <span *ngIf="selectedMonth">{{ monthNames[selectedMonth - 1] }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
          >
            <path
              d="M12.625 1.25L7 6.875L1.375 1.25"
              stroke="#5E718D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <ul *ngIf="isDropdownOpen.month">
          <li *ngFor="let month of months" (click)="selectMonth(month, $event)">
            {{ monthNames[month - 1] }}
          </li>
        </ul>
      </div>

      <button
        *ngIf="selectedYear && selectedMonth"
        id="filter-btn"
        class="lightBlue"
        type="button"
        (click)="selectHealthScore()"
      >
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="magnifying-glass--glass-search-magnifying">
            <path
              id="Union"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.17789 6.53402C2.17789 5.51134 2.58415 4.53055 3.30729 3.80741C4.03043 3.08426 5.01123 2.67801 6.03391 2.67801C7.05658 2.67801 8.03738 3.08426 8.76052 3.80741C9.48366 4.53055 9.88992 5.51134 9.88992 6.53402C9.88992 7.5567 9.48366 8.53749 8.76052 9.26063C8.03738 9.98378 7.05658 10.39 6.03391 10.39C5.01123 10.39 4.03043 9.98378 3.30729 9.26063C2.58415 8.53749 2.17789 7.5567 2.17789 6.53402ZM6.03391 0.75C5.1236 0.749884 4.22612 0.964628 3.41446 1.37677C2.6028 1.78891 1.89987 2.3868 1.36285 3.12183C0.825831 3.85686 0.469882 4.70826 0.323952 5.60679C0.178022 6.50532 0.246232 7.42561 0.523035 8.29281C0.799838 9.16001 1.27742 9.94963 1.91693 10.5975C2.55645 11.2453 3.33984 11.733 4.20339 12.021C5.06694 12.309 5.98628 12.389 6.88662 12.2547C7.78696 12.1204 8.64288 11.7755 9.38478 11.248L12.1004 13.9636C12.2822 14.1392 12.5257 14.2364 12.7785 14.2342C13.0312 14.232 13.273 14.1306 13.4517 13.9519C13.6305 13.7731 13.7319 13.5313 13.7341 13.2786C13.7362 13.0258 13.6391 12.7823 13.4635 12.6005L10.7488 9.88586C11.3642 9.02053 11.7294 8.00253 11.8045 6.94341C11.8797 5.88429 11.6619 4.82491 11.1749 3.88137C10.688 2.93783 9.95068 2.14654 9.04386 1.5942C8.13705 1.04186 7.09569 0.749794 6.03391 0.75Z"
              fill="white"
            />
          </g>
        </svg>
      </button>
      <button
        *ngIf="selectedHealthScoreId"
        id="reset-btn"
        class="do3-fw600"
        type="button"
        (click)="resetFilter()"
      >
        Reset
      </button>
    </div>
    <span class="selected-period" *ngIf="selectedHealthScoreId">{{
      selectedPeriod
    }}</span>
  </form>
</ng-container>

<!-- Visualizzazione immagine KPI non più utilizzata -->
<!-- <ng-container *ngIf="kpiUrl">
  <div class="health-score">
    <img [src]="kpiUrl" alt="health score" />
  </div>
</ng-container> -->

<!-- Upload MyHealth non più utilizzato -->
<!-- 
<div class="plus-button" *ngIf="isHm" (click)="onClick()">
  <div class="btn do3-secondary-btn red">Upload MyHealth</div>
  <input
    type="file"
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    multiple="multiple"
    accept="image/*"
    style="display: none"
  />
</div> 
-->

<app-kpi-input
  [selectedPeriod]="selectedPeriod"
  [selectedHealthScoreId]="selectedHealthScoreId"
  [patientId]="patientId"
  [isHm]="isHm"
  (statusScoreChange)="handleStatusScoreChange($event)"
></app-kpi-input>

<app-surveys
  [selectedPeriod]="selectedPeriod"
  [selectedHealthScoreId]="selectedHealthScoreId"
  [pazienteId]="patientId"
  [isLoading]="loading"
  [receivedStatusScore]="statusScore"
></app-surveys>

<!--
  Rappresentazione tabellare degli ultimi 12 health scores.
  Sarebbe preferibile una rappresentazione con grafico a linee ma richiederebbe utilizzo di librerie esterne
-->
<ng-container *ngIf="allHealthScoresLength > 0">
  <div id="health-score-table" class="d-flex do3-fw800">
    <div class="label-column d-flex flex-column">
      <div class="table-cell score-row px-2 py-1">Score complessivo</div>
      <div class="table-cell health-row px-2 py-1">Health</div>
      <div class="table-cell mind-row px-2 py-1">Mind</div>
      <div class="table-cell movement-row px-2 py-1">Movement</div>
      <div class="table-cell nutrition-row px-2 py-1">Nutrition</div>
      <div class="table-cell period-row px-2 py-1">Periodo</div>
    </div>
    <!-- usato slice(-12) per mostrare solo gli ultimi 12 risultati nel caso di paziente con più di 12 health scores completati -->
    <div
      class="values-column d-flex flex-column"
      *ngFor="let score of allHealthScores.slice(-12)"
    >
      <div class="table-cell score-row px-2 py-1">
        {{ score.score }}
      </div>
      <div class="table-cell health-row px-2 py-1">
        {{ score.zoneScore.HEALTH }}
      </div>
      <div class="table-cell mind-row px-2 py-1">
        {{ score.zoneScore.MIND }}
      </div>
      <div class="table-cell movement-row px-2 py-1">
        {{ score.zoneScore.MOVEMENT }}
      </div>
      <div class="table-cell nutrition-row px-2 py-1">
        {{ score.zoneScore.NUTRITION }}
      </div>
      <div class="table-cell period-row px-2 py-1">
        {{ formatDate(score.validTo) }}
      </div>
    </div>
  </div>
</ng-container>
