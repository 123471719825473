<!-- Title banner -->
<div class="container">
  <!-- <div class="row banner">
    <div class="col-12">
      <span class="material-icons-outlined">calendar_month</span>
      <span class="text"> Lista pazienti </span>
    </div>
  </div> -->
  <div *ngIf="!patients || patients.items.length === 0">
    <div class="row justify-content-center mt-5">
      <h1 class="text-center no-requests">Nessun paziente da visualizzare.</h1>
    </div>
  </div>
  <div *ngIf="patients && patients.items.length > 0">
    <div class="my-3 d-flex justify-content-end">
      <h3 class="bg-grey py-1 px-3 rounded-pill">
        {{ patients.pagination.totalElements }}
        {{ patients.pagination.totalElements > 1 ? "pazienti" : "paziente" }}
      </h3>
    </div>
  </div>
  <div
    *ngIf="patients.items.length > 0"
    class="appuntamenti-table align-middle"
  >
    <div class="row th">
      <div class="col"></div>
      <div class="col">Nome</div>
      <div class="col">Cognome</div>
      <div class="col">Codice</div>
      <div class="col" *ngIf="isHm || isSeller">Stato Abbonamento</div>
      <div class="col"></div>
    </div>

    <div *ngIf="patients">
      <ng-container *ngFor="let patient of patients.items">
        <!-- <app-appuntamenti-item
          [richiesta]="richiesta"
          [mode]="mode"
          [listType]="listType"
          (handleClick)="handleDetailClick($event)"
        ></app-appuntamenti-item> -->
        <app-patients-item
          [isHm]="isHm"
          [patient]="patient.patient"
          [isSeller]="isSeller"
          (handleClick)="handleDetailClick($event)"
        ></app-patients-item>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="patients.pagination.totalElements > 0">
    <!-- Pagination -->
    <div class="row mb-5 justify-content-center">
      <div class="col-auto">
        <div class="pagination">
          <a
            class="page-link"
            (click)="handlePageClick(0)"
            [ngClass]="{
              disabled: patients.pagination.first
            }"
          >
            <span class="material-icons-outlined">first_page</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(page - 1)"
            [ngClass]="{
              disabled: patients.pagination.first
            }"
          >
            <span class="material-icons-outlined">chevron_left</span>
          </a>
          <span class="page-indicator"
            >{{ page + 1 }} / {{ getTotalPages() }}</span
          >
          <a
            class="page-link"
            (click)="handlePageClick(page + 1)"
            [ngClass]="{
              disabled: patients.pagination.last
            }"
          >
            <span class="material-icons-outlined">chevron_right</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(getTotalPages() - 1)"
            [ngClass]="{
              disabled: patients.pagination.last
            }"
          >
            <span class="material-icons-outlined">last_page</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
