import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { Policy, PolicyData } from 'src/app/model/Policy';
import { ProfiloDocument } from 'src/app/model/ProfiloDocument';
import { environment } from 'src/environments/environment';
import * as fromApp from '../../../store/app.reducer';
import * as ProfiloActions from './profilo.actions';
import { Auth } from '@angular/fire/auth';
import { of } from 'rxjs';
import { DoctorTriage } from 'src/app/model/DoctorTriage';

@Injectable()
export class ProfiloEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private auth: Auth
  ) {}

  fetchDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.fetchDocuments),
      switchMap(() => {
        console.log('fetching profilo');
        return this.http.get<ProfiloDocument>(
          environment.NEW_API + '/documents'
        );
      }),
      map((docs) => {
        console.log('all profilo documents from effects', JSON.stringify(docs));
        if (docs) {
          return ProfiloActions.fetchDocumentsSuccess({
            documents: docs.items,
          });
        } else {
          return ProfiloActions.fetchDocumentsFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  uploadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.uploadDocument),
      switchMap((action) => {
        console.log('uploading profilo');
        return this.http.post<any>(
          environment.NEW_API + '/documents',
          action.document
        );
      }),
      map((docs) => {
        console.log('upload documents from effects', JSON.stringify(docs));
        if (docs) {
          return ProfiloActions.fetchDocumentsSuccess({
            documents: docs.data,
          });
        } else {
          return ProfiloActions.fetchDocumentsFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  fetchPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.fetchPoliciesProfile),
      switchMap(() => {
        return this.http.get<PolicyData>(environment.NEW_API + '/policies');
      }),
      map((policies) => {
        if (policies) {
          return ProfiloActions.fetchPoliciesSuccess({
            policies: policies,
          });
        } else {
          return ProfiloActions.fetchPoliciesFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  fetchTriages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.fetchTriages),
      switchMap(() => {
        return this.http.get<DoctorTriage>(
          environment.NEW_API + '/profile/triage-info'
        );
      }),
      map((triages) => {
        if (triages) {
          return ProfiloActions.fetchTriagesSuccess({
            triages: triages,
          });
        } else {
          return ProfiloActions.fetchTriagesFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  postPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.postPolicyProfile),
      switchMap((action) => {
        console.log('posting policy');
        return this.http.post<any>(
          environment.NEW_API + '/policies/multiple',
          action.policy
        );
      }),
      map((policies) => {
        console.log(
          'post policies from profile effects',
          JSON.stringify(policies)
        );
        if (policies) {
          return ProfiloActions.fetchPoliciesSuccess({
            policies: policies,
          });
        } else {
          return ProfiloActions.fetchPoliciesFail({
            error: 'error: unable to fetch policies',
          });
        }
      })
    )
  );

  changeProfilePic$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfiloActions.changeProfilePic),
        switchMap((action) => {
          console.log('uploading profile pic');
          const user = this.auth.currentUser;
          const file = action.profilePic;
          console.log('profile pic file', file);
          console.log('profile pic user', user);

          if (user) {
            return this.auth.updateCurrentUser({
              ...user,
              photoURL: file,
            });
          } else {
            return of(null);
          }
        })
      ),
    { dispatch: false }
  );

  async uploadProfilePic(file: any) {
    const user = this.auth.currentUser;
    if (user) {
      return await this.auth.updateCurrentUser({
        ...user,
        photoURL: file,
      });
    } else {
      return of(null);
    }
  }
}
