import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit, OnDestroy {
  public fileToUpload: File | null = null;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files = [];
  private payload: any = {
    headers: {
      contentType: 'multipart/form-data',
    },
  };
  @Input() patientId: string = '';
  @Input() isHm: boolean | null = false;
  public kpiUrl: any;
  public loading: boolean;
  public env = environment;
  public statusScore: string;
  public error: boolean = false;
  public allHealthScores: any;
  public allHealthScoresLength: number;
  public selectedHealthScoreId: string | null;
  public years: number[] = [];
  public months: number[] = [];
  public monthNames: string[] = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];
  public selectedYear: number | null = null;
  public selectedMonth: number | null = null;
  public selectedMonthName: string | null;
  public selectedPeriod: string | null = null;
  isDropdownOpen: { [key: string]: boolean } = {
    year: false,
    month: false,
  };

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getAllHealthScores();

    // chiudo il menu dropdown se clicco al di fuori dello stesso
    document.addEventListener('click', this.closeDropdownOutside.bind(this));
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.closeDropdownOutside.bind(this));
  }

  // ottengo un array di oggetti con tutti gli health scores completati dal paziente nel tempo
  getAllHealthScores() {
    this.loading = true;
    this.http
      .get(`${environment.NEW_API}/patients/${this.patientId}/health-scores`)
      .subscribe(
        (response: any) => {
          if (response) {
            this.allHealthScores = response.items || '';
            this.allHealthScoresLength = this.allHealthScores.length;
            if (this.allHealthScores.length > 0) {
              this.getYears();
            }
          }

          console.log('health scores history', this.allHealthScores);
          this.loading = false;
        },
        (err) => {
          this.errorHandling(err);
        }
      );
  }

  private errorHandling(errMessage: any) {
    console.error(errMessage);
    this.error = true;
    this.loading = false;
  }

  // genero un array senza duplicati con gli anni in cui il paziente ha completato almeno un health score
  getYears() {
    this.allHealthScores.forEach((score: any) => {
      const date = new Date(score.validTo);
      const year = date.getFullYear();
      if (!this.years.includes(year)) {
        this.years.push(year);
      }
    });
    this.years.sort((a, b) => b - a);
  }

  // per ogni anno dell'array precedente, genero un array senza duplicati con i mesi di quell'anno in cui il paziente ha completato l'health score
  getMonths() {
    this.selectedMonth = null;
    this.months = [];
    if (this.selectedYear) {
      this.allHealthScores.forEach((score: any) => {
        const date = new Date(score.validTo);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        if (year == this.selectedYear) {
          if (!this.months.includes(month)) {
            this.months.push(month);
          }
        }
      });
      this.months.sort((a, b) => a - b);
    }
  }

  // menu dropdown personalizzato: chiudo o apro il dropdown su anno e mese
  toggleDropdown(type: string, event: Event) {
    event.stopPropagation();
    Object.keys(this.isDropdownOpen).forEach((key) => {
      this.isDropdownOpen[key] =
        key === type ? !this.isDropdownOpen[key] : false;
    });
  }

  // seleziono un anno dal primo menu dropdown e eseguo il metodo getMonths per generare i mesi presenti in quell'anno
  selectYear(year: number | null = null, event: Event) {
    this.selectedYear = year;
    this.toggleDropdown('year', event);
    this.getMonths();
  }

  // seleziono il mese dal secondo menu dropdown
  selectMonth(month: number | null = null, event: Event) {
    this.selectedMonth = month;
    this.toggleDropdown('month', event);
  }

  // con anno e mese selezionato, ottengo l'health score relativo al periodo selezionato e salvo in una variabile il relativo id per passarlo anche agli altri componenti (kpi-input e surveys)
  selectHealthScore() {
    if (this.selectedYear && this.selectedMonth) {
      const selectedHealthScore = this.allHealthScores.find(
        (score: any) =>
          new Date(score.validTo).getFullYear() == this.selectedYear &&
          new Date(score.validTo).getMonth() + 1 == this.selectedMonth
      );

      if (selectedHealthScore) {
        this.selectedHealthScoreId = selectedHealthScore.id;
        this.selectedPeriod =
          this.monthNames[this.selectedMonth - 1] + ' ' + this.selectedYear;
      } else {
        console.warn('Nessun oggetto trovato per la selezione di anno e mese');
      }
    } else {
      console.warn('Anno o mese non selezionato');
    }
  }

  // resetto il periodo selezionato
  resetFilter() {
    this.selectedMonth = null;
    this.selectedYear = null;
    this.months = [];
    this.selectedHealthScoreId = null;
  }

  // Chiudo il menu dropdown se clicco al di fuori di esso
  closeDropdownOutside(event: Event) {
    const dropdownContainer = document.querySelector('.custom-dropdown');

    if (
      dropdownContainer &&
      !dropdownContainer.contains(event.target as Node)
    ) {
      Object.keys(this.isDropdownOpen).forEach((key) => {
        this.isDropdownOpen[key] = false;
      });
    }
  }

  handleStatusScoreChange(statusScore: string) {
    console.log('Status Score changed:', statusScore);
    this.statusScore = statusScore;
  }

  formatDate(date: string) {
    const dateToFormat = new Date(date);
    return dateToFormat.getMonth() + 1 + '-' + dateToFormat.getFullYear();
  }

  // Visualizzazione immagine kpi non più utilizzata
  // getKPI() {
  //   this.loading = true;
  //   return this.http
  //     .get(`${environment.NEW_API}/hm/patients/${this.patientId}/kpi`, {
  //       responseType: 'blob',
  //     })
  //     .pipe(
  //       catchError((err) => {
  //         this.kpiUrl = null;
  //         console.error('No Health Meter for this patient');
  //         this.loading = false;
  //         return of(null);
  //       })
  //     )
  //     .subscribe((data: any) => {
  //       if (data !== null) {
  //         this.loading = false;
  //         return this.createImageFromBlob(data);
  //       }
  //     });
  // }

  // createImageFromBlob(image: Blob) {
  //   let reader = new FileReader();
  //   reader.addEventListener(
  //     'load',
  //     () => {
  //       this.kpiUrl = reader.result;
  //     },
  //     false
  //   );

  //   if (image) {
  //     reader.readAsDataURL(image);
  //   }
  // }

  //   Upload Image
  // uploadImageProfile(file: any) {
  //   const postUrl: string = `${environment.NEW_API}/hm/patients/${this.patientId}/kpi`;
  //   const formData = new FormData();
  //   formData.append('image', file.data, file.data.name);
  //   return this.http.post(postUrl, formData, this.payload).subscribe((data) => {
  //     console.log('success', data);
  //     this.getKPI();
  //   });
  // }

  // onClick(): void {
  //   const fileUpload = this.fileUpload.nativeElement;
  //   fileUpload.onchange = () => {
  //     const fileSingle = fileUpload.files[0];
  //     const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
  //     this.uploadImageProfile(fileParams);
  //   };
  //   fileUpload.click();
  // }
}
