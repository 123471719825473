<app-loading-spinner class="d-flex justify-content-center" *ngIf="isLoading">
</app-loading-spinner>
<ng-container *ngIf="specializzazioni && !isLoading">
  <div class="anamnesi" *ngIf="specializzazioni.length > 0">
    <div class="row do3-fw800 do3-blue-text header">
      <div class="col-5 pl-4">Specializzazione</div>
      <div class="col-5">Descrizione</div>
      <div class="col-2">Attivo</div>
    </div>
    <ng-container
      *ngFor="let specializzazione of specializzazioni; let i = index"
    >
      <app-single-anamnesi
        [singleAnamnesi]="specializzazione"
        [status]="specializzazione.active"
      ></app-single-anamnesi>
    </ng-container>
  </div>
  <ng-container *ngIf="specializzazioni.length == 0">
    <h1 class="d-flex justify-content-center m-5 do3-fw600">
      Al momento non ci sono questionari
    </h1>
  </ng-container>
</ng-container>
