import { createAction, props } from '@ngrx/store';
import { ProfiloPazienteDatum } from 'src/app/model/ProfiloPaziente';
import { PatientMembership } from 'src/app/model/PatientMembership';
import { PatientHealthTeam } from 'src/app/model/PatientHealthTeam';
import {
  ProfiloDocumentDatum,
  Pagination as DocumentiPagination,
} from 'src/app/model/ProfiloDocument';
import {
  Richiesta,
  RichiestaDatum,
  Pagination as RichiestePagination,
} from 'src/app/model/Richiesta';
import { PatientMembershipProfile } from 'src/app/model/PatientMembershipProfile';

export const fetchProfiloPaziente = createAction(
  '[Profilo-Paziente] Fetch Paziente',
  props<{ pazienteId: string }>()
);

export const fetchProfiloPazienteSuccess = createAction(
  '[Profilo-Paziente] Fetch Paziente Success',
  props<{ profiloPaziente: ProfiloPazienteDatum }>()
);

export const fetchProfiloPazienteFailure = createAction(
  '[Profilo-Paziente] Fetch Paziente Success',
  props<{ error: any }>()
);

export const fetchProfiloMeasurementsPaziente = createAction(
  '[Profilo-Paziente] Fetch Measurements Paziente',
  props<{ pazienteId: string; isHm: boolean }>()
);

export const fetchProfiloMeasurementsPazienteSuccess = createAction(
  '[Profilo-Paziente] Fetch Measurements Paziente Success',
  props<{ measurements: any }>()
);

export const fetchProfiloMeasurementsPazienteFailure = createAction(
  '[Profilo-Paziente] Fetch Measurements Paziente Failure',
  props<{ error: any }>()
);

export const fetchDocumentsPaziente = createAction(
  '[Profilo Paziente] Fetch Documents',
  props<{
    pazienteId: String;
    isHm?: boolean;
    isHm_doc?: boolean;
    documentType?: string;
    pageItems?: number;
    page?: number;
  }>()
);

export const fetchDocumentsPazienteSuccess = createAction(
  '[Profilo Paziente] Fetch Documents Success',
  props<{
    documents: ProfiloDocumentDatum[];
    meta: DocumentiPagination;
  }>()
);

export const fetchDocumentsPazienteFail = createAction(
  '[Profilo Paziente] Fetch Documents Fail',
  props<{ error: any }>()
);

export const fetchRichiestePaziente = createAction(
  '[Profilo Paziente] Fetch Richieste',
  props<{
    idPatient: string;
    withTriage: boolean;
    surveyRequest?: boolean;
    isHm?: boolean;
    pageItems?: number;
    page?: number;
  }>()
);
export const fetchRichiestePazienteSuccess = createAction(
  '[Profilo Paziente] Fetch Richieste Success',
  props<{
    richieste: RichiestaDatum[];
    meta: RichiestePagination;
  }>()
);

export const resetProfiloPaziente = createAction(
  '[Profilo-Paziente] Reset Paziente'
);

// export const fetchPatientActiveMembership = createAction(
//   '[Profilo-Paziente] Fetch Patient Active Membership',
//   props<{ pazienteId: string }>()
// );

// export const fetchPatientActiveMembershipSuccess = createAction(
//   '[Profilo-Paziente] Fetch Pazient Active Membership Success',
//   props<{ membership: PatientMembership }>()
// );

// export const fetchPatientActiveMembershipFailure = createAction(
//   '[Profilo-Paziente] Fetch Pazient Active Membership Failure',
//   props<{ error: any }>()
// );

// export const fetchPatientHealthTeam = createAction(
//   '[Profilo-Paziente] Fetch Patient Active Membership',
//   props<{ pazienteId: string }>()
// );

// export const fetchPatientHealthTeamSuccess = createAction(
//   '[Profilo-Paziente] Fetch Pazient Active Membership Success',
//   props<{ healthTeam: PatientHealthTeam }>()
// );

// export const fetchPatientHealthTeamFailure = createAction(
//   '[Profilo-Paziente] Fetch Pazient Active Membership Failure',
//   props<{ error: any }>()
// );

// export const fetchPatientMembershipProfile = createAction(
//   '[Profilo-Paziente] Fetch Patient Membership Profile',
//   props<{ pazienteId: string }>()
// );

// export const fetchPatientMembershipProfileSuccess = createAction(
//   '[Profilo-Paziente] Fetch Pazient Membership Profile Success',
//   props<{ membershipProfile: PatientMembershipProfile }>()
// );

// export const fetchPatientMembershipProfileFailure = createAction(
//   '[Profilo-Paziente] Fetch Pazient Membership Profile Failure',
//   props<{ error: any }>()
// );
