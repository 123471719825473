import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsensoComponent } from './consenso.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DatiProfiloComponent } from './dati-profilo/dati-profilo.component';
import { ConsensoInformatoComponent } from './consenso-informato/consenso-informato.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ConsensoComponent,
    DatiProfiloComponent,
    ConsensoInformatoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([{ path: ':id', component: ConsensoComponent }]),
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  exports: [DatiProfiloComponent],
})
export class ConsensoModule {}
