<div class="row table-row">
  <div class="col">
    <div class="img-profile-container medium big-border mx-0">
      <img
        class="img-profile pointer"
        [src]="avatarUrl"
        alt="{{ patient.name }}"
        (click)="goToPazienteProfile(patient.id)"
      />
    </div>
  </div>
  <div class="col">
    {{
      patient.firstName.length > 17
        ? (patient.firstName | slice : 0 : 17) + ".."
        : patient.firstName
    }}
  </div>
  <div class="col">
    {{
      patient.lastName.length > 17
        ? (patient.lastName | slice : 0 : 17) + ".."
        : patient.lastName
    }}
  </div>
  <div class="col">
    {{ patient.referenceCode }}
  </div>

  <div
    class="col user-subscription"
    *ngIf="isHm || isSeller"
    [ngSwitch]="patient.profile"
  >
    <span
      *ngSwitchCase="'MEMBER'"
      class="badge text-white text-uppercase lh-sm bg-success"
      >Abbonato</span
    >
    <span
      *ngSwitchCase="'NOT_MEMBER'"
      class="badge text-white text-uppercase lh-sm bg-warning"
      >Non abbonato</span
    >
    <span
      *ngSwitchCase="'MEMBER_EXPIRED'"
      class="badge text-white text-uppercase lh-sm bg-danger"
      >Scaduto</span
    >
  </div>

  <div class="col last">
    <!-- bottone attiva chat -->
    <button
      *ngIf="isSeller && !success"
      type="button"
      class="activate-chat-btn text-uppercase me-auto"
      (click)="activateChat(patient.id)"
    >
      {{ "Attiva chat" }}
    </button>
    <!-- bottone chat non attiva -->
    <svg
      *ngIf="isSeller && !success"
      width="30"
      height="30"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#CCCCCC" />
      <path
        d="M13 5C9.136 5 6 8.136 6 12C6 13.3627 6.392 14.632 7.064 15.7053L6 19L9.29467 17.936C10.368 18.608 11.6373 19 13 19C16.864 19 20 15.864 20 12C20 8.136 16.8733 5 13 5Z"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7473 12.0009C13.7473 12.4115 13.4113 12.7382 13.01 12.7382C12.6086 12.7382 12.2726 12.4022 12.2726 12.0009C12.2726 11.5995 12.6086 11.2635 13.01 11.2635C13.4113 11.2635 13.7473 11.5995 13.7473 12.0009Z"
        fill="white"
      />
      <path
        d="M16.715 12.0009C16.715 12.4115 16.379 12.7382 15.9777 12.7382C15.5764 12.7382 15.2404 12.4022 15.2404 12.0009C15.2404 11.5995 15.5764 11.2635 15.9777 11.2635C16.379 11.2635 16.715 11.5995 16.715 12.0009Z"
        fill="white"
      />
      <path
        d="M10.7778 12.0009C10.7778 12.4115 10.4418 12.7382 10.0404 12.7382C9.6391 12.7382 9.3031 12.4022 9.3031 12.0009C9.3031 11.5995 9.6391 11.2635 10.0404 11.2635C10.4418 11.2635 10.7778 11.5995 10.7778 12.0009Z"
        fill="white"
      />
    </svg>
    <!-- bottone chat attiva con link -->
    <a
      *ngIf="isSeller && success"
      routerLink="/chat"
      target="_blank"
      routerLinkActive="activeLink"
    >
      <svg
        class="pointer"
        width="30"
        height="30"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12.5" cy="12.5" r="12.5" fill="#ff3d77" />
        <path
          d="M13 5C9.136 5 6 8.136 6 12C6 13.3627 6.392 14.632 7.064 15.7053L6 19L9.29467 17.936C10.368 18.608 11.6373 19 13 19C16.864 19 20 15.864 20 12C20 8.136 16.8733 5 13 5Z"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M13.7473 12.0009C13.7473 12.4115 13.4113 12.7382 13.01 12.7382C12.6086 12.7382 12.2726 12.4022 12.2726 12.0009C12.2726 11.5995 12.6086 11.2635 13.01 11.2635C13.4113 11.2635 13.7473 11.5995 13.7473 12.0009Z"
          fill="white"
        />
        <path
          d="M16.715 12.0009C16.715 12.4115 16.379 12.7382 15.9777 12.7382C15.5764 12.7382 15.2404 12.4022 15.2404 12.0009C15.2404 11.5995 15.5764 11.2635 15.9777 11.2635C16.379 11.2635 16.715 11.5995 16.715 12.0009Z"
          fill="white"
        />
        <path
          d="M10.7778 12.0009C10.7778 12.4115 10.4418 12.7382 10.0404 12.7382C9.6391 12.7382 9.3031 12.4022 9.3031 12.0009C9.3031 11.5995 9.6391 11.2635 10.0404 11.2635C10.4418 11.2635 10.7778 11.5995 10.7778 12.0009Z"
          fill="white"
        />
      </svg>
    </a>
    <!-- bottone mostra dettagli profilo -->
    <svg
      class="pointer"
      width="30"
      height="30"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="handleDetailClick(patient)"
    >
      <circle cx="13" cy="13" r="13" fill="#ff3d77" />
      <path
        d="M13.0008 5.20078C8.68975 5.20078 5.20081 8.68933 5.20081 13.0008C5.20081 17.3118 8.68936 20.8008 13.0008 20.8008C17.3119 20.8008 20.8008 17.3122 20.8008 13.0008C20.8008 8.68979 17.3123 5.20078 13.0008 5.20078ZM13.0008 19.7124C9.30001 19.7124 6.28918 16.7016 6.28918 13.0008C6.28918 9.29996 9.30001 6.28916 13.0008 6.28916C16.7016 6.28916 19.7124 9.29996 19.7124 13.0008C19.7124 16.7016 16.7016 19.7124 13.0008 19.7124Z"
        fill="white"
      />
      <path
        d="M13.0019 11.7035C12.5399 11.7035 12.2114 11.8986 12.2114 12.1861V16.0979C12.2114 16.3443 12.5399 16.5907 13.0019 16.5907C13.4434 16.5907 13.8027 16.3443 13.8027 16.0979V12.1861C13.8027 11.8986 13.4434 11.7035 13.0019 11.7035Z"
        fill="white"
      />
      <path
        d="M13.0025 9.29141C12.5302 9.29141 12.1606 9.63022 12.1606 10.0204C12.1606 10.4106 12.5302 10.7596 13.0025 10.7596C13.4645 10.7596 13.8341 10.4106 13.8341 10.0204C13.8341 9.63022 13.4645 9.29141 13.0025 9.29141Z"
        fill="white"
      />
    </svg>
  </div>
</div>
