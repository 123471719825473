import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import * as AuthActions from './auth/store/auth.actions';
import * as MeActions from './core/header/store/me.actions';
import { Auth } from '@angular/fire/auth';
import { UserData } from './model/UserData';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Dr.Feel';

  constructor(
    private store: Store<fromApp.AppState>,
    private auth: Auth,
    private router: Router
  ) {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userToken = await user.getIdToken();
        const userData: UserData = {
          uid: user.uid,
          email: user.email,
          refreshToken: user.refreshToken,
          accessToken: userToken,
        };
        this.store.dispatch(AuthActions.loginSuccess({ userData }));
        // this.router.navigate(['/appuntamenti']);
      }
    });
  }

  ngOnInit() {}
}
