import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import * as fromApp from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import * as RichiesteActions from '../../features/richieste/store/richieste.actions';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'app-requests-container',
  templateUrl: './requests-container.component.html',
  styleUrls: ['./requests-container.component.scss'],
})
export class RequestsContainerComponent implements OnInit, OnDestroy {
  @Input() requestType: string = 'closed';
  @Input() sectionTab: string | null;
  public requests: Richiesta | null = null;
  public page: number = 0;
  public isLoading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = '';
  private storeSubscription: Subscription;
  private profiloSubscription: Subscription;
  public isHm: boolean | null = false;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit(): void {
    if (this.requestType === 'closed') {
      console.log('request type closed');

      this.store.dispatch(
        RichiesteActions.fetchClosedRequests({
          statusValue: 'CLOSED',
          sort: '-start_date_time',
        })
      );
    } else if (this.requestType === 'open') {
      console.log('request type open', this.requestType);
      this.store.dispatch(
        RichiesteActions.fetchPastOpenRequests({
          statusValue: 'OPEN',
          toDate: moment().startOf('day').format('YYYY-MM-DD[T]00:00'),
          sort: 'start_date_time',
        })
      );
    }

    this.storeSubscription = this.store
      .select('richieste')
      .subscribe((richieste) => {
        if (this.requestType === 'closed') {
          this.requests = richieste.pastClosedRichieste;
        } else if (this.requestType === 'open') {
          this.requests = richieste.pastOpenRichieste;
        }
        this.isLoading = richieste.loading;
        this.error = richieste.error;
        this.errorMessage = richieste.errorMessage;
      });

    this.profiloSubscription = this.store
      .select('me')
      .pipe(map((meState) => meState))
      .subscribe((meState) => {
        this.isHm = meState.isHm;
      });
  }

  ngOnDestroy(): void {
    this.storeSubscription && this.storeSubscription.unsubscribe();
  }

  changePage(page: any) {
    this.page = page;
    console.log('page future', this.page);
    if (this.requestType === 'closed') {
      this.store.dispatch(
        RichiesteActions.fetchClosedRequests({
          statusValue: 'CLOSED',
          page: this.page,
          sort: '-start_date_time',
        })
      );
    } else if (this.requestType === 'open') {
      this.store.dispatch(
        RichiesteActions.fetchPastOpenRequests({
          statusValue: 'OPEN',
          toDate: moment().startOf('day').format('YYYY-MM-DD[T]00:00'),
          page: this.page,
          sort: 'start_date_time',
        })
      );
    }
  }

  handleDetailClick(richiesta: RichiestaDatum) {
    this.router.navigate(['/detail', richiesta.id]);
  }
}
