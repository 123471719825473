import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as HmActions from './hm.actions';
import { of, pipe } from 'rxjs';
import { Patient } from 'src/app/model/Patient';
import {
  fetchRichiestaDetailsFailure,
  fetchRichiestaDetailsSuccess,
  fetchRichiestaDocumentsFailed,
  fetchRichiestaDocumentsSuccess,
} from '../../richieste/store/richieste.actions';

@Injectable()
export class HmEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  fetchPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HmActions.fetchPatients),
      switchMap((action) => {
        return this.fetchPatients(action);
      }),
      pipe(
        map((patients) => {
          if (patients) {
            return HmActions.fetchPatientsSuccess({
              patients: patients,
            });
          } else {
            return HmActions.fetchPatientsFailure({
              error: 'error: ',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching profilo paziente', error);
          return of(
            HmActions.fetchPatientsFailure({
              error: 'errore',
            })
          );
        })
      )
    )
  );

  fetchPatients(action: any) {
    let url = environment.NEW_API + '/patients';
    // let searchValue: any = {
    //   sort: [
    //     {
    //       direction: 'DESC',
    //       field: 'modifiedtime',
    //     },
    //   ],
    // };
    // if (action.firstName || action.lastName || action.id) {
    //   searchValue = {
    //     filter: {
    //       filterBy: {
    //         operation: 'AND',
    //         filters: [],
    //       },
    //     },
    //   };

    //   if (action.firstName) {
    //     searchValue.filter.filterBy.filters.push({
    //       field: 'firstName',
    //       value: action.firstName,
    //       operation: 'LIKE',
    //     });
    //   }

    //   if (action.lastName) {
    //     searchValue.filter.filterBy.filters.push({
    //       field: 'lastName',
    //       value: action.lastName,
    //       operation: 'LIKE',
    //     });
    //   }

    //   if (action.id) {
    //     searchValue.filter.filterBy.filters.push({
    //       field: 'crmNo',
    //       value: action.id,
    //       operation: 'LIKE',
    //     });
    //   }
    // }
    // if (action.page) {
    //   searchValue = {
    //     ...searchValue,
    //     pagination: {
    //       page: action.page,
    //       size: 20,
    //     },
    //   };
    // }
    // const base64 = btoa(JSON.stringify(searchValue));
    // url += '?searchRequest=' + base64;
    let queryPatient = [];
    if (action.firstName) {
      queryPatient.push('f.patient_first_name_contains=' + action.firstName);
    }
    if (action.lastName) {
      queryPatient.push('f.patient_last_name_contains=' + action.lastName);
    }
    if (action.referenceCode) {
      queryPatient.push('f.patient_ref_code_eq=' + action.referenceCode);
    }
    if (action.primary) {
      queryPatient.push('f.primary_is=' + action.primary);
    }
    if (action.active) {
      queryPatient.push('f.active_membership=' + action.active);
    }
    if (action.sort) {
      queryPatient.push('sort=' + action.sort);
    }
    if (action.page) {
      queryPatient.push('page=' + action.page);
    }

    if (queryPatient.length > 0) {
      url += '?' + queryPatient.join('&');
    }
    console.log(url);
    return this.http.get<Patient>(url);
  }

  fetchRichiestaDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HmActions.fetchHmRequestDetail),
      switchMap((action) => {
        return this.http.get<any>(
          environment.NEW_API + '/requests/' + action.richiestaId
        );
      }),
      pipe(
        map((richiesta) => {
          if (richiesta) {
            return fetchRichiestaDetailsSuccess({
              richiesta: richiesta,
            });
          } else {
            return fetchRichiestaDetailsFailure({
              error: 'error: Fetch details failed',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching:', error);
          return of(
            fetchRichiestaDetailsFailure({
              error:
                'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
            })
          );
        })
      )
    )
  );

  // fetchHmRichiestaDocuments$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(HmActions.fetchHmRequestDocuments),
  //     switchMap((action) =>
  //       this.http
  //         .get<any>(
  //           environment.NEW_API +
  //             `/patients/${action.patientId}/documents?f.sharedWithRequestId=${action.richiestaId}&f.type_in=${action.typeDoc}`
  //         )
  //         .pipe(
  //           map((documents) => {
  //             if (documents) {
  //               return fetchRichiestaDocumentsSuccess({
  //                 documents: documents.data,
  //               });
  //             } else {
  //               return fetchRichiestaDocumentsFailed({
  //                 error: 'error: ',
  //               });
  //             }
  //           }),
  //           catchError((error) => {
  //             console.error('Error while fetching:', error);
  //             return of(
  //               fetchRichiestaDocumentsFailed({
  //                 error:
  //                   'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
  //               })
  //             );
  //           })
  //         )
  //     )
  //   )
  // );
}
