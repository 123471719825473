import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MeData } from 'src/app/model/Me';
import { Store } from '@ngrx/store';
import * as MeActions from 'src/app/core/header/store/me.actions';
import * as fromApp from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-info-profilo',
  templateUrl: './info-profilo.component.html',
  styleUrls: ['./info-profilo.component.scss'],
})
export class InfoProfiloComponent implements OnInit, OnDestroy {
  @Input() me: MeData | null = null;

  public sedi: string[] | any;
  public addInput: boolean = false;
  public inputNum: any[] = [1];
  public avatarUrl: any;
  public avatarChanged: boolean = false;
  public avatarChangeError: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  public addSede(): void {
    if (this.addInput) {
      this.inputNum.push(1);
    }
    this.addInput = true;
  }

  public removeSede(index: number): void {
    this.sedi.splice(index, 1);
  }

  private storeSub: Subscription;
  public meStore: MeData | null = null;
  public loading: boolean = false;
  public fullName: string = '';

  onSaveProfile(form: NgForm) {
    console.log('form: ', form.value);
    let fiscalCode = form.value.fiscalCode.toUpperCase();
    form.value.fiscalCode = fiscalCode;
    this.store.dispatch(MeActions.updateMe({ me: form.value }));
  }

  onAvatarChanged(avatarChanged: boolean) {
    if (avatarChanged) {
      this.avatarChangeError = false;
      this.avatarChanged = avatarChanged;
      this.getAvatar();
      setTimeout(() => {
        this.avatarChanged = false
      }, 5000)
    }
  }

  onAvatarChangeError(avatarChangeError: boolean) {
    if (avatarChangeError) {
      this.avatarChangeError = avatarChangeError;
    }
  }

  ngOnInit(): void {
    this.storeSub = this.store.select('me').subscribe((me) => {
      this.meStore = me.me;
      this.loading = me.loading;
      this.fullName = `${me.me?.firstName} ${me.me?.lastName}`;
      // DA VALUTARE me.locations
      // this.sedi = me.me?.locations;
    });
    this.getAvatar();
  }

  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(`${environment.NEW_API}/profile/image`, { responseType: 'blob' })
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  ngOnDestroy(): void {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
