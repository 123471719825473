<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div *ngIf="me && !loading" class="info-profilo">
  <div *ngIf="avatarChanged" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Foto modificata con successo!</strong>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div *ngIf="avatarChangeError" class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Si è verificato un errore.</strong>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div class="info-profilo-topper">
    <div class="info-profilo-image">
      <div class="avatar-border">
        <ngx-avatars
          alt="avatar"
          [textSizeRatio]="3"
          bgColor="#FF3D77 "
          fgColor="white"
          class="circle my-avatar"
          [name]="this.fullName"
          [src]="avatarUrl"
          size="77"
          [round]="true"
        ></ngx-avatars>
      </div>
      <div class="div-ghost">
        <app-change-avatar (avatarChanged)="onAvatarChanged($event)" (avatarChangeError)="onAvatarChangeError($event)">Modifica Foto</app-change-avatar>
      </div>
    </div>
    <div class="codice-ateco">
      <p class="fw-bold">
        Codice Ateco: <span class="fw-normal text-black-50">{{ me.id }}</span>
      </p>
    </div>
  </div>

  <div class="info-profilo-form" *ngIf="!loading">
    <form #f="ngForm" (ngSubmit)="onSaveProfile(f)" class="row">
      <!-- ERRORS -->
      <div class="col-12">
        <!--<div class="alert alert-danger" role="alert"
          *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
          <h5>Nome</h5>
          <ul>
            <li *ngIf="firstName.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
          <h5>Cognome</h5>
          <ul>
            <li *ngIf="lastName.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert"
          *ngIf="bornDate.invalid && (bornDate.dirty || bornDate.touched)">
          <h5>Data di Nascita</h5>
          <ul>
            <li *ngIf="bornDate.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="gender.invalid && (gender.dirty || gender.touched)">
          <h5>Sesso</h5>
          <ul>
            <li *ngIf="gender.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="bornCity.invalid && (bornCity.dirty || bornCity.touched)">
          <h5>Luogo di Nascita</h5>
          <ul>
            <li *ngIf="bornCity.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="fiscalCode.invalid && fiscalCode.touched">
          <h5>Codice Fiscale</h5>
          <ul>
            <li *ngIf="fiscalCode.errors?.['required']">
              il campo è obbligatorio
            </li>
            <li *ngIf="!!fiscalCode.errors?.['pattern']">
              Codice fiscale errato
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="email.invalid && email.touched">
          <h5>Email</h5>
          <ul>
            <li *ngIf="email.errors?.['required']">il campo è obbligatorio</li>
            <li *ngIf="!!email.errors?.['pattern']">Email errata</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="phoneNumber.invalid && phoneNumber.touched">
          <h5>Cellulare</h5>
          <ul>
            <li *ngIf="phoneNumber.errors?.['required']">
              il campo è obbligatorio
            </li>
            <li *ngIf="phoneNumber.errors?.['minlength']">
              Numero di telefono errato
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="street.invalid && (street.dirty || street.touched)">
          <h5>Indirizzo</h5>
          <ul>
            <li *ngIf="street.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="city.invalid && (city.dirty || city.touched)">
          <h5>Città</h5>
          <ul>
            <li *ngIf="city.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="country.invalid && (country.dirty || country.touched)">
          <h5>Regione</h5>
          <ul>
            <li *ngIf="country.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="zip.invalid && (zip.dirty || zip.touched)">
          <h5>Codice Postale</h5>
          <ul>
            <li *ngIf="zip.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>-->

        <!--<div
          class="alert alert-danger"
          role="alert"
          *ngIf="sede.invalid && (sede.dirty || sede.touched)"
        >
          <h5>Sede</h5>
          <ul>
            <li *ngIf="sede.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>-->
      </div>
      <!-- ERRORS END -->

      <div class="form-floating col-4" *ngIf="me.firstName">
        <input
          required
          #firstName="ngModel"
          name="firstName"
          class="form-control no-box"
          id="firstName"
          placeholder="Nome"
          type="text"
          [ngModel]="me.firstName"
          disabled
        />
        <label for="firstName">Nome</label>
      </div>

      <div class="form-floating col-4" *ngIf="me.lastName">
        <input
          required
          #lastName="ngModel"
          name="lastName"
          class="form-control no-box"
          id="lastName"
          placeholder="Cognome"
          type="text"
          [ngModel]="me.lastName"
          disabled
        />
        <label for="lastName">Cognome</label>
      </div>

      <!-- DA VALUTARE me.bornDate
      <div class="form-floating col-4" *ngIf="me.bornDate">
        <input
          required
          #bornDate="ngModel"
          name="bornDate"
          class="form-control no-box"
          id="bornDate"
          placeholder="Data"
          type="date"
          [ngModel]="me.bornDate"
          disabled
        />
        <label for="bornDate">Data di Nascita</label>
      </div> -->

      <div class="form-floating col-4" *ngIf="me.gender">
        <select
          required
          #gender="ngModel"
          class="form-select no-box do3-fw600 do3-gray-text"
          name="gender"
          id="gender"
          [ngModel]="me.gender"
          disabled
        >
          <option value="MALE">Uomo</option>
          <option value="FEMALE">Donna</option>
          <option value="OTHER">Preferisco non rispondere</option>
        </select>
        <label for="gender">Sesso</label>
      </div>

      <!-- DA VALUTARE me.bornCity, me.fiscalCode
      <div class="form-floating col-4" *ngIf="me.bornCity">
        <input
          required
          #bornCity="ngModel"
          name="bornCity"
          class="form-control no-box"
          id="bornCity"
          placeholder="Luogo di Nascita"
          type="text"
          [ngModel]="me.bornCity"
          disabled
        />
        <label for="bornCity">Luogo di Nascita</label>
      </div>

      <div class="form-floating col-4" *ngIf="me.fiscalCode">
        <input
          required
          #fiscalCode="ngModel"
          name="fiscalCode"
          class="form-control no-box"
          id="fiscalCode"
          placeholder="Codice fiscale"
          type="text"
          [ngModel]="me.fiscalCode"
          pattern="^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$"
          disabled
        />
        <label for="fiscalCode">Codice Fiscale</label>
      </div> -->

      <div class="form-floating col-6" *ngIf="me.email">
        <input
          required
          #email="ngModel"
          name="email"
          class="form-control no-box"
          id="email"
          placeholder="Email"
          [ngModel]="me.email"
          type="email"
          disabled
          pattern='((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,})))'
        />
        <label for="email">Email</label>
      </div>

      <!-- DA VALUTARE me.phoneNumber,me.street,me.city,me.country,me.zip
      <div class="form-floating col-6" *ngIf="me.phoneNumber">
        <input
          required
          #phoneNumber="ngModel"
          name="phoneNumber"
          class="form-control no-box"
          id="phoneNumber"
          disabled
          placeholder="Cellulare"
          type="tel"
          [ngModel]="me.phoneNumber"
          minlength="10"
        />
        <label for="phoneNumber">Cellulare</label>
      </div>

      <div class="form-floating col-12" *ngIf="me.street">
        <input
          required
          #street="ngModel"
          name="street"
          class="form-control no-box"
          id="street"
          placeholder="Indirizzo"
          disabled
          type="text"
          [ngModel]="me.street"
        />
        <label for="street">Indirizzo</label>
      </div>

      <div class="form-floating col-4" *ngIf="me.city">
        <input
          required
          #city="ngModel"
          name="city"
          class="form-control no-box"
          id="city"
          placeholder="Città"
          disabled
          type="text"
          [ngModel]="me.city"
        />
        <label for="city">Città</label>
      </div>

      <div class="form-floating col-4" *ngIf="me.country">
        <input
          required
          #country="ngModel"
          name="country"
          class="form-control no-box"
          id="country"
          placeholder="Regione"
          disabled
          type="text"
          [ngModel]="me.country"
        />
        <label for="country">Regione</label>
      </div>

      <div class="form-floating col-4" *ngIf="me.zip">
        <input
          required
          #zip="ngModel"
          name="zip"
          class="form-control no-box"
          id="zip"
          placeholder="Codice Postale"
          disabled
          type="number"
          [ngModel]="me.zip"
        />
        <label for="zip">Codice Postale</label>
      </div> -->

      <!-- <hr /> -->

      <div class="row" *ngIf="sedi">
        <h3 class="form-title">Sede</h3>
        <div
          class="form-floating col-6"
          *ngFor="let sede of sedi; let i = index"
        >
          <input
            #sede="ngModel"
            name="sede-{{ i }}"
            class="form-control no-box"
            id="sede"
            placeholder="Sede"
            disabled
            type="text"
            [ngModel]="sede.street"
          />
          <label for="sede">Sede</label>
        </div>
        <ng-container *ngIf="addInput && inputNum">
          <div class="form-floating col-6" *ngFor="let input of inputNum">
            <input
              type="text"
              id="add-sede"
              class="form-control no-box"
              disabled
            />
            <label for="add-sede">Sede</label>
          </div>
        </ng-container>

        <!-- <button
          type="button"
          class="do3-secondary-btn blue"
          (click)="addSede()"
        >
          Aggiungi Sede
        </button> -->
      </div>

      <!-- <hr /> -->

      <div class="row" *ngIf="me.specializations">
        <h3>Specialistica</h3>
        <div
          class="form-floating col-6"
          *ngFor="let specialistica of me.specializations; let index = index"
        >
          <input
            #specialistica="ngModel"
            name="specialistica-{{ index }}"
            class="form-control no-box"
            id="specialistica"
            placeholder="Specialistica"
            type="text"
            [(ngModel)]="me.specializations[index].name"
            disabled
          />
          <label for="specialistica">Specialistica</label>
        </div>
      </div>

      <!-- <button type="button" class="do3-secondary-btn blue">
        Aggiungi Specialistica
      </button> -->

      <!-- <hr /> -->

      <!-- DA VALUTARE me.Illness
      <div class="row" *ngIf="me.Illness">
        <h3 class="form-title">Patologie comnuni trattate</h3>
        <div
          class="form-floating col-6"
          *ngFor="let patologia of me.Illness; let index = index"
        >
          <input
            #patologia="ngModel"
            name="patologia-{{ index }}"
            class="form-control no-box"
            id="patologie"
            placeholder="Patologia"
            type="text"
            [(ngModel)]="me.Illness[index].name"
            disabled
          />
          <label for="patologie">Patologie</label>
        </div>
      </div> -->

      <!-- <button type="button" class="do3-secondary-btn blue">
        Aggiungi Patologia
      </button> -->

      <!-- <div class="d-flex justify-content-between mt-5">
        <button
          class="do3-secondary-btn light-blue ml-0"
          [disabled]="!f.valid"
          type="submit"
        >
          SALVA MODIFICHE
        </button>
        <button class="do3-secondary-btn red">ELIMINA IL TUO ACCOUNT</button>
      </div> -->
    </form>
  </div>
</div>
