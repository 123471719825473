<div class="tabs-container">
  <div class="tabs">
    <div
      *ngFor="let tab of tabs"
      [class.active]="tab.title === activeTab"
      class="tab"
      (click)="selectTab(tab)"
    >
      <p
        *ngIf="tab.icon"
        class="tab-icon"
        [innerHTML]="sanitizeIcon(tab.icon)"
      ></p>
      <p class="tab-title">
        {{ tab.title }}
      </p>

      <div *ngIf="tab.title === activeTab" class="active-underline"></div>
    </div>
  </div>
  <div class="full-underline"></div>
</div>
