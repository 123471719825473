import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from 'src/app/store/app.reducer';
import * as RichiesteActions from 'src/app/features/richieste/store/richieste.actions';
import { fetchDocumentsPaziente } from 'src/app/features/profilo-paziente/store/profilo-paziente.actions';

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss'],
})
export class UploadDocComponent implements OnInit {
  @Input() isRichiesta: boolean;
  @Input() richiestaId: string = '';
  @Input() typeDocSelected: string = 'DIAGNOSIS';
  @Input() isHm: boolean;
  @Input() isHm_doc: boolean;
  @Input() pazienteId: string | undefined;
  @Input() typeDoc: string = 'diagnosis';
  @Input() page: number = 0;
  @Input() pageItems: number;
  @Output() resetFirstPage: EventEmitter<number> = new EventEmitter<number>();
  public fileToUpload: File | null = null;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files = [];
  public loading: boolean = false;
  @ViewChild('openModal', { static: true }) openModal: ElementRef;
  public success: boolean = false;
  public fail: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    console.log(this.typeDocSelected);
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('document', file.data, file.data.name);
    formData.append('documentType', this.typeDoc.toUpperCase());
    // Check for requestId
    if (this.isRichiesta) {
      formData.append('requestId', this.richiestaId);
    }
    console.log('formData', formData);
    this.loading = true;
    // const url = !this.isHm
    //   ? environment.NEW_API + '/requests/' + this.richiestaId + '/documents'
    //   : environment.NEW_API + '/hm/patients/' + this.pazienteId + '/documents';

    const url =
      environment.NEW_API + '/patients/' + this.pazienteId + '/documents';

    return this.httpClient
      .post(url, formData, {
        headers: {
          contentType: 'multipart/form-data',
        },
      })
      .subscribe(
        (data) => {
          console.log('success', data);
          this.success = true;
          this.loading = false;
          this.resetFirstPage.emit(0);
          //   this.openModal.nativeElement.click();
          if (this.isRichiesta) {
            this.store.dispatch(
              RichiesteActions.fetchRichiestaDocuments({
                richiestaId: this.richiestaId,
              })
            );
          } else {
            this.store.dispatch(
              fetchDocumentsPaziente({
                pazienteId: this.pazienteId!,
                isHm: this.isHm,
                isHm_doc: this.isHm_doc,
                documentType: this.typeDoc,
                pageItems: this.pageItems
              })
            );
          }
        },
        (error) => {
          this.openModal.nativeElement.click();
          this.fail = true;
          this.loading = false;
          console.log(error);
        }
      );
  }
  onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const fileSingle = fileUpload.files[0];
      const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
      this.uploadFile(fileParams);
    };
    fileUpload.click();
  }
}
