import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ChatClientService,
  ChannelService,
  StreamI18nService,
  MessageContext,
  CustomTemplatesService,
} from 'stream-chat-angular';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import { fetchUserInfo } from 'src/app/core/header/store/me.actions';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-get-stream',
  templateUrl: './get-stream.component.html',
  styleUrls: ['./get-stream.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GetStreamComponent implements OnInit, OnDestroy {
  // Create a reference to your custom template
  @ViewChild('customMessageTemplate')
  messageTemplate!: TemplateRef<MessageContext>;
  private meSub: Subscription;
  private chatUserId: string = '';
  private chatUserToken: string = '';
  public loading: boolean = false;
  public channels: any[] = [];
  private queryParamSub: Subscription;
  public id: string = '';
  public showUnreadOnly: boolean = false;
  public searchQuery: string = '';

  constructor(
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private customTemplatesService: CustomTemplatesService, // Import the CustomTemplatesService
    private route: ActivatedRoute
  ) {
    this.translateService.getTranslation('it').subscribe(() => {
      this.streamI18nService.setTranslation('it', {
        'You have no channels currently': 'Nessun risultato',
        'No chats here yet…': 'Seleziona una chat dalla lista.',
      });
    });
    this.translateService.use('it');
  }

  async ngOnInit() {
    this.store.dispatch(fetchUserInfo());
    this.queryParamSub = this.route.queryParamMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.id = id;
      }
    });
    this.meSub = this.store.select('me').subscribe(async (me) => {
      this.chatUserId = me.userInfo?.chatInfo?.userId || '';
      this.chatUserToken = me.userInfo?.chatInfo?.userToken || '';

      if (this.chatUserId && this.chatUserToken) {
        this.chatService.init(
          environment.streamChat.apiKey,
          this.chatUserId,
          this.chatUserToken,
          {
            timeout: 10000,
          }
        );

        if (this.id) {
          this.channelService.init(
            {
              type: 'messaging',
              members: { $in: [this.id] },
              joined: true,
            },
            { last_message_at: -1 },
            {},
            false
          );
        } else {
          this.channelService.init(
            {
              type: 'messaging',
              members: { $in: [this.chatUserId] },
            },
            { last_message_at: -1 },
            {},
            false
          );
        }
      }
    });
  }

  ngAfterViewInit(): void {
    // Register your template
    this.customTemplatesService.messageTemplate$.next(this.messageTemplate);
  }

  ngOnDestroy(): void {
    this.meSub && this.meSub.unsubscribe();
  }

  filterChannels(input: any) {
    if (input instanceof Event) {
      this.searchQuery = (input.target as HTMLInputElement).value;
    } else {
      this.searchQuery = input;
    }

    this.applyFilters();
  }

  toggleUnreadFilter(event: any) {
    this.showUnreadOnly = event.target.checked;
    this.applyFilters();
  }

  applyFilters() {
    const filter: any = {
      type: 'messaging',
      members: { $in: [this.chatUserId] },
    };

    if (this.searchQuery) {
      filter.$or = [
        { name: { $autocomplete: this.searchQuery } },
        { 'member.user.name': { $autocomplete: this.searchQuery } },
      ];
    }

    if (this.showUnreadOnly) {
      filter.has_unread = true;
    }

    this.channelService.reset();
    this.channelService.init(filter, { last_message_at: -1 }, {}, false);
  }
}
