<div class="fatturazione">
  <div class="d-flex  align-items-center">
    <p class="do3-gray-text"> <span class="do3-fw800 do3-blue-text">Metodo di pagamento:</span> Mastercard xxxx xxxx
      xxxx 3456</p>
    <button class="do3-secondary-btn blue">CAMBIA</button>
  </div>
  <table class="table do3-gray-text">
    <thead class="do3-fw800 do3-blue-text">
      <tr>
        <th scope="col">Medico</th>
        <th scope="col">Specialistica</th>
        <th scope="col">ID Appuntamento</th>
        <th scope="col">Data</th>
        <th scope="col">Importo</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody class="do3-gray-text">
      <tr>
        <td scope="row">Dott. Giuseppe Strada</td>
        <td>Pneumologo</td>
        <td>123498#002#02</td>
        <td>05.02.2022</td>
        <td>45.00 €</td>
        <td class="last">
          <button class="do3-secondary-btn red">PREAUTORIZZATO</button>
        </td>
      </tr>
      <tr>
        <td scope="row">Dott. Mario Rossi</td>
        <td>Urologia</td>
        <td>120122#001#01</td>
        <td>30.01.2022</td>
        <td>65.00 €</td>
        <td class="last">
          <button class="do3-secondary-btn green">PAGATO</button>
        </td>
      </tr>

    </tbody>

  </table>

</div>
