<section class="cronologia">
  <table class="table table-image">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Medico</th>
        <th scope="col">Specialistica</th>
        <th scope="col">ID Appuntamento</th>
        <th scope="col">Data</th>
        <th scope="col">Consenso informato</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let richiesta of richieste">
        <tr *ngIf="richiesta.status !== 'OPEN'">
          <td scope="row align-items-center">
            <div class="img-profile-container medium big-border">
              <img [src]="getDoctorAvatar(richiesta)" class="img-profile" [alt]="richiesta.medicalProvider.name">
            </div>
          </td>
          <td>{{richiesta.medicalProvider.name}}</td>
          <td>{{richiesta.medicalSpecialization.description}}</td>
          <td>{{richiesta.id}}</td>
          <td>{{richiesta.startDateTime | date: 'longDate' }}</td>
          <td [ngClass]="richiesta.needInformedConsent === false ? 'green' : 'red'" class="dot"></td>
          <td>
            <mat-icon class="download">save_alt</mat-icon>
            <mat-icon class="dots">more_vert</mat-icon>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</section>
