import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Richiesta, RichiestaDatum, Pagination } from 'src/app/model/Richiesta';

@Component({
  selector: 'app-appuntamenti-list',
  templateUrl: './appuntamenti-list.component.html',
  styleUrls: ['./appuntamenti-list.component.scss'],
})
export class AppuntamentiListComponent implements OnInit {
  @Input() sectionTab: string | null;
  @Input() isHm: boolean | null = false;
  @Input() myId: number | undefined;
  @Input() richieste: Richiesta;
  @Input() listType: 'today' | 'future' | 'past' | 'all' | 'open' | 'closed' =
    'today';
  @Input() mode: 'appuntamenti' | 'storico';
  @Input() page: number = 0;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<string> = new EventEmitter();
  @Output() changePage: EventEmitter<any> = new EventEmitter();
  public dateDisplay: any;
  public richiesteData: RichiestaDatum[] = [];
  public pagination: Pagination;
  public pageDirection: string;
  public richiesteClosed: RichiestaDatum[] = [];
  public itemsPerPage: number = 20;
  public totalPages: number;

  constructor() {}

  ngOnInit(): void {
    this.richiesteData = this.richieste.items;
    this.pagination = this.richieste.pagination;
    this.totalPages = Math.ceil(
      this.richieste.pagination.totalElements / this.itemsPerPage
    );
    console.log('total pages: ', this.totalPages);
    switch (this.listType) {
      case 'today':
        this.dateDisplay = new Date();
        break;

      case 'future':
        this.dateDisplay = 'Appuntamenti in programma';
        break;

      default:
        break;
    }

    if (this.richiesteData) {
      this.richiesteData.forEach((richiesta) => {
        if (richiesta.status === 'CLOSED') {
          this.richiesteClosed.push(richiesta);
          // this.richiesteClosed.sort(
          //   (a: any, b: any) =>
          //     new Date(b.slot.from).getTime() - new Date(a.slot.from).getTime()
          // );
        }
      });
    }
  }

  handleDetailClick(richiesta: RichiestaDatum) {
    this.onClick.emit(richiesta);
  }

  handleDeleteRequest(richiestaId: string) {
    this.onDelete.emit(richiestaId);
  }

  handlePageClick(page: any) {
    this.page = page;
    this.changePage.emit(this.page);
  }
}
