import { Action, createReducer, on } from '@ngrx/store';
import * as HmActions from './hm.actions';
import { Patient } from 'src/app/model/Patient';

export interface State {
  patients: Patient | null;
  loading: boolean;
  error: boolean;
  errorMessage: any;
}

const initialState: State = {
  patients: null,
  loading: false,
  error: false,
  errorMessage: null,
};

const _hmReducer = createReducer(
  initialState,

  on(HmActions.fetchPatients, (state) => ({
    ...state,
    patients: null,
    loading: true,
  })),

  on(HmActions.fetchPatientsSuccess, (state, action) => ({
    ...state,
    patients: action.patients,
    loading: false,
  })),

  on(HmActions.fetchPatientsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  }))
);

export const hmReducer = (state: State | undefined, action: Action) => {
  return _hmReducer(state, action);
};
